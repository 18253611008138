import { DateTime } from 'luxon';

export const getDayMonthYearDateFromString = (input: string) =>
  input ? DateTime.fromISO(input).toLocaleString() : '';

export const getHourMinuteTimeFromString = (input: string) =>
  input ? DateTime.fromISO(input).toFormat('T') : '';

const padWithZero = (number: number) => ('00' + number).slice(-2);

export const getISOFormatDate = (date: string, excludeDate?: boolean) => {
  if (!date) {
    return '';
  }
  const { day, month, year, hour, minute } = DateTime.fromISO(date);
  if (excludeDate) {
    return `${day}.${month}.${year}`;
  } else {
    return `${day}.${month}.${year}, ${padWithZero(hour)}:${padWithZero(
      minute
    )}`;
  }
};

export const getFormattedCountdownTimer = (
  minutes: number,
  seconds: number
) => {
  return `${padWithZero(minutes)}:${padWithZero(seconds)}`;
};
