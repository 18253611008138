import { IMenuItem } from '../../../utils/types';
import * as S from './MenuWithHeader.styles';

const MenuItem = ({
  label,
  isSelected = false,
  counter = 0,
  action,
}: IMenuItem) => (
  <S.MenuItem onClick={action} isSelected={isSelected}>
    <S.Label isSelected={isSelected}>{label}</S.Label>
    <S.Counter isSelected={counter > 0}>
      <S.CounterValue>{counter}</S.CounterValue>
    </S.Counter>
  </S.MenuItem>
);

export default MenuItem;
