import * as A from './actionTypes';
import {
  initialModalActiveStates,
  utilsReducerInitialState,
  UtilsReducerState,
} from './types';

const UtilsReducer = (
  state = utilsReducerInitialState,
  action: any
): UtilsReducerState => {
  switch (action.type) {
    case A.TOGGLE_MODAL: {
      const { name } = action;
      const currentState = state.modalsActiveStates.find(
        (t) => t.name === name
      );
      if (currentState === undefined) {
        return state;
      }
      const initialActiveStates = state.modalsActiveStates;
      return {
        ...state,
        modalsActiveStates: [
          ...initialActiveStates.filter((s) => s.name !== name),
          {
            name,
            isOpen: !currentState.isOpen,
          },
        ],
      };
    }
    case A.CLOSE_MODALS: {
      return {
        ...state,
        modalsActiveStates: [...initialModalActiveStates],
      };
    }
    default:
      return state;
  }
};

export default UtilsReducer;
