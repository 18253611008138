import { useSelector, useDispatch } from 'react-redux';
import {
  useSelectedDrawerSubmission,
  useSelectedSubmissionFiles,
} from '../../redux/submission/selectors';
import { getISOFormatDate } from '../../utils/date';
import { ReactComponent as Calendar } from '../../assets/myAnalysis/calendar.svg';
import ExclamationMark from '../../assets/common/exclamation-mark.png';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import {
  archiveOwnSubmission,
  executePaymentIntent,
  setDrawerSubmissionId,
} from '../../redux/submission/actions';
import { ANALYSIS_STATUS } from '../../utils/types';
import { userData } from '../../redux/auth/selectors';
import { BASE_URL, getFile } from '../../api/common-api';
import { useTranslation } from 'react-i18next';
import ImageModal from '../../common/ImageModal/ImageModal';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { getFilesSizeFormatted } from '../../utils/misc';
import { BiMoviePlay } from 'react-icons/bi';
import i18n from '../../i18n';

import * as S from './SideDrawer.styles';

const SideDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedSubmission = useSelector(useSelectedDrawerSubmission);
  const selectedSubmissionFiles = useSelector(useSelectedSubmissionFiles);
  const { email, role } = useSelector(userData);
  const [isSrc, setIsSrc] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [playerRef] = useInView({ threshold: 1 });
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);
  if (selectedSubmission === undefined) {
    return null;
  }

  const isAdmin = role === 'admin';
  const {
    description,
    status,
    created_at: date,
    id: submissionId,
    referencedBy,
    paid,
  } = selectedSubmission;
  const handleClose = () => dispatch(setDrawerSubmissionId(''));
  const handleSetSrcToModal = (src: string) => setIsSrc(src);
  const submissionFiles = selectedSubmissionFiles.submitted;
  const resultsFiles = selectedSubmissionFiles.results;
  const isDicomSectionVisible = submissionFiles.zip.length > 0;
  const isDescriptionSectionVisible = submissionFiles.files;
  const isPaymentRequired = status === ANALYSIS_STATUS.NOT_PAID;
  const createdAtDate = getISOFormatDate(date);

  const handlePaymentIntent = (e: any) => {
    e.stopPropagation();
    dispatch(
      executePaymentIntent({
        submissionId,
        email,
        locale: selectedLanguage ? selectedLanguage : 'pl',
      })
    );
  };

  const openAllFilesDownload = (): void => {
    const url = `${BASE_URL}/submission/${submissionId}/files`;
    window.location.assign(url);
  };

  const handleDownloadResults = (): void => {
    if (!referencedBy || referencedBy.length === 0) {
      return;
    }
    const resultsSubmissionId = referencedBy[0].id;
    const url = `${BASE_URL}/submission/${resultsSubmissionId}/files`;
    window.location.assign(url);
  };

  const openFileDownload = async (
    e: any,
    referencedSubmission: string,
    id: string,
    filename: string
  ): Promise<void> => {
    e.preventDefault();
    const url = await getFile(referencedSubmission, id);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  const canDownloadVisualization =
    !isPaymentRequired &&
    (selectedSubmissionFiles.results.zip.length > 0 ||
      selectedSubmissionFiles.results.files.length > 0);

  const submittedZipSize = selectedSubmissionFiles.submitted.zip.map(
    (z) => z.size
  );
  const submittedFileSize = selectedSubmissionFiles.submitted.files.map(
    (z) => z.size
  );
  const submittedTotalSizeInBytes = submittedZipSize
    .concat(submittedFileSize)
    .reduce((a, b) => a + b, 0);
  const submittedFilesSize = getFilesSizeFormatted(submittedTotalSizeInBytes);
  const dicomFilesSize = getFilesSizeFormatted(
    submittedZipSize.reduce((a, b) => a + b, 0)
  );

  const resultsZipSize = selectedSubmissionFiles.results.zip.map((z) => z.size);
  const resultsFileSize = selectedSubmissionFiles.results.files.map(
    (z) => z.size
  );
  const resultsTotalSizeInBytes = resultsZipSize
    .concat(resultsFileSize)
    .reduce((a, b) => a + b, 0);
  const resultsFilesSize = getFilesSizeFormatted(resultsTotalSizeInBytes);

  const numberOfZipFiles = selectedSubmissionFiles.submitted.zip.map(
    (z) => z.number_of_files
  );
  const numberOfStandardFiles = selectedSubmissionFiles.submitted.files.map(
    (z) => z.number_of_files
  );
  const numberOfFiles = numberOfZipFiles
    .concat(numberOfStandardFiles)
    .reduce((a, b) => a + b, 0);

  // const toggleDeleteSelectedItem = (id: string, add: boolean) => {
  //   if (add) {
  //     setSelectedDeleteItems([
  //       ...seletectedDeleteItems,
  //       id
  //     ])
  //   } else {
  //     setSelectedDeleteItems(seletectedDeleteItems.filter(e => e !== id))
  //   }
  // }

  const handleDeleteItems = () => {
    dispatch(archiveOwnSubmission(submissionId));
    setIsDeleteModalOpen(false);
  };

  const canDeleteSubmission = !paid && isAdmin;

  return (
    <S.DrawerContainer onClick={(e: any) => e.stopPropagation()}>
      <ImageModal
        type={'img'}
        onClick={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        src={isSrc}
        onClose={() => setIsModalOpen(false)}
        title={''}
      />
      {/* <ImageModal
        type={'movie'}
        onClick={() => setIsVideoModalOpen(false)}
        isOpen={isVideoModalOpen}
        src={isVideoSrc}
        onClose={() => setIsVideoModalOpen(false)}
        title={''}
      /> */}
      <S.Wrapper>
        <S.HeaderWrapper>
          <S.TitleWrapper>
            <S.TitleContainer>
              <S.Title>{description}</S.Title>
            </S.TitleContainer>
            <S.CloseContainer onClick={handleClose}>
              <S.CloseIcon />
            </S.CloseContainer>
          </S.TitleWrapper>
          <S.StatusWrapper>
            <S.StatusContainer>
              <S.Status>
                <StatusLabel status={status} />
              </S.Status>
            </S.StatusContainer>
            <S.DateContainer>
              <S.Date>
                <S.IconContainer>
                  <Calendar />
                </S.IconContainer>
                {t('analysis.preview.creationDate')} {createdAtDate}
              </S.Date>
            </S.DateContainer>
          </S.StatusWrapper>
        </S.HeaderWrapper>
        <S.VisualizationWrapper>
          {(canDownloadVisualization || isPaymentRequired) && (
            <S.TitleContainer>
              <S.VisualizationTitle>
                {t('analysis.preview.visualisation')}
              </S.VisualizationTitle>
            </S.TitleContainer>
          )}
          <S.SubmissionFilesWrapper>
            {resultsFiles.files
              .filter((t) => t.mimetype.includes('image/'))
              .slice(0, 10)
              .map(({ id, signedUrl, filename, mimetype, submission_id }) => {
                return mimetype.includes('image/') ? (
                  <S.ImageWrapper key={id}>
                    <S.DownloadImageContainer>
                      <S.DownloadIcon2
                        onClick={(e) =>
                          openFileDownload(e, submission_id, id, filename)
                        }
                      />
                    </S.DownloadImageContainer>
                    <S.ImageContainer
                      ref={playerRef}
                      onClick={() => {
                        setIsModalOpen(true);
                        handleSetSrcToModal(signedUrl);
                      }}
                    >
                      <S.Image src={signedUrl} />
                    </S.ImageContainer>
                  </S.ImageWrapper>
                ) : (
                  <S.ImageWrapper key={id}>
                    <S.DownloadImageContainer>
                      <S.DownloadIcon2
                        onClick={(e) =>
                          openFileDownload(e, submission_id, id, filename)
                        }
                      />
                    </S.DownloadImageContainer>
                    <S.ImageContainer>
                      <S.MovieIconContainer>
                        <BiMoviePlay />
                      </S.MovieIconContainer>
                    </S.ImageContainer>
                  </S.ImageWrapper>
                );
              })}
          </S.SubmissionFilesWrapper>
          {isPaymentRequired && (
            <S.PaymentRequiredSection>
              <S.ExclamationMarkContainer>
                <img alt="exclamation" src={ExclamationMark} />
              </S.ExclamationMarkContainer>
              <div>
                <S.PaymentRequiredBody>
                  {t('analysis.preview.analysisNotPaid')}
                </S.PaymentRequiredBody>
                <S.PayButton onClick={(e: any) => {}}>
                  <S.WhiteLabelContainer>
                    <S.WhiteLabel onClick={handlePaymentIntent}>
                      {t('analysis.preview.payForAnalysis')}
                    </S.WhiteLabel>
                  </S.WhiteLabelContainer>
                </S.PayButton>
              </div>
            </S.PaymentRequiredSection>
          )}
          {canDownloadVisualization && (
            <S.DownloadButton onClick={handleDownloadResults}>
              <S.DownloadIcon />
              {t('analysis.preview.downloadAll')}{' '}
              {resultsFilesSize !== 0 && `(${resultsFilesSize})`}
            </S.DownloadButton>
          )}
        </S.VisualizationWrapper>
        {isDescriptionSectionVisible && (
          <S.DescriptionWrapper>
            <S.TitleContainer>
              <S.DescriptionTitle>
                {t('analysis.preview.description')}
              </S.DescriptionTitle>
            </S.TitleContainer>
            <S.SubmissionFilesWrapper>
              {submissionFiles.files
                .filter((t) => t.mimetype.includes('image/'))
                .slice(0, 10)
                .map(({ id, filename, signedUrl }) => (
                  <S.ImageWrapper key={id}>
                    <S.DownloadImageContainer>
                      <S.DownloadIcon2
                        onClick={(e) =>
                          openFileDownload(e, submissionId, id, filename)
                        }
                      />
                    </S.DownloadImageContainer>
                    <S.ImageContainer
                      ref={playerRef}
                      onClick={() => {
                        setIsModalOpen(true);
                        handleSetSrcToModal(signedUrl);
                      }}
                    >
                      <S.Image src={signedUrl} />
                    </S.ImageContainer>
                  </S.ImageWrapper>
                ))}
            </S.SubmissionFilesWrapper>
          </S.DescriptionWrapper>
        )}
        {isDicomSectionVisible && (
          <S.DicomWrapper>
            <S.TitleContainer>
              <S.DicomTitle>{t('analysis.preview.dicom')}</S.DicomTitle>
            </S.TitleContainer>
            <S.DicomContainer>
              <S.InfoWrapper>
                <S.InfoContainer>
                  <S.FolderIconContainer>
                    <S.FolderIcon />
                  </S.FolderIconContainer>
                  <S.DetailsWrapper>
                    <S.Details>
                      <S.TitleContainer2>
                        <S.Title2>{description}</S.Title2>
                      </S.TitleContainer2>
                      <S.DetailsContainer>
                        {numberOfFiles > 0
                          ? `${numberOfFiles} ${t(
                              'analysis.preview.filesNumber'
                            )} |`
                          : ''}{' '}
                        {dicomFilesSize}
                      </S.DetailsContainer>
                    </S.Details>
                    <S.ButtonsWrapper></S.ButtonsWrapper>
                  </S.DetailsWrapper>
                </S.InfoContainer>
              </S.InfoWrapper>
            </S.DicomContainer>
          </S.DicomWrapper>
        )}
        {/* {!isPaymentRequired && ( */}
        <S.ActionButtonWrapper>
          {canDeleteSubmission && (
            <S.DeleteButtonWrapper>
              {/* <S.DeleteButton onClick={openAllFilesDownload} > */}
              <S.DeleteButton onClick={() => setIsDeleteModalOpen(true)}>
                <S.DeleteIcon />
                {t('analysis.preview.delete')}{' '}
                {submittedFilesSize !== 0 && `(${submittedFilesSize})`}
              </S.DeleteButton>
            </S.DeleteButtonWrapper>
          )}
          <S.DownloadButtonWrapper>
            {canDownloadVisualization && (
              <S.DownloadAnalysisWrapper>
                <S.DownloadAllButton onClick={handleDownloadResults}>
                  <S.DownloadIcon />
                  {t('analysis.preview.download')}{' '}
                  {resultsFilesSize !== 0 && `(${resultsFilesSize})`}
                </S.DownloadAllButton>
              </S.DownloadAnalysisWrapper>
            )}
            <S.DownloadAllWrapper>
              <S.DownloadAllButton onClick={openAllFilesDownload}>
                <S.DownloadIcon />
                {t('analysis.preview.downloadAll')}{' '}
                {submittedFilesSize !== 0 && `(${submittedFilesSize})`}
              </S.DownloadAllButton>
            </S.DownloadAllWrapper>
          </S.DownloadButtonWrapper>
        </S.ActionButtonWrapper>
        {/* )} */}
      </S.Wrapper>
      {isDeleteModalOpen && (
        <S.DeleteModalWrapper onClick={() => setIsDeleteModalOpen(false)}>
          <S.DeleteModalContainer onClick={(e) => e.stopPropagation()}>
            <S.DeleteModalTitle>
              {t('analysis.deleteModal.question')}
            </S.DeleteModalTitle>
            {/* <S.DeleteModalBody>
              {submissionFiles.zip.map(({ id }) => (
                <S.LabelContainer key={id} >
                  <S.Checkbox type="checkbox" onChange={(e) => toggleDeleteSelectedItem(id, e.target.checked)} />
                  <S.FileLabel>
                    {t("analysis.deleteModal.dicomFiles")}
                  </S.FileLabel>
                </S.LabelContainer>
              ))}
              {submissionFiles.files.map(({ id, filename }) => (
                <S.LabelContainer key={id}>
                  <S.Checkbox type="checkbox" onChange={(e) => toggleDeleteSelectedItem(id, e.target.checked)} />
                  <S.FileLabel>
                    {filename}
                  </S.FileLabel>
                </S.LabelContainer >
              ))}
            </S.DeleteModalBody> */}
            <S.DeleteModalButtonContainer>
              <S.CancelButton onClick={() => setIsDeleteModalOpen(false)}>
                {t('analysis.deleteModal.cancel')}
              </S.CancelButton>
              <S.ConfirmButton onClick={handleDeleteItems}>
                {t('analysis.deleteModal.submit')}
              </S.ConfirmButton>
            </S.DeleteModalButtonContainer>
          </S.DeleteModalContainer>
        </S.DeleteModalWrapper>
      )}
    </S.DrawerContainer>
  );
};

export default SideDrawer;
