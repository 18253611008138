import MobileRow from './MobileRow';
import TabletRow from './TabletRow';
import DesktopRow from './DesktopRow';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { ANALYSIS_STATUS } from '../../../utils/types';
import { useState } from 'react';
import { ILabelTheme } from '../../MyAnalysis/AnalysisItem/AnalysisItem';
import { ISubmission } from '../../../redux/submission/types';
import {
  destroyArchivedSubmission,
  markSubmissionAsArchived,
  reminderSubmissionFiles,
  restoreArchivedSubmission,
  reminderSubmissionPayment,
  downloadFiles,
} from '../../../redux/submission/actions';
import { useDispatch, useSelector } from 'react-redux';
import { statusesLabelsThemes } from '../../../utils/submission';
import { useTranslation } from 'react-i18next';
import { getISOFormatDate } from '../../../utils/date';
import { BASE_URL } from '../../../api/common-api';
import { history } from '../../../App';
import { userData } from '../../../redux/auth/selectors';
export interface ITableRow extends ISubmission {
  count: number;
}

export interface IExtendedRow extends ITableRow {
  isSelected: boolean;
  isArchived: boolean;
  isReady: boolean;
  toggleIsArchived: () => any;
  label: ILabelTheme;
  formattedDate: string;
  buttonLabel: string;
  isTooltipOn: boolean;
  setIsTooltipOn: (value: boolean) => void;
  action: () => void;
  submissionId: string;
  handleDownloadFile: () => void;
  isAdmin: boolean;
}

export default function TableRow(data: ITableRow) {
  const { t } = useTranslation();
  const {
    status,
    created_at,
    id,
    isArchived: isArchivedInitial,
    isTouchedByOperator: isTouchedByOperatorInitial,
  } = data;
  const email = data.createdBy ? data.createdBy.email : '';
  const dispatch = useDispatch();
  const { role } = useSelector(userData);
  const [isTooltipOn, setIsTooltipOn] = useState(false);
  const [isArchived, setIsArchived] = useState(isArchivedInitial);
  const [isTouchedByOperator] = useState(isTouchedByOperatorInitial);
  const isTablet = useMediaQuery('(min-width: 616px)');
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const selectedLabel: ILabelTheme = statusesLabelsThemes[status];
  const isReady = status === ANALYSIS_STATUS.READY;
  const isAdmin = role === 'admin';

  const getDynamicAction = () => {
    if (isArchived) {
      return handleDestroy();
    }
    switch (status) {
      case ANALYSIS_STATUS.SENT: {
        history.push(`/results/${id}/file`);
        break;
      }
      case ANALYSIS_STATUS.NEW_ANALYSIS: {
        handleDownloadFile();
        break;
      }
      case ANALYSIS_STATUS.NOT_PAID: {
        return dispatch(reminderSubmissionPayment(id));
      }
      case ANALYSIS_STATUS.NO_FILES: {
        return dispatch(reminderSubmissionFiles(id));
      }
      case ANALYSIS_STATUS.READY: {
        history.push(`/results/${id}/file`);
        break;
      }
      default: {
        return '';
      }
    }
  };

  const getFileLabel = () => {
    if (isArchived) {
      return t('analysis.buttons.delete');
    }
    switch (status) {
      case ANALYSIS_STATUS.SENT: {
        return t('analysis.buttons.add');
      }
      case ANALYSIS_STATUS.NEW_ANALYSIS: {
        return t('analysis.buttons.download');
      }
      case ANALYSIS_STATUS.NOT_PAID: {
        return t('analysis.buttons.remindAboutPayments');
      }
      case ANALYSIS_STATUS.NO_FILES: {
        return t('analysis.buttons.remindAboutFiles');
      }
      case ANALYSIS_STATUS.READY: {
        return t('analysis.buttons.change');
      }
      default: {
        return '';
      }
    }
    // return isArchived ? t('analysis.buttons.delete') : isReady ? t('analysis.buttons.change') : t('analysis.buttons.add');
  };

  const buttonLabel = getFileLabel();
  const formattedDate = getISOFormatDate(created_at);

  const handleSetRemove = () => {
    setIsArchived(!isArchived);
    if (!isArchived) {
      dispatch(markSubmissionAsArchived(id));
    } else {
      dispatch(restoreArchivedSubmission(id));
    }
  };

  const handleDownloadFile = () => {
    window.location.assign(`${BASE_URL}/submission/${id}/files`);
    dispatch(downloadFiles(id));
  };

  const handleDestroy = () => {
    dispatch(destroyArchivedSubmission(id));
  };

  const rowProps = {
    email,
    isReady,
    isArchived,
    toggleIsArchived: handleSetRemove,
    label: selectedLabel,
    isSelected: status === ANALYSIS_STATUS.NEW_ANALYSIS && !isTouchedByOperator,
    formattedDate,
    buttonLabel,
    isTooltipOn,
    setIsTooltipOn,
    action: () => getDynamicAction(), //isArchived ? handleDestroy : addSubmissionResults,
    submissionId: id,
    handleDownloadFile,
    isTouchedByOperator,
    isAdmin,
  };

  return (
    <>
      {isDesktop && <DesktopRow {...data} {...rowProps} />}
      {!isDesktop && isTablet && <TabletRow {...data} {...rowProps} />}
      {!isDesktop && !isTablet && <MobileRow {...data} {...rowProps} />}
    </>
  );
}
