import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import SubmissionNavigation from '../SubmissionNavigation/SubmissionNavigation';
import { useHistory } from 'react-router';
import Dropzone from '../../Dropzone/Dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetUploadPercentage,
  uploadDicoms,
} from '../../../redux/submission/actions';
import { useUserEmail, useUserId } from '../../../redux/auth/selectors';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useSelectedSubmissionFiles,
  useUploadPercentage,
} from '../../../redux/submission/selectors';
import { fetchSubmission } from '../../../api/common-api';
import { extractZIPFiles } from '../../../utils/submission';
import { ISubmission } from '../../../redux/submission/types';
import i18n from '../../../i18n';
import * as S from './DicomStep.styles';

export default function DicomStep() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { submissionId } = useParams() as { submissionId: string };

  const userEmail = useSelector(useUserEmail);
  const userId = useSelector(useUserId);
  const uploadPercentage = useSelector(useUploadPercentage);
  const selectedSubmissionFiles = useSelector(useSelectedSubmissionFiles);
  const isAlreadyUploadedDicom =
    selectedSubmissionFiles.submitted.zip.length > 0;
  const [files, setFiles] = useState<File[]>([]);
  const onDrop = useCallback((acceptedFiles) => setFiles(acceptedFiles), []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);
  useEffect(() => {
    const getSubmissionData = async () => {
      const submissionData = (await fetchSubmission(
        submissionId
      )) as ISubmission;
      const isOwner = userId === submissionData.created_by;
      const uploadedZipFiles = extractZIPFiles(submissionData);
      if (!isOwner || uploadedZipFiles.length > 0) {
        return history.push('/');
      }
    };
    getSubmissionData();
    setIsLoading(false);
  }, [submissionId, userId, history]);

  useEffect(() => {
    dispatch(resetUploadPercentage);
  }, [dispatch]);

  const GoBackToStartPanel = () => history.push('/');

  const handleSubmit = useCallback((): void => {
    dispatch(
      uploadDicoms({
        email: userEmail,
        submissionId,
        files,
        locale: selectedLanguage ? selectedLanguage : 'pl',
        isResults: false,
        onUploadCompleted: () => {}, // false ? navigateToDashboard : onUploadCompleted
      })
    );
  }, [dispatch, files, submissionId, userEmail, selectedLanguage]);

  const isUploadingFiles = uploadPercentage > 0;
  const isNextStepDisabled =
    isLoading || files.length === 0 || isUploadingFiles;
  const dropzoneDisabled = isLoading || isUploadingFiles;

  if (isAlreadyUploadedDicom) {
    return <Redirect to="/" />;
  }

  return (
    <S.Wrapper>
      <SubmissionNavigation activeStep={1} />
      <S.StepWrapper>
        <S.DescriptionContainer>
          <S.Name>{t('steps.dicom.uploadDicom')}</S.Name>
        </S.DescriptionContainer>
        <Dropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          files={files}
          isVerticalOrientation
          isDirectory
          key={submissionId}
          disabled={dropzoneDisabled}
        />
        <S.ButtonContainer>
          <S.NextStepButton
            disabled={isNextStepDisabled}
            onClick={handleSubmit}
          >
            {t('steps.nextStep')}
          </S.NextStepButton>
          <S.BackButton
            isDisabled={isUploadingFiles}
            disabled={isUploadingFiles}
            onClick={GoBackToStartPanel}
          >
            {isUploadingFiles ? <S.BackArrowDisabled /> : <S.BackArrow />}
            {t('steps.backToPanel')}
          </S.BackButton>
        </S.ButtonContainer>
      </S.StepWrapper>
    </S.Wrapper>
  );
}
