import { AppState } from '../store';

export const authState = (state: AppState) => state.auth;

export const useUserLoggedIn = (state: AppState) => authState(state).loggedIn;

export const useIsLoading = (state: AppState) => authState(state).isLoading;

export const userData = (state: AppState) => authState(state).user;

export const useUserEmail = (state: AppState) => authState(state).user.email;

export const useUserId = (state: AppState) => authState(state).user.id;

export const signInError = (state: AppState) =>
  authState(state).signInErrorMessage;

export const changePasswordError = (state: AppState) =>
  authState(state).changePasswordErrorMessage;

export const useIsAccountConfirmed = (state: AppState) =>
  authState(state).isAccountConfirmationConfirmed;

export const useSignInErrorMessage = (state: AppState) =>
  authState(state).signInErrorMessage;

export const useNotifications = (state: AppState) =>
  authState(state).user.notifications;

export const useUnreadNotificationsCounter = (state: AppState) => {
  const { notifications } = authState(state).user;
  if (!notifications) {
    return 0;
  }
  return notifications.filter((n) => !n.isRead).length;
};
