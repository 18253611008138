import useMediaQuery from '../../../hooks/useMediaQuery';
import colorTheme from '../../../colorTheme';
import FailedCross from '../../../assets/common/failed-step.svg';
import * as S from './SubmissionNavigation.styles';

export interface ISubmissionNavigationStep {
  hideLeftSide?: boolean;
  hideRightSide?: boolean;
  isActive?: boolean;
  isCompleted?: boolean;
  order: number;
  label: string;
  isFailure?: boolean;
  isGrey?: boolean;
}

export default function SubmissionNavigationStep({
  hideLeftSide,
  hideRightSide,
  isActive,
  isCompleted,
  label,
  order,
  isFailure,
  isGrey,
}: ISubmissionNavigationStep) {
  const isTablet = useMediaQuery('(min-width: 767px');
  const stepNumber = order + 1;
  const { gray, blue, white } = colorTheme;

  const isBlue = isActive || isCompleted;
  const leftColor = hideLeftSide ? gray : isBlue ? blue : white;
  const rightColor = hideRightSide ? '' : isBlue ? blue : white;
  const last = stepNumber === 4;
  const greyProgressBar = stepNumber === 3 && isFailure;

  return (
    <S.Wrapper>
      <S.StepWrapper>
        <S.StepContainer>
          <S.Progress
            isGrey={isGrey}
            isLast={false}
            color={leftColor}
          ></S.Progress>
          <S.Step
            isBlue={isBlue}
            isActive={isActive}
            isFailure={isFailure}
            isGrey={isGrey}
          >
            {isCompleted ? (
              isFailure ? (
                <img src={FailedCross} alt="cross" />
              ) : (
                <S.CheckMark />
              )
            ) : (
              stepNumber
            )}
          </S.Step>
          <S.Progress
            isGrey={greyProgressBar}
            isLast={last}
            color={rightColor}
          ></S.Progress>
        </S.StepContainer>
      </S.StepWrapper>
      <S.TextsWrapper>
        {isTablet && <S.Text>{label}</S.Text>}
        {!isTablet && isActive && <S.Text>{label}</S.Text>}
      </S.TextsWrapper>
    </S.Wrapper>
  );
}
