import { IExtendedRow } from './TableRow';
import { useTranslation } from 'react-i18next';
import * as S from './TableRow.styles';

export default function DesktopRow({
  email,
  files,
  description,
  formattedDate,
  isSelected,
  count,
  toggleIsArchived,
  isArchived,
  isReady,
  buttonLabel,
  isTooltipOn,
  setIsTooltipOn,
  label: { background, color, message, icon },
  action,
  isTouchedByOperator,
  filesUploaded,
  handleDownloadFile,
  isAdmin
}: IExtendedRow) {
  const { t } = useTranslation();
  const selectedBackground = isSelected ? '#fff' : background;
  const selectedColor = isSelected ? color : '#fff';
  const showDeleteTooltip = isTooltipOn && !isArchived && isAdmin;
  const showRestoreTooltip = isTooltipOn && isArchived && isAdmin;
  const showIcons = isAdmin;
  const dateVals = formattedDate.split(', ');
  const formattedDay = dateVals[0];
  const formattedTime = dateVals[1];
  const quantity = filesUploaded?.resultFiles?.length || 0;
  const isAnalysisButtonDisabled = isArchived && !isAdmin
  return (
    <S.DesktopWrapper
      isRemoved={isArchived}
      isSelected={isSelected}
      color={background}
    >
      <S.Border isRemoved={isArchived} color={selectedColor} />
      <S.DesktopCounter isSelected={isSelected}>{count}</S.DesktopCounter>
      <S.DesktopHeader>{description}</S.DesktopHeader>
      <S.DesktopDataValue isSelected={isSelected}>{email}</S.DesktopDataValue>
      <S.DesktopQuantityValue isSelected={isSelected}>
        {quantity ? quantity : '-'}
      </S.DesktopQuantityValue>
      <S.DesktopDataValue isSelected={isSelected}>
        <div>{formattedDay}</div>
        <div>{formattedTime}</div>
      </S.DesktopDataValue>
      <S.DesktopLabelContainer>
        <S.TabletLabelWrapper
          isGrayedOut={isArchived}
          backgroundColor={selectedBackground}
        >
          <S.LabelIcon src={icon} alt={t(message)} />
          <S.LabelText color={color}>{t(message)}</S.LabelText>
        </S.TabletLabelWrapper>
      </S.DesktopLabelContainer>
      <S.DesktopButtonsContainer>
        {true ? (
          <S.DownloadIcon onClick={handleDownloadFile} />
        ) : (
          <div style={{ width: '16px', paddingRight: '2px' }}></div>
        )}
        {!isAnalysisButtonDisabled && action && buttonLabel && (
          <S.AddAnalysisButton
            isReady={isReady && !isArchived}
            isRemoved={isArchived}
            onClick={action}
          >
            {buttonLabel}
          </S.AddAnalysisButton>
        )}
        <S.RemoveButton
          onMouseEnter={() => setIsTooltipOn(true)}
          onMouseLeave={() => setIsTooltipOn(false)}
          onClick={toggleIsArchived}
        >
          {showDeleteTooltip && (
            <S.ArchiveTooltip>
              {t('analysis.table.reportDelete')}
            </S.ArchiveTooltip>
          )}
          {showRestoreTooltip && (
            <S.RestoreTooltip>{t('analysis.table.restore')}</S.RestoreTooltip>
          )}
          {showIcons && (isArchived ? <S.RestoreIcon /> : <S.TrashIcon />)}
        </S.RemoveButton>
      </S.DesktopButtonsContainer>
    </S.DesktopWrapper>
  );
}
