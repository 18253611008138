import * as A from './actionTypes';
import * as api from '../../api/common-api';
import { history } from '../../App';
import { ANALYSIS_STATUS } from '../../utils/types';
import { toast } from 'react-toastify';
import { AppState } from '../store';
import { SUBMISSION_FIELDS } from './submissionReducer';

export interface CreateSubmissionParams {
  description: string;
  files: any;
  references?: string;
  onUploadCompleted?: () => void | Promise<void>;
}

export interface CreateResultsSubmissionParams {
  description: string;
  references?: string;
  files: any;
  onUploadCompleted?: () => void | Promise<void>;
}

export interface UploadSubmissionPhotos extends CreateSubmissionParams {
  submissionId: string;
}

export interface UploadSubmissionDicoms {
  email: string;
  submissionId: string;
  files: any;
  locale: string;
  isResults: boolean;
  onUploadCompleted?: () => void | Promise<void>;
}

export interface IPaymentIntent {
  email: string;
  submissionId: string;
  locale: string;
}

export function createSubmission(payload: CreateSubmissionParams) {
  const { description } = payload;
  return async function (dispatch: any) {
    dispatch({ type: A.CREATE_SUBMISSION, payload });
    return api.createSubmission({ description }).then(
      ({ id: submissionId }: any) => {
        dispatch({ type: A.CREATE_SUBMISSION_SUCCESS, data: { submissionId } });
        dispatch(uploadSubmissionPhotos({ ...payload, submissionId }));
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function createResultsSubmission(
  payload: CreateResultsSubmissionParams
) {
  const { description, references } = payload;
  return async function (dispatch: any) {
    dispatch({ type: A.CREATE_SUBMISSION, payload });
    return api.createSubmission({ description, references }).then(
      ({ id: submissionId }: any) => {
        dispatch({
          type: A.CREATE_RESULTS_SUBMISSION_SUCCESS,
          data: { submissionId },
        });
        dispatch(uploadSubmissionResultsPhotos({ ...payload, submissionId }));
        // await uploadFiles({ id: submissionRes.data.id, files, onUploadCompleted, isResults })
        history.push(`/results/${references}/file`);
        // dispatch(uploadSubmissionPhotos({ ...payload, submissionId }))
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export const resetUploadPercentage = () => ({
  type: A.RESET_UPLOAD_PERCENTAGE,
});

export function uploadSubmissionPhotos(payload: UploadSubmissionPhotos) {
  const { submissionId, files, onUploadCompleted } = payload;
  return async function (dispatch: any) {
    dispatch({ type: A.UPLOAD_SUBMISSION_PHOTOS, payload });

    const onPercentageUpdated = (percentage: number) => {
      dispatch({ type: A.UPLOAD_PERCENTAGE_CHANGED, data: { percentage } });
    };

    return api
      .uploadFilesQueued(
        { submissionId, files, onUploadCompleted },
        onPercentageUpdated
      )
      .then(
        () => {
          dispatch({
            type: A.UPLOAD_SUBMISSION_PHOTOS_SUCCESS,
            data: { submissionId },
          });
          history.push(`/submission/${submissionId}/dicom`);
        },
        (error: any) => console.log('An error occurred.', error)
      );
  };
}

export function uploadSubmissionResultsPhotos(payload: UploadSubmissionPhotos) {
  const { submissionId, files, onUploadCompleted } = payload;
  return async function (dispatch: any) {
    dispatch({ type: A.UPLOAD_SUBMISSION_PHOTOS, payload });

    const onPercentageUpdated = (percentage: number) => {
      dispatch({ type: A.UPLOAD_PERCENTAGE_CHANGED, data: { percentage } });
    };

    return api
      .uploadFilesQueued(
        { submissionId, files, onUploadCompleted },
        onPercentageUpdated
      )
      .then(
        () => {
          dispatch({
            type: A.UPLOAD_SUBMISSION_PHOTOS_SUCCESS,
            data: { submissionId },
          });
          history.push('/analysis-list');
        },
        (error: any) => console.log('An error occurred.', error)
      );
  };
}

export const executePaymentIntent = ({
  submissionId,
  email,
  locale,
}: IPaymentIntent) => {
  return async (dispatch: any) => {
    const { id: paymentSessionId } = await api.createPaymentsSession({
      submissionId,
      email,
      locale,
    });
    dispatch({
      type: A.CREATE_PAYMENT_SESSION_SUCCESS,
      data: { paymentSessionId },
    });
    history.push(`/submission/${submissionId}/payment/${paymentSessionId}`);
    dispatch(setDrawerSubmissionId(''));
  };
};

export enum UPLOAD_TYPE {
  UPLOAD = 'UPLOAD',
  ZIP = 'ZIP',
}

export function uploadDicoms(payload: UploadSubmissionDicoms) {
  const { email, locale, isResults, submissionId, files, onUploadCompleted } =
    payload;
  return async function (dispatch: any) {
    dispatch({ type: A.UPLOAD_SUBMISSION_DICOMS, payload });

    const onPercentageUpdated = (percentage: number, type: UPLOAD_TYPE) => {
      dispatch({
        type: A.UPLOAD_PERCENTAGE_CHANGED,
        data: { percentage, type },
      });
    };

    if (isResults) {
      const {
        data: { id: newSubmissionId },
      } = await api.createSubmission({
        description: `results_${submissionId}`,
        references: submissionId,
      });
      await api.uploadFilesAsZip(
        {
          submissionId: newSubmissionId,
          files,
          onUploadCompleted,
          isResults,
        },
        onPercentageUpdated
      );
    } else {
      await api.uploadFilesAsZip(
        {
          submissionId,
          files,
          onUploadCompleted,
          isResults,
        },
        onPercentageUpdated
      );
      const { id: paymentSessionId } = await api.createPaymentsSession({
        submissionId,
        email,
        locale,
      });
      dispatch({ type: A.UPLOAD_SUBMISSION_DICOMS_SUCCESS, payload });
      dispatch({
        type: A.CREATE_PAYMENT_SESSION_SUCCESS,
        data: { paymentSessionId },
      });
      history.push(`/submission/${submissionId}/payment/${paymentSessionId}`);
    }
  };
}

export function markSubmissionAsArchived(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.MARK_SUBMISSION_AS_ARCHIVED });
    return api.markSubmissionAsArchived(id).then(
      (data: any) => {
        dispatch({ type: A.MARK_SUBMISSION_AS_ARCHIVED_SUCCESS, data });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function restoreArchivedSubmission(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.RESTORE_ARCHIVED_SUBMISSION });
    return api.restoreArchivedSubmission(id).then(
      (data: any) => {
        dispatch({ type: A.RESTORE_ARCHIVED_SUBMISSION_SUCCESS, data });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export const downloadFiles = (submissionId: string) => ({
  type: A.REMOVE_NEW_STATUS,
  data: { submissionId },
});

export function destroyArchivedSubmission(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.DESTROY_ARCHIVED_SUBMISSION });
    return api.destroyArchivedSubmission(id).then(
      (data: any) => {
        dispatch({ type: A.DESTROY_ARCHIVED_SUBMISSION_SUCCESS, data: { id } });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function fetchSubmissions() {
  return async function (dispatch: any, getState: () => AppState) {
    dispatch({ type: A.FETCH_SUBMISSIONS });
    return api.fetchSubmissions().then(
      (subs: any) => {
        const isUser = getState().auth.user.role === 'user';
        dispatch({
          type: A.FETCH_SUBMISSIONS_SUCCESS,
          data: { subs, isAdmin: !isUser },
        });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export const setDrawerSubmissionId = (id: string) => ({
  type: A.SET_DRAWER_SUBMISSION_ID,
  data: { id },
});

export interface ISearchParams {
  query?: string;
  perPage?: number;
  page?: number;
  status?: ANALYSIS_STATUS;
  dateMin?: string | null;
  dateMax?: string | null;
}

export const setSearchParams = (data: ISearchParams) => ({
  type: A.SET_SEARCH_PARAMS,
  data,
});

export function reminderSubmissionFiles(id: string) {
  return async function (dispatch: any) {
    return api.reminderSubmissionFiles(id).then(
      (data: any) => {
        toast.success('Wiadomość wysłana');
      },
      (error: any) => {
        toast.warning('Nie udało się wysłać wiadomości');
        console.log('An error occurred.', error);
      }
    );
  };
}

export function reminderSubmissionPayment(id: string) {
  return async function (dispatch: any) {
    return api.reminderSubmissionPayment(id).then(
      (data: any) => {
        toast.success('Wiadomość wysłana');
      },
      (error: any) => {
        toast.warning('Nie udało się wysłać wiadomości');
        console.log('An error occurred.', error);
      }
    );
  };
}

export function archiveOwnSubmission(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.ARCHIVE_SUBMISSION });
    return api.archiveOwnSubmission(id).then(
      (data: any) => {
        dispatch({ type: A.ARCHIVE_SUBMISSION_SUCCESS, data: { id } });
        dispatch(setDrawerSubmissionId(''));
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export const setSubmissionListSort = (
  direction: 'desc' | 'asc',
  field: SUBMISSION_FIELDS
) => ({
  type: A.SORT_SUBMISSIONS_TABLE,
  data: { direction, field },
});
