import pl from '../../assets/common/flag-PL-160.png';
import us from '../../assets/common/flag-USA-160.png';
import de from '../../assets/common/flag-DE-160.png';
import styled from 'styled-components';
import { MdOutlineClose } from 'react-icons/md';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  /* border: 1px solid gray; */
`;

export const FlagContainer = styled.div`
  display: flex;
`;

// export const PlFlag = styled(PolishFlag)`
//   display: flex;
//   width: 60px;
//   height: 60px;
//   position: absolute;
// `;

export const PlFlag = styled.img.attrs({
  alt: 'pl flag',
  src: pl,
})`
  position: absolute;
  height: 100%;
  border: 1px solid grey;
  border-radius: 50%;
`;

export const EnFlag = styled.img.attrs({
  alt: 'en flag',
  src: us,
})`
  position: absolute;
  height: 100%;
  border: 1px solid grey;
  border-radius: 50%;
`;

export const DeFlag = styled.img.attrs({
  alt: 'de flag',
  src: de,
})`
  position: absolute;
  height: 100%;
  border: 1px solid grey;
  border-radius: 50%;
`;

// export const EnFlag = styled(EnglishFlag)`
//   display: flex;
//   width: 60px;
//   height: 60px;
//   position: absolute;
// `;

// export const DeFlag = styled(Deutsch)`
//   display: flex;
//   width: 60px;
//   height: 60px;
//   position: absolute;
// `;

export const LanguagePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 50px;
  background-color: #f4f5f9;
  padding: 50px;
  border-radius: 8px;
  position: relative;
  cursor: auto;
`;

export const LanguagePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
  z-index: 100;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const FlagWrapper = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  /* border: 1px solid gray; */
`;

export const LanguageContainer = styled.div`
  display: flex;
  margin: 12px 0;
  cursor: pointer;
`;
export const LanguageInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;
export const LanguageName = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 700;
`;
export const LanguageCurrency = styled.div`
  display: flex;
  font-size: 12px;
`;

export const CloseContainer = styled.button`
  background-color: #f4f5f9;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 48px;
  height: 48px;
  border: none;
  position: absolute;
  top: 3px;
  right: 6px;
  cursor: pointer;
`;

export const CloseIcon = styled(MdOutlineClose)`
  width: 26px;
  height: 26px;
  color: gray;
  &:hover {
    color: #07005c;
  }
`;
