import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SEOTagsSortParams,
  SEO_TAGS_FIELD,
} from '../../../../redux/admin/adminReducer';
import * as S from './SEOTableTagsHeader.styles';

interface ISEOTagsTableHeader {
  sortBy: SEOTagsSortParams;
  setSortBy: (value: SEOTagsSortParams) => void;
}

const SEOTableTagsHeader = ({
  sortBy: { field, direction },
  setSortBy,
}: ISEOTagsTableHeader) => {
  const { t } = useTranslation();
  const [hoverField, setHoverField] = useState<undefined | SEO_TAGS_FIELD>();
  const handleSort = (selectedField: SEO_TAGS_FIELD) => {
    if (selectedField === field && direction === 'asc') {
      return setSortBy({ field: selectedField, direction: 'desc' });
    }
    setSortBy({ field: selectedField, direction: 'asc' });
  };
  return (
    <S.TableHeaderWrapper>
      <S.TitleContainer>
        <S.SortByButton
          onClick={() => handleSort(SEO_TAGS_FIELD.TITLE)}
          onMouseEnter={() => setHoverField(SEO_TAGS_FIELD.TITLE)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === SEO_TAGS_FIELD.TITLE}>
            {t('seo.tableTags.title')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SEO_TAGS_FIELD.TITLE,
              isUp: field === SEO_TAGS_FIELD.TITLE && direction === 'desc',
              isHover: hoverField === SEO_TAGS_FIELD.TITLE,
            }}
          />
        </S.SortByButton>
      </S.TitleContainer>
      <S.ColumnDivider />
      <S.ImageContainer>
        <S.SortByButton
          onClick={() => handleSort(SEO_TAGS_FIELD.IMAGE)}
          onMouseEnter={() => setHoverField(SEO_TAGS_FIELD.IMAGE)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === SEO_TAGS_FIELD.IMAGE}>
            {t('seo.tableTags.image')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SEO_TAGS_FIELD.IMAGE,
              isUp: field === SEO_TAGS_FIELD.IMAGE && direction === 'desc',
              isHover: hoverField === SEO_TAGS_FIELD.IMAGE,
            }}
          />
        </S.SortByButton>
      </S.ImageContainer>
      <S.ColumnDivider />
      <S.UrlContainer>
        <S.SortByButton
          onClick={() => handleSort(SEO_TAGS_FIELD.URL)}
          onMouseEnter={() => setHoverField(SEO_TAGS_FIELD.URL)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === SEO_TAGS_FIELD.URL}>
            {t('seo.tableTags.url')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SEO_TAGS_FIELD.URL,
              isUp: field === SEO_TAGS_FIELD.URL && direction === 'desc',
              isHover: hoverField === SEO_TAGS_FIELD.URL,
            }}
          />
        </S.SortByButton>
      </S.UrlContainer>
      <S.ColumnDivider />
      <S.LocaleContainer>
        <S.SortByButton
          onClick={() => handleSort(SEO_TAGS_FIELD.LOCALE)}
          onMouseEnter={() => setHoverField(SEO_TAGS_FIELD.LOCALE)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === SEO_TAGS_FIELD.LOCALE}>
            {t('seo.tableTags.locale')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SEO_TAGS_FIELD.LOCALE,
              isUp: field === SEO_TAGS_FIELD.LOCALE && direction === 'desc',
              isHover: hoverField === SEO_TAGS_FIELD.LOCALE,
            }}
          />
        </S.SortByButton>
      </S.LocaleContainer>
      <S.ColumnDivider />
      <S.ActionContainer>
        <S.SortByButton
          onClick={() => handleSort(SEO_TAGS_FIELD.LOCALE)}
          onMouseEnter={() => setHoverField(SEO_TAGS_FIELD.LOCALE)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === SEO_TAGS_FIELD.LOCALE}></S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SEO_TAGS_FIELD.LOCALE,
              isUp: field === SEO_TAGS_FIELD.LOCALE && direction === 'desc',
              isHover: hoverField === SEO_TAGS_FIELD.LOCALE,
            }}
          />
        </S.SortByButton>
      </S.ActionContainer>
    </S.TableHeaderWrapper>
  );
};

export default SEOTableTagsHeader;
