import { ReactComponent as SmilingFaceIcon } from '../../assets/navbar/smilingFace.svg';
import { ReactComponent as HomeIcon } from '../../assets/navbar/home.svg';
import { ReactComponent as QuestionCircleIcon } from '../../assets/navbar/question-circle.svg';
import { ReactComponent as SettingsIcon } from '../../assets/navbar/settings.svg';
import { ReactComponent as FolderIcon } from '../../assets/navbar/folder.svg';
import { ReactComponent as SEOGlobeIcon } from '../../assets/navbar/seo-globe.svg';

import { useTranslation } from 'react-i18next';
import * as S from './Navbar.styles';
import { Role } from '../../redux/submission/types';

export interface ILink {
  name: string;
  href: string;
  Icon: any; //JSX.Element;
  isButton?: boolean;
}

export const Links = (role: string) => {
  if (role === Role.ADMIN) {
    return [
      {
        name: 'navbar.mainPanel',
        href: '/',
        Icon: HomeIcon,
      },
      {
        name: 'navbar.adminAnalysis',
        href: '/analysis-list',
        Icon: FolderIcon,
      },
      {
        name: 'navbar.help',
        href: '/help',
        Icon: QuestionCircleIcon,
      },
      {
        name: 'navbar.users',
        href: '/users',
        Icon: SmilingFaceIcon,
      },
      {
        name: 'navbar.seo',
        href: '/seo-tags',
        Icon: SEOGlobeIcon,
      },
      {
        name: 'navbar.settings',
        href: '/settings',
        Icon: SettingsIcon,
      },
    ];
  } else if (role === Role.OPERATOR) {
    return [
      {
        name: 'navbar.mainPanel',
        href: '/',
        Icon: HomeIcon,
      },
      {
        name: 'navbar.adminAnalysis',
        href: '/analysis-list',
        Icon: FolderIcon,
      },
      {
        name: 'navbar.help',
        href: '/help',
        Icon: QuestionCircleIcon,
      },
      {
        name: 'navbar.users',
        href: '/users',
        Icon: SmilingFaceIcon,
      },
      {
        name: 'navbar.seo',
        href: '/seo-tags',
        Icon: SEOGlobeIcon,
      },
      {
        name: 'navbar.settings',
        href: '/settings',
        Icon: SettingsIcon,
      },
    ];
  } else {
    return [
      {
        name: 'navbar.mainPanel',
        href: '/',
        Icon: HomeIcon,
      },
      {
        name: 'navbar.myAnalysis',
        href: '/my-analysis',
        Icon: FolderIcon,
      },
      {
        name: 'navbar.help',
        href: '/help',
        Icon: QuestionCircleIcon,
      },
      {
        name: 'navbar.settings',
        href: '/settings',
        Icon: SettingsIcon,
      },
    ];
  }
};

export default function NavbarLink({ name, href, Icon }: ILink) {
  const { t } = useTranslation();
  return (
    <S.LinkWrapper exact to={href} activeStyle={{ backgroundColor: '#D3F0F6' }}>
      <S.IconContainer>
        <Icon />
        {/* <S.Icon></S.Icon> */}
      </S.IconContainer>
      <S.NameWrapper>
        <S.Name>{t(name)}</S.Name>
      </S.NameWrapper>
    </S.LinkWrapper>
  );
}
