import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOgTags,
  setSearchSeoParams,
  setSEOTagsListSort,
} from '../../../redux/admin/actions';
import {
  filteredSEOTags,
  seoSearchParams,
} from '../../../redux/admin/selectors';
import SEOTableTagsRow, { ISEOTags } from './SEOTableTagsRow/SEOTableTagsRow';
import SEOTableTagsHeader from './SEOTableTagsHeader/SEOTableTagsHeader';
import Pagination from '../../AnalysisTable/Pagination/Pagination';
import {
  SEOTagsSortParams,
  SEO_TAGS_FIELD,
} from '../../../redux/admin/adminReducer';
import * as S from '../SEOTable.styles';

const SEOTableTags = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOgTags());
  }, [dispatch]);

  const { maxPage, results } = useSelector(filteredSEOTags);
  const [data, setData] = useState<ISEOTags[]>(results);

  const { page, perPage } = useSelector(seoSearchParams);
  const [sortBy, setSortBy] = useState<SEOTagsSortParams>({
    field: SEO_TAGS_FIELD.TITLE,
    direction: 'desc',
  });

  const setSelectedPage = (value: number) =>
    dispatch(setSearchSeoParams({ page: value }));
  const setItemsPerPage = (value: number) =>
    dispatch(setSearchSeoParams({ perPage: value }));

  useEffect(() => {
    setData(results);
  }, [results]);

  const handleSortBy = (params: SEOTagsSortParams) => {
    const { direction, field } = params;
    dispatch(setSEOTagsListSort(direction, field));
    setSortBy(params);
  };

  return (
    <S.Wrapper>
      <SEOTableTagsHeader sortBy={sortBy} setSortBy={handleSortBy} />
      <S.ResultsContainer>
        {data.map((row, index: number) => (
          <SEOTableTagsRow {...row} key={index} />
        ))}
      </S.ResultsContainer>
      <Pagination
        selectedPage={page}
        setSelectedPage={setSelectedPage}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={perPage}
        maxPage={maxPage}
      />
    </S.Wrapper>
  );
};

export default SEOTableTags;
