import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { CgSearch } from 'react-icons/cg';

export const SectionWrapper = styled.nav<{ hasSearch?: boolean }>`
  display: flex;
  transition: height 300ms;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  height: ${({ hasSearch }) => (hasSearch ? '172px' : '96px')};
  @media (min-width: 1024px) {
    height: 96px;
  }
`;

export const ExternalWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { white } }) => white};
  position: fixed;
  z-index: ${({
    theme: {
      zIndexes: { navbar },
    },
  }) => navbar};
`;

export const Wrapper = styled.div<{ isLeft?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavBody = styled.div`
  box-sizing: border-box;
  height: 96px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 32px;
  max-width: 1920px;
`;

export const NotificationWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
`;

export const NotificationSpan = styled.span`
  padding-right: 16px;
`;

export const NotificationLogo = styled.img`
  right: -4px;
  top: 0;
`;

export const CounterContainer = styled.div`
  display: flex;
  position: absolute;
  background-color: ${({ theme: { orange } }) => orange};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  align-items: center;
  justify-content: center;
  right: -4px;
  top: 0;
`;

export const Counter = styled.div`
  color: ${({ theme: { white } }) => white};
  font-family: Poppins;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({ theme: { greenDark } }) => greenDark};
  justify-content: center;
  cursor: pointer;
`;

export const User = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 1px;
  font-weight: 700;
  color: ${({ theme: { blue } }) => blue};
`;

export const LogoContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 116px;
  height: 34px;
  @media (min-width: 768px) {
    width: 160px;
    height: 47px;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 44px;
`;

export const PlainButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { white } }) => white};
  border: none;
  :focus {
    outline: none;
  }
`;

export const Label = styled.label`
  display: block;
  margin: 4px 0 0 2px;
  font-family: Poppins;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 44px;
  height: 38px;
  margin: auto 0 0;
`;

const IconEl = `
  width: 32px;
  height: 4px;
  position: absolute;
  border-radius: 8px;
  `;

export const IconEl1 = styled.div<{ isOpen: boolean }>`
  ${IconEl}
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  left: 6px;
  top: ${({ isOpen }) => (isOpen ? '17px' : '2px')};
  transform: rotate(${({ isOpen }) => (isOpen ? '45deg' : '0')});
  transition: 300ms;
`;

export const IconEl2 = styled.div<{ isOpen: boolean }>`
  ${IconEl}
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  left: 6px;
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  top: 17px;
  transition: 300ms;
`;

export const IconEl3 = styled.div<{ isOpen: boolean }>`
  ${IconEl}
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  left: 6px;
  bottom: ${({ isOpen }) => (isOpen ? '17px' : '2px')};
  transform: rotate(${({ isOpen }) => (isOpen ? '-45deg' : '0')});
  transition: 250ms;
`;
export const Vector = styled.img`
  width: 20px;
  height: 10px;
  cursor: pointer;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 16px;
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const RoleName = styled.div`
  font-size: 12px;
  color: #07005c;
`;

export const LinkWrapper = styled(NavLink)`
  margin-bottom: 8px;
  display: flex;
  padding: 14px 17px;
  border-radius: 4px;
  transition: 0.5s;
  text-decoration: none;
  align-items: center;
  min-width: 201px;
  &:hover {
    background-color: ${({ theme: { greenDark } }) => greenDark};
  }
  @media (min-width: 1024px) {
    width: auto;
  }
`;

export const IconContainer = styled.div`
  padding-right: 16px;
  display: flex;
`;

export const Icon = styled.img``;

export const NameWrapper = styled.div``;

export const Name = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const MobileMenuWrapper = styled.div<{ isVisible?: boolean }>`
  position: absolute;
  top: 96px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-around;
  height: ${({ isVisible }) => (isVisible ? '50vh' : 0)};
  transition: 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme: { white } }) => white};
  overflow: hidden;
  z-index: ${({
    theme: {
      zIndexes: { navbar },
    },
  }) => navbar};
  border-bottom: 1px solid ${({ theme: { gray } }) => gray};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
export const SendAnalysisButton = styled.button<{ isSideMenu?: boolean }>`
  max-width: 298px;
  max-height: 48px;
  font-size: ${({ isSideMenu }) => (isSideMenu ? '10px' : '15px')};
  line-height: 22.5px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  color: ${({ theme: { white } }) => white};
  background: linear-gradient(to right, #41bbff, #086ee7);
  padding: 12.5px 40px;
  border-radius: 4px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 39px #41bbff;
  }
  @media (min-width: 1100px) {
    font-size: ${({ isSideMenu }) => (isSideMenu ? '12px' : '15px')};
  }
  @media (min-width: 1150px) {
    font-size: ${({ isSideMenu }) => (isSideMenu ? '13px' : '15px')};
  }
  @media (min-width: 1250px) {
    font-size: 15px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

export const LogoutButton = styled.button<{ isSideMenu?: boolean }>`
  font-size: ${({ isSideMenu }) => (isSideMenu ? '10px' : '15px')};
  font-size: 15px;
  line-height: 22.5px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  color: #41bbff;
  background: white;
  border: 3px solid #41bbff;
  padding: 12.5px 8px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: 250ms;
  &:hover {
    box-shadow: 0px 4px 11px #41bbff;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px auto;
  width: 100%;
  padding: 0 36px;
  box-sizing: border-box;
  @media (min-width: 1024px) {
    padding: 0;
    width: fit-content;
    justify-content: flex-start;
    margin: 0 0 0 28px;
  }
`;

export const SearchContainer = styled.form`
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: ${({ theme: { gray } }) => gray};
  flex-grow: 1;
  padding: 0 18px;
  height: 48px;
  @media (min-width: 1024px) {
    margin: 0 18px 0 0;
    max-width: 230px;
  }
  @media (min-width: 1160px) {
    max-width: 420px;
  }
`;

export const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  padding: 6px;
  width: 100%;
  min-width: 210px;
  box-sizing: border-box;
  :placeholder {
    color: ${({ theme: { grayInfo } }) => grayInfo};
  }
  :focus {
    outline: none;
  }
`;

export const SearchButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchIcon = styled(CgSearch)`
  color: ${({ theme: { grayInfo } }) => grayInfo};
  width: 24px;
  height: 24px;
`;

export const SearchButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (min-width: 1024px) {
    margin: 0;
    flex-direction: row;
  }
`;

export const SearchTypeButton = styled.button<{ isSelected?: boolean }>`
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: ${({ isSelected, theme: { mainBlue, grayInfo } }) =>
    isSelected ? mainBlue : grayInfo};
  transition: 250ms;
  margin: 0 2px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 8px 16px;
  @media (min-width: 1024px) {
    padding: 4px;
  }
  @media (min-width: 1080px) {
    padding: 8px 16px;
  }
`;

export const LocaleSelect = styled.select`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  border: none;
  cursor: pointer;
  background-color: ${({ theme: { white } }) => white};
  :focus-visible {
    outline: none;
  }
`;

export const Option = styled.option`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  background-color: ${({ theme: { white } }) => white};
`;

export const LanguagePickWrapper = styled.div`
  padding: 10px 10px;
`;

export const NavBottom = styled.div``;

export const NavTop = styled.div``;
