import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../App';
import { markNotificationAsRead } from '../../../redux/auth/actions';
import {
  useNotifications,
  useUnreadNotificationsCounter,
} from '../../../redux/auth/selectors';
import { getDayMonthYearDateFromString } from '../../../utils/date';
import * as S from './NotificationsMenu.styles';

export interface INotification {
  isRead: boolean;
  body: string;
  date: Date;
  id: string;
}

interface INotificationsMenuProps {
  onMouseLeave: () => void;
}

export default function NotificationsMenu({
  onMouseLeave,
}: INotificationsMenuProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector(useNotifications);
  const notificationsCounter = useSelector(useUnreadNotificationsCounter);

  const handleClickNotification = (
    notificationId: string,
    submissionId: string
  ) => {
    dispatch(markNotificationAsRead(notificationId));
    history.push(`/analysis-list/${submissionId}`);
  };

  return (
    <S.Relative onMouseLeave={onMouseLeave}>
      <S.Wrapper>
        <S.HeaderWrapper>
          <S.Header>{t(`navbar.notifications`)}</S.Header>
          <S.CounterContainer>
            <S.Counter>{notificationsCounter}</S.Counter>
          </S.CounterContainer>
        </S.HeaderWrapper>
        <S.BodyWrapper>
          {notifications.map(
            ({ id, isSuccess, text, created_at, submissionId }) => (
              <S.NotificationWrapper
                key={id}
                onClick={() => handleClickNotification(id, submissionId)}
              >
                {isSuccess ? <S.CheckIcon /> : <S.XIcon />}
                <S.Body>{text}</S.Body>
                <S.Date>{getDayMonthYearDateFromString(created_at)}</S.Date>
              </S.NotificationWrapper>
            )
          )}
        </S.BodyWrapper>
      </S.Wrapper>
    </S.Relative>
  );
}
