import styled from 'styled-components';
import { TiTick } from 'react-icons/ti';
import { FaTimes } from 'react-icons/fa';
import RestoreSvg from '../../../assets/common/restore.svg';
import TrashSvg from '../../../assets/common/trash.svg';

export const RowWrapper = styled.div`
  display: flex;
  margin: 0 0 8px;
  word-break: break-word;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  color: #828282;
  font-size: 14px;
  padding: 12px 16px;
`;

export const EmailContainer = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-right: 10px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NameContainer = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-right: 10px;
`;

export const SurnameContainer = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-right: 10px;
`;

export const DateContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
`;

export const ConfirmContainer = styled.span`
  display: flex;
  flex: 0.5;
  justify-content: center;
  padding-right: 10px;
`;

export const ArchivedContainer = styled.span`
  display: flex;
  flex: 0.5;
  justify-content: center;
  padding-right: 10px;
`;

export const LocaleContainer = styled.span`
  display: flex;
  flex: 0.2;
  justify-content: center;
  padding-right: 10px;
  height: 22px;
  width: 22px;
`;

export const TrueIcon = styled(TiTick)`
  color: green;
  width: 30px;
  height: 30px;
  justify-content: center;
`;

export const FalseIcon = styled(FaTimes)`
  color: rgb(228, 5, 55);
  width: 20px;
  height: 20px;
  justify-content: center;
`;

export const TrashIcon = styled.img.attrs({ alt: 'trash', src: TrashSvg })`
  filter: saturate(0) contrast(0.08);
  width: 24px;
  height: 24px;
  transition: filter 250ms;
  :hover {
    filter: invert(11%) sepia(88%) saturate(19000%) hue-rotate(360deg)
      brightness(93%) contrast(134%);
  }
`;

export const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  min-width: 36px;
  margin: 0 0 0 auto;
  position: relative;
  flex: 0.2;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const RestoreIcon = styled.img.attrs({
  alt: 'restore',
  src: RestoreSvg,
})<{
  isChecked?: boolean;
}>`
  width: 20px;
  height: 20px;
  transition: filter 250ms;
  :hover {
    filter: invert(11%) sepia(88%) saturate(19000%) hue-rotate(198deg)
      brightness(93%) contrast(134%);
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  background-color: ${({ theme: { white } }) => white};
  color: ${({ theme: { red } }) => red};
  font-family: Poppins;
  font-size: 11px;
  line-height: 160%;
  letter-spacing: -0.5px;
  transition: 250ms;
  bottom: 20px;
  border-radius: 4px;
  width: 120px;
  z-index: 4;
  box-shadow: 0 2px 8px #41bbff;
`;

export const ArchiveTooltip = styled(Tooltip)`
  color: ${({ theme: { red } }) => red};
`;

export const UnarchiveTooltip = styled(Tooltip)`
  color: ${({ theme: { green } }) => green};
`;
