import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useSelector } from 'react-redux';
import VideoThumbnail from '../../assets/steps/cloud-upload.png';
import { useUploadPercentage } from '../../redux/submission/selectors';
import Loader from '../Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getFileSizeInMb } from '../../utils/misc';
import * as S from './Dropzone.styles';

interface DropzoneProps {
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  files: File[];
  isVerticalOrientation?: boolean;
  isDirectory?: boolean;
  showSuccessImage?: boolean;
  disabled?: boolean;
  maxFilesAmount?: number;
  onFileSizeExceeded?: () => void;
  onFilesNumberExceeded?: () => void;
  isVertical?: boolean;
  onClick?: () => void;
  isWarning?: boolean;
  acceptedFiles?: string;
}

const Dropzone = ({
  getRootProps,
  getInputProps,
  files,
  isVerticalOrientation = false,
  isDirectory = false,
  showSuccessImage = false,
  disabled = false,
  maxFilesAmount,
  onFileSizeExceeded,
  onFilesNumberExceeded,
  isVertical,
  onClick,
  isWarning,
  acceptedFiles,
}: DropzoneProps) => {
  const { t } = useTranslation();
  const isFileListSelected = files.length > 0;
  const directoryProps = isDirectory
    ? { webkitdirectory: '', directory: '' }
    : null;
  const acceptedProps = acceptedFiles ? { accept: acceptedFiles } : null;
  const DropzoneContainer = isVerticalOrientation
    ? S.DropzoneTopContentVertical
    : S.DropzoneTopContent;

  const uploadPercentage = useSelector(useUploadPercentage);

  const isUploading = uploadPercentage > 0;

  useEffect(() => {
    if (
      onFilesNumberExceeded &&
      maxFilesAmount &&
      files.length > maxFilesAmount
    ) {
      return onFilesNumberExceeded();
    }

    if (onFileSizeExceeded) {
      let totalSize = 0;
      files.map((f) => (totalSize += f.size));
      const totalSizeInMb = getFileSizeInMb(totalSize);
      if (Number(totalSizeInMb) > 100) {
        onFileSizeExceeded();
      }
    }
  }, [files, maxFilesAmount, onFileSizeExceeded, onFilesNumberExceeded]);

  return (
    <S.Wrapper {...getRootProps()} disabled={disabled} isWarning={isWarning}>
      <input
        {...directoryProps}
        {...getInputProps()}
        disabled={disabled}
        {...acceptedProps}
      />
      <S.DropzoneContent onClick={onClick}>
        {!isUploading && !isFileListSelected && (
          <DropzoneContainer>
            <S.UploadIcon src={VideoThumbnail} />
            <S.Text isVertical={isVertical}>
              {t('steps.scan.dropdownPlaceholder')}
            </S.Text>
            <S.SendFilesButton isWarning={isWarning}>
              {t('steps.scan.uploadFile')}
            </S.SendFilesButton>
          </DropzoneContainer>
        )}
        {isUploading && (
          <Loader
            percentage={uploadPercentage}
            showSuccessImage={showSuccessImage}
          />
        )}
        {!isUploading && isFileListSelected && (
          <S.DropzoneBottomContent>
            <div>
              <b>{t('steps.scan.selected')}</b>
            </div>
            {files.slice(0, 5).map(({ name }, index) => (
              <div key={index}>{name}</div>
            ))}
            {files.length > 5 && (
              <div>
                <b>{`${t('dropzone.and')} ${files.length - 5} ${t(
                  'dropzone.others'
                )}`}</b>
              </div>
            )}
          </S.DropzoneBottomContent>
        )}
      </S.DropzoneContent>
    </S.Wrapper>
  );
};

export default Dropzone;
