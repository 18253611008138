import { useSelector } from 'react-redux';
import LoginRegisterPart from '../Authentication/LoginRegisterPart';
import { useUserLoggedIn } from '../../redux/auth/selectors';
import { Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ConfirmPasswordResetForm from '../Authentication/ConfirmPasswordResetForm/ConfirmPasswordResetForm';
import MobileConfirmPasswordResetForm from '../Authentication/ConfirmPasswordResetForm/MobileConfirmPasswordResetForm';
import * as S from './ConfirmPasswordResetPage.style';

const ConfirmPasswordResetPage = () => {
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isUserLoggedIn = useSelector(useUserLoggedIn);

  if (isUserLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <S.Wrapper>
      {isTablet ? (
        <>
          <LoginRegisterPart />
          <ConfirmPasswordResetForm />
        </>
      ) : (
        <MobileConfirmPasswordResetForm />
      )}
    </S.Wrapper>
  );
};

export default ConfirmPasswordResetPage;
