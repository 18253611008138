import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmRegistration } from '../../api/common-api';
import { history } from '../../App';
import BaseLoader from '../../common/Loaders/BaseLoader';
import { useTranslation } from 'react-i18next';

const ConfirmAccountPage = () => {
  const { t } = useTranslation();
  const { token } = useParams() as { token: string };
  useEffect(() => {
    const handleConfirmRegistration = async () => {
      await toast
        .promise(confirmRegistration({ token }), {
          pending: t('errors.accountPending'),
          success: t('errors.accountSuccess'),
          error: t('errors.accountError'),
        })
        .finally(() => history.push('/'));
    };
    handleConfirmRegistration();
  }, [token, t]);

  return (
    <div>
      <BaseLoader width={40} height={40} />
      {t('errors.yourToken')} {token} {t('errors.isConfirmed')}
    </div>
  );
};

export default ConfirmAccountPage;
