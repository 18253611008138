import styled from 'styled-components';
import { AiOutlineArrowLeft } from 'react-icons/ai';

export const Wrapper = styled.section`
  max-width: 800px;
  margin: 0 auto;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-size: 15px;
  /* align-items: center; */
  line-height: 20.8px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  padding: 37px 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 43px;
  position: relative;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const NextStepButton = styled.button<{ disabled?: boolean }>`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  letter-spacing: 1px;
  justify-content: center;
  color: ${({ theme: { white } }) => white};
  background: ${({ disabled, theme: { grayFont, blue, gradientBlue } }) =>
    disabled
      ? grayFont
      : `linear-gradient(to right, ${blue}, ${gradientBlue})`};
  padding: 12.5px 90px;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  margin: 25px 0;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  max-width: 325px;
  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '' : '0px 4px 39px #41bbff')};
  }
  @media (min-width: 768px) {
    min-width: 215px;
    padding: 11.5px 0;
    margin: auto 0 auto auto;
    order: 2;
  }
`;

export const BackButton = styled.button<{ isDisabled?: boolean }>`
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  color: ${({ isDisabled, theme: { mainBlue } }) =>
    !isDisabled ? mainBlue : 'gray'};
  border: none;
  text-transform: uppercase;
  background-color: inherit;
  cursor: ${({ isDisabled }) => (!isDisabled ? 'pointer' : 'auto')};
  @media (min-width: 768px) {
    order: 1;
  }
`;

export const BackArrow = styled(AiOutlineArrowLeft)`
  color: ${({ theme: { mainBlue } }) => mainBlue}
  padding-right: 8px;
  width: 25px;
  height: 25px;
`;
export const BackArrowDisabled = styled(AiOutlineArrowLeft)`
  color: 'gray';
  padding-right: 8px;
  width: 25px;
  height: 25px;
`;

export const Name = styled.div`
  padding-bottom: 8px;
`;

export const DescriptionContainer = styled.div``;
