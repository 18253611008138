import styled from 'styled-components';
import RestoreSvg from '../../../assets/common/restore.svg';
import TrashSvg from '../../../assets/common/trash.svg';
import { BsCloudDownload } from 'react-icons/bs';

export const MobileWrapper = styled.li<{
  isSelected?: boolean;
  color: string;
  isRemoved?: boolean;
}>`
  width: 100%;
  transition: 250ms;
  background-color: ${({ isSelected, color, theme: { white } }) =>
    isSelected ? color : white};
  border: 1px solid transparent;
  ${({ isRemoved, theme: { grayFont } }) =>
    isRemoved &&
    `background-color: transparent; border: 1px solid ${grayFont}`};
  border-radius: 8px;
  margin: 0 0 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 12px 1px ${({ theme: { blue } }) => blue};
  }
`;

export const TabletWrapper = styled(MobileWrapper)`
  flex-direction: row;
  margin: 0 0 12px;
`;

export const DesktopWrapper = styled(TabletWrapper)`
  align-items: center;
  margin: 0 0 8px;
  word-break: break-all;
`;

export const TabletBody = styled.div`
  flex-grow: 1;
`;

export const Border = styled.div<{ color: string; isRemoved?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 20px;
  width: 4px;
  transition: 250ms;
  background-color: ${({ isRemoved, color }) =>
    isRemoved ? 'transparent' : color};
`;

export const HeaderRow = styled.button`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: fit-content;
  align-items: center;
`;

export const TabletDataRow = styled(HeaderRow)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 12px;
  border-bottom: 1px solid ${({ theme: { grayBorder } }) => grayBorder};
`;

export const Counter = styled.p<{ isSelected?: boolean }>`
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  line-height: 24px;
  margin: 0 12px 0 0;
  flex-basis: 24px;
  color: ${({ isSelected, theme: { grayInfo, mainBlue } }) =>
    isSelected ? mainBlue : grayInfo};
`;

export const DesktopCounter = styled(Counter)`
  flex: 0.5;
`;

export const Header = styled.p`
  margin: 0;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  white-space: nowrap;
  text-align: left;
  flex: 4;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  @media (min-width: 1024px) {
    flex: 6;
  }
`;

export const DesktopHeader = styled.button`
  border: none;
  margin: 0;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  flex: 4;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const DataRow = styled.div`
  margin: 12px 0 0;
  display: flex;
`;

export const DataLabel = styled.label`
  color: ${({ theme: { grayFont } }) => grayFont};
  font-family: Poppins;
  font-size: 13px;
  line-height: 180%;
  letter-spacing: -0.5px;
  flex-basis: 100px;
  margin: 0 16px 0 0;
  @media (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const DataValue = styled.div<{ isSelected?: boolean }>`
  margin: 0;
  color: ${({ isSelected, theme: { grayInfo, mainBlue } }) =>
    isSelected ? mainBlue : grayInfo};
  transition: color 250ms;
  font-family: Poppins;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: -0.5px;
  flex: 4;
  text-align: left;
  @media (min-width: 1024px) {
    font-size: 15px;
    flex: 3;
  }
`;

export const TabletQuantityValue = styled.div<{ isSelected?: boolean }>`
  margin: 0;
  color: ${({ isSelected, theme: { grayInfo, mainBlue } }) =>
    isSelected ? mainBlue : grayInfo};
  transition: color 250ms;
  font-family: Poppins;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: -0.5px;
  flex: 1;
  text-align: left;
  @media (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const TabletDateValue = styled(DataValue)`
  flex: 3;
  text-align: left;
`;

export const DesktopDataValue = styled(DataValue)`
  flex: 6;
  font-size: 14px;
`;

export const DesktopQuantityValue = styled(DesktopDataValue)`
  flex: 2;
`;

export const DesktopLabelContainer = styled.div`
  flex: 6;
`;

export const DesktopButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 8;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 100%;
  margin: 12px 0 0;
`;

export const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  min-width: 36px;
  margin: 0 0 0 auto;
  position: relative;
`;

export const Tooltip = styled.div`
  position: absolute;
  background-color: ${({ theme: { white } }) => white};
  color: ${({ theme: { red } }) => red};
  font-family: Poppins;
  font-size: 11px;
  line-height: 160%;
  letter-spacing: -0.5px;
  transition: 250ms;
  bottom: 20px;
  border-radius: 4px;
  width: 120px;
  z-index: 4;
  box-shadow: 0 2px 8px #41bbff;
`;

export const ArchiveTooltip = styled(Tooltip)`
  color: ${({ theme: { red } }) => red};
`;

export const RestoreTooltip = styled(Tooltip)`
  color: ${({ theme: { green } }) => green};
`;

export const TrashIcon = styled.img.attrs({ alt: 'trash', src: TrashSvg })`
  filter: saturate(0) contrast(0.08);
  width: 24px;
  height: 24px;
  transition: filter 250ms;
  :hover {
    filter: invert(11%) sepia(88%) saturate(19000%) hue-rotate(360deg)
      brightness(93%) contrast(134%);
  }
`;

export const RestoreIcon = styled.img.attrs({
  alt: 'restore',
  src: RestoreSvg,
})<{
  isChecked?: boolean;
}>`
  width: 20px;
  height: 20px;
  transition: filter 250ms;
  :hover {
    filter: invert(11%) sepia(88%) saturate(19000%) hue-rotate(198deg)
      brightness(93%) contrast(134%);
  }
`;

export const LabelWrapper = styled.div<{
  isGrayedOut: boolean;
  backgroundColor: string;
}>`
  border-radius: 8px;
  height: 26px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: 250ms;
  filter: ${({ isGrayedOut }) => `saturate(${isGrayedOut ? 0 : 1})`};
`;

export const TabletLabelWrapper = styled(LabelWrapper)`
  margin: 0 auto 0 0;
`;

export const TabletSpan = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 250px;
`;

export const LabelText = styled.p<{ color: string }>`
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  line-height: 160%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ color }) => color};
`;

export const LabelIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
`;

export const AddAnalysisButton = styled.button<{
  isRemoved?: boolean;
  isReady?: boolean;
}>`
  height: 32px;
  width: fit-content;
  border-radius: 4px;
  white-space: nowrap;
  background: linear-gradient(to right, #41bbff, #086ee7);
  color: ${({ theme: { white } }) => white};
  cursor: ${({ disabled }) => (disabled ? 'default' : `pointer`)};
  filter: ${({ disabled }) => (disabled ? 'saturate(.4)' : `saturate(1)`)};
  &:hover {
    ${({ disabled }) => (disabled ? '' : `box-shadow: 0 0 8px #41bbff;`)}
  }
  font-family: Poppins;
  font-weight: 500;
  font-size: 13px;
  @media (min-width: 1024px) {
    font-size: 15px;
  }
  line-height: 19px;
  text-align: center;
  padding: 0 16px;
  border: none;
  transition: 250ms;
  ${({ isRemoved, theme: { red, white } }) =>
    isRemoved &&
    `
  border: 2px solid ${red};
  color: ${red};
  background: transparent;
  `}
  ${({ isReady, theme: { grayInfo } }) =>
    isReady &&
    `
  background: ${grayInfo}};
  `}
    padding: 0 16px;
  @media (min-width: 1024px) {
    padding: 0 6px;
  }
  @media (min-width: 1162px) {
    padding: 0 16px;
  }
`;

export const DownloadIcon = styled(BsCloudDownload)`
  color: #41bbff;
  height: 24px;
  padding-right: 2px;
`;
