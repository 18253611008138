import i18n from '../../i18n';
import { Redirect, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const LanguageSwitchPage = ({ language }: { language: string }) => {
  const { pathname } = useLocation();
  const newLocation = pathname.substring(3);
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const init = async () => {
      await i18n.changeLanguage(language);
      setRedirect(true);
    };
    init();
  }, []);
  return redirect ? <Redirect to={newLocation} /> : null;
};

export default LanguageSwitchPage;
