import { Button } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateOgImage } from '../../../../redux/admin/actions';
import * as S from './SEOTableTagsRow.styles';

export interface ISEOTags {
  title: string;
  image: string;
  url: string;
  locale: string;
  id: number;
}

const SEOTableTagsRow = ({ title, image, url, locale, id }: ISEOTags) => {
  useEffect(() => {
    setCurrentTitle(title);
    setCurrentImage(image);
    setPicture(null);
    setIsEdit(false);
  }, [title, image, url, locale, id]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentImage, setCurrentImage] = useState(image);
  const [picture, setPicture] = useState<any>(null);
  const dispatch = useDispatch();

  const handleSave = () => {
    setIsEdit(false);
    const data = {
      picture,
      title: currentTitle,
      description: '',
    };
    dispatch(updateOgImage(id, data));
  };

  const handleAction = () => {
    if (!isEdit) {
      setIsEdit(true);
    } else {
      handleSave();
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setCurrentTitle(title);
    setCurrentImage(image);
    setPicture(null);
  };

  const inputFile = useRef<HTMLInputElement | null>(null);

  const handleUploadImage = () => {
    if (!inputFile || !inputFile.current) {
      return;
    }
    inputFile.current.click();
  };

  const handleImageChange = (e: any) => setPicture(e.target.files[0]);

  const actionText = isEdit ? t('seo.action.save') : t('seo.action.edit');

  const ImageColumn = () => {
    if (!isEdit) {
      return <S.ImageContainer href={image}>{image}</S.ImageContainer>;
    }

    if (picture) {
      return (
        <S.EditImageContainer>
          <S.UploadedImage src={URL.createObjectURL(picture)} />
          <S.UploadIcon onClick={handleUploadImage} />
        </S.EditImageContainer>
      );
    }

    return (
      <S.EditImageContainer>
        <S.ImageEditTextArea
          value={currentImage}
          onChange={(e) => setCurrentImage(e.target.value)}
        />
        <S.UploadIcon onClick={handleUploadImage} />
      </S.EditImageContainer>
    );
  };

  return (
    <S.RowWrapper>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
      {isEdit ? (
        <S.TitleContainer
          value={currentTitle}
          onChange={(e) => setCurrentTitle(e.target.value)}
        />
      ) : (
        <S.ValueContainer>{title}</S.ValueContainer>
      )}
      <S.ColumnDivider />
      {ImageColumn()}
      <S.ColumnDivider />
      <S.UrlContainer>{url}</S.UrlContainer>
      <S.ColumnDivider />
      <S.LocaleContainer>{locale}</S.LocaleContainer>
      <S.ColumnDivider />
      <S.ActionContainer>
        <Button onClick={handleAction}>{actionText}</Button>
        {isEdit && (
          <Button onClick={handleCancel}>{t('seo.action.cancel')}</Button>
        )}
      </S.ActionContainer>
    </S.RowWrapper>
  );
};

export default SEOTableTagsRow;
