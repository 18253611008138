export enum ANALYSIS_STATUS {
  NEW_ANALYSIS = 'NEW_ANALYSIS',
  ANONYMIZATION = 'ANONYMIZATION',
  SENT = 'SENT',
  NOT_PAID = 'NOT_PAID',
  NO_FILES = 'NO_FILES',
  READY = 'READY',
  ANY = 'ANY',
}

export interface IMenuItem {
  label: string;
  counter: number;
  isSelected: boolean;
  action?: () => void;
}

export interface IMenuItemExtended extends IMenuItem {
  status: ANALYSIS_STATUS;
}

export interface IMenuWithHeader {
  menuItems: IMenuItemExtended[];
  header: string;
  selectedStatus: ANALYSIS_STATUS;
  handleSelect: (status: ANALYSIS_STATUS) => void;
  counters: { [key: string]: number };
}
