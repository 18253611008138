import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SuccessTada from '../../assets/steps/success-tada.svg';
import { UPLOAD_TYPE } from '../../redux/submission/actions';
import { useUploadType } from '../../redux/submission/selectors';
import * as S from './Loader.styles';

const getRadiusByPercentage = (percentage: number) => {
  const percentageToRadius = (360 * percentage) / 100;
  const finalPercentage = percentageToRadius + 45;
  return `${finalPercentage}deg`;
};

interface ILoader {
  percentage: number;
  showSuccessImage?: boolean;
}

const Loader = ({ percentage, showSuccessImage }: ILoader) => {
  const { t } = useTranslation();
  const movingCircleRadius = getRadiusByPercentage(percentage);

  const uploadType = useSelector(useUploadType);

  const loaderMessage =
    uploadType === UPLOAD_TYPE.UPLOAD
      ? t('loader.filesTransfer')
      : t('loader.filesUpload');

  if (showSuccessImage && percentage === 100) {
    return (
      <S.SuccessWrapper>
        <S.SuccessImage src={SuccessTada} />
        <div>{t('loader.filesUpload')}</div>
      </S.SuccessWrapper>
    );
  }

  const visiblePercentage = percentage === 100 ? 99 : percentage;

  return (
    <>
      <S.Wrapper>
        <CircularProgressbarWithChildren
          value={visiblePercentage}
          styles={buildStyles({
            pathColor: '#41BBFF',
            trailColor: '#eee',
            strokeLinecap: 'butt',
          })}
        >
          <S.MovingCircleContainer rotateDeg={movingCircleRadius}>
            <S.MovingCircle />
          </S.MovingCircleContainer>
          <S.TextContainer>{visiblePercentage}%</S.TextContainer>
        </CircularProgressbarWithChildren>
      </S.Wrapper>

      <S.PleaseWaitText>{t(loaderMessage)}</S.PleaseWaitText>
    </>
  );
};

export default Loader;
