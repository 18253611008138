import styled from 'styled-components';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 630px) {
    flex-direction: row;
  }
`;

export const PerPageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  display: block;
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  margin: 0 0.5rem 0 0;
  color: ${({ theme: { black } }) => black};
`;

export const PageSelectorContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 32px;
  @media (min-width: 630px) {
    margin: 0;
    justify-content: flex-start;
  }
  @media (min-width: 1024px) {
    justify-content: center;
  }
`;

const commonArrow = `
  height: 1.5rem;
  width: 1.5rem;
  transition: 250ms;
`;

export const ArrowLeft = styled(BiChevronLeft)<{
  props: { isHover?: boolean; isDisabled: boolean };
}>`
  ${commonArrow}
  color: ${({
    props: { isHover, isDisabled },
    theme: { mainBlue, grayFont },
  }) => (!isDisabled && isHover ? mainBlue : grayFont)};
`;

export const ArrowRight = styled(BiChevronRight)<{
  props: { isHover?: boolean; isDisabled: boolean };
}>`
  ${commonArrow}
  color: ${({
    props: { isHover, isDisabled },
    theme: { mainBlue, grayFont },
  }) => (!isDisabled && isHover ? mainBlue : grayFont)};
`;

const commonPeripheralButton = `
  border: none;
  height: 38px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const LeftButton = styled.button`
  ${commonPeripheralButton}
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ theme: { white } }) => white};
`;

export const RightButton = styled.button`
  ${commonPeripheralButton}
  border-radius: 0 4px 4px 0;
  margin-left: 1px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ theme: { white } }) => white};
`;

export const PagesContainer = styled.div`
  display: flex;
  height: 38px;
  padding: 2px;
  box-sizing: border-box;
  background-color: ${({ theme: { white } }) => white};
`;

export const PageButton = styled.button`
  ${commonPeripheralButton}
  border-radius: 4px;
  margin: 0 2px;
  min-width: 34px;
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(to right, #41bbff, #086ee7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const DotsLabel = styled.label`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 15px;
  line-height: 230%;
  text-align: center;
  width: 34px;
  min-width: 34px;
  height: 100%;
  transition: 250ms;
  display: block;
  margin: 0 2px;
`;

export const PageLabel = styled.label<{
  isSelected?: boolean;
  disabled?: boolean;
}>`
  background-color: ${({ isSelected, theme: { white } }) =>
    isSelected ? 'transparent' : white};
  color: ${({ isSelected, theme: { mainBlue, white } }) =>
    isSelected ? white : mainBlue};
  ${({ disabled, theme: { gray, grayInfo } }) =>
    disabled &&
    `
  background-color: ${gray};
  color: ${grayInfo};
  `}
  font-family: Poppins;
  font-size: 15px;
  line-height: 230%;
  text-align: center;
  width: 100%;
  height: 100%;
  transition: 250ms;
  display: block;
  ${({ disabled, theme: { white } }) =>
    !disabled &&
    `
    cursor: pointer;
    :hover {
      background-color: transparent;
      color: ${white};
    }
  `}
`;
