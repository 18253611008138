import Logo from '../../assets/navbar/t3d-company-logo.svg';
import NotificationLogo from '../../assets/navbar/notificaton_icon.svg';
import { FormEvent, useState, KeyboardEvent, useEffect } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import MenuIcon from './MenuIcon';
import NavbarLink, { Links } from '../Navbar/NavbarLink';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/auth/actions';
import { setSearchParams as setSearchParamsSubs } from '../../redux/submission/actions';
import {
  setSearchUsersParams,
  setSearchSeoParams,
  resetSearchParams,
} from '../../redux/admin/actions';
import {
  useUnreadNotificationsCounter,
  userData,
} from '../../redux/auth/selectors';
import { useTranslation } from 'react-i18next';
// import i18n from '../../i18n';
import CountdownBar from '../CountdownBar/CountdownBar';
import {
  isSearcheableRoute,
  isSeoRouteActive,
  isAdminRouteActive,
} from '../../utils/routing';
import LanguageModal from '../../common/LanguageModal/LanguageModal';
import AvatarCircle from './AvatarCircle/AvatarCircle';
import i18n from '../../i18n';
import * as S from './Navbar.styles';
import { isModalOpen } from '../../redux/utils/selectors';
import { MODAL_NAME } from '../../redux/utils/types';
import { toggleModal } from '../../redux/utils/actions';

export default function Navbar() {
  const { t } = useTranslation();
  const isOpen = useSelector(isModalOpen(MODAL_NAME.NOTIFICATION_BELL));
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isSelectedAnalysisTab = pathname.includes('analysis-list');
  const isSelectedUsersTab = pathname.includes('users');
  const isSelectedSEOTagsTab = pathname.includes('seo-tags');
  const isSelectedSEOImagesTab = pathname.includes('seo-images');
  const handleLogout = () => dispatch(logout());
  const notificationsCounter = useSelector(useUnreadNotificationsCounter);
  const { name, surname, role } = useSelector(userData);

  useEffect(() => {
    dispatch(resetSearchParams({ query: '' }));
    setLocalQuery('');
  }, [pathname, dispatch]);

  const handleSearchQueryChange = () => {
    let setSearchParams = undefined;
    if (pathname.includes('seo')) {
      setSearchParams = setSearchSeoParams;
    } else if (pathname.includes('users')) {
      setSearchParams = setSearchUsersParams;
    } else {
      setSearchParams = setSearchParamsSubs;
    }
    dispatch(setSearchParams({ query: localQuery }));
  };

  const isSearchField = isSearcheableRoute(pathname);
  const isSeoTab = isSeoRouteActive(pathname);
  const isUserTab = isAdminRouteActive(pathname);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(min-width: 768px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [localQuery, setLocalQuery] = useState('');

  const getLinks = () =>
    Links(role).map((row: any, index: number) => (
      <NavbarLink key={`row_${index}`} {...row} />
    ));
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSearchQueryChange();
  };

  const submitOnEnter = ({ key }: KeyboardEvent) =>
    key === 'Enter' && handleSearchQueryChange();
  const handleGoToAnalysis = () => history.push('/analysis-list');
  const handleGoToUsers = () => history.push('/users');
  const handleGoToSEOTags = () => history.push('/seo-tags');
  const handleGoToSEOImages = () => history.push('/seo-images');
  const usersSearchButtons = () => {
    const isAdmin = role === 'admin';
    const isOperator = role === 'operator';
    const isPriviliged = isAdmin || isOperator;
    if (!isSearchField) {
      return null;
    }
    return (
      <S.SearchButtonsContainer>
        {isPriviliged && (
          <S.SearchTypeButton
            isSelected={isSelectedAnalysisTab}
            onClick={handleGoToAnalysis}
          >
            {t(`navbar.adminAnalysis`)}
          </S.SearchTypeButton>
        )}
        {isPriviliged && (
          <S.SearchTypeButton
            isSelected={isSelectedUsersTab}
            onClick={handleGoToUsers}
          >
            {t(`navbar.users`)}
          </S.SearchTypeButton>
        )}
      </S.SearchButtonsContainer>
    );
  };
  const seoSearchButtons = () => {
    const isAdmin = role === 'admin';
    const isOperator = role === 'operator';
    const isPriviliged = isAdmin || isOperator;
    return (
      <S.SearchButtonsContainer>
        {isPriviliged && (
          <S.SearchTypeButton
            isSelected={isSelectedSEOTagsTab}
            onClick={handleGoToSEOTags}
          >
            {t(`navbar.seo-tags`)}
          </S.SearchTypeButton>
        )}
        {isPriviliged && (
          <S.SearchTypeButton
            isSelected={isSelectedSEOImagesTab}
            onClick={handleGoToSEOImages}
          >
            {t(`navbar.seo-images`)}
          </S.SearchTypeButton>
        )}
      </S.SearchButtonsContainer>
    );
  };
  const renderSearchButtons = () => {
    if (!isSearchField) {
      return null;
    }
    if (isUserTab) {
      return usersSearchButtons();
    }
    if (isSeoTab) {
      return seoSearchButtons();
    }
  };

  const renderSearch = () => {
    if (!isSearchField) {
      return null;
    }
    return (
      <S.SearchWrapper>
        <S.SearchContainer onSubmit={handleSubmit}>
          <S.SearchInput
            onKeyPress={submitOnEnter}
            value={localQuery}
            onChange={(e) => setLocalQuery(e.target.value)}
            placeholder={t('navbar.whatAreYouLookingFor')}
            type={'text'}
          />
          <S.SearchButton type={'submit'}>
            <S.SearchIcon />
          </S.SearchButton>
        </S.SearchContainer>
        {isDesktop && renderSearchButtons()}
      </S.SearchWrapper>
    );
  };

  const handleClickOnNotificationBell = (e: any) => {
    if (notificationsCounter <= 0) {
      return;
    }
    e.stopPropagation();
    dispatch(toggleModal(MODAL_NAME.NOTIFICATION_BELL));
    // setIsNotificationsMenuOpen(!isNotificationsMenuOpen);
  };

  const handleNotificationsMenuOnMouseLeave = () => {
    dispatch(toggleModal(MODAL_NAME.NOTIFICATION_BELL));
  };

  const logoRedirectUrl =
    language === 'pl'
      ? process.env.REACT_APP_LANDING_PAGE
      : `${process.env.REACT_APP_LANDING_PAGE}/${selectedLanguage}`;

  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const RedirectWrapper = ({ children }: any) =>
    pathname === '/' ? (
      <a href={logoRedirectUrl}>{children}</a>
    ) : (
      <NavLink to="/">{children}</NavLink>
    );

  return (
    <S.SectionWrapper hasSearch={isSearchField}>
      <S.ExternalWrapper>
        <S.Wrapper>
          <S.NavBody>
            <RedirectWrapper>
              <S.LogoContainer>
                <S.Logo src={Logo} />
              </S.LogoContainer>
            </RedirectWrapper>
            {isDesktop && renderSearch()}
            {!isDesktop && isTablet && renderSearchButtons()}
            <S.NavigationWrapper>
              {notificationsCounter > 0 && (
                <S.NotificationSpan>
                  <S.NotificationWrapper
                    onClick={(e) => {
                      handleClickOnNotificationBell(e);
                    }}
                  >
                    <S.CounterContainer>
                      <S.Counter>{notificationsCounter}</S.Counter>
                    </S.CounterContainer>
                    <S.NotificationLogo src={NotificationLogo} />
                  </S.NotificationWrapper>
                  {isOpen && (
                    <NotificationsMenu
                      onMouseLeave={handleNotificationsMenuOnMouseLeave}
                    />
                  )}
                </S.NotificationSpan>
              )}
              {isDesktop && <AvatarCircle />}
              <LanguageModal />
              {/* <S.LanguagePickWrapper>
                <S.LocaleSelect
                  value={selectedLanguage}
                  onChange={selectChange}
                >
                  <S.Option value="pl">PL</S.Option>
                  <S.Option value="en">EN</S.Option>
                </S.LocaleSelect>
              </S.LanguagePickWrapper> */}
              {isTablet && (
                <S.UserDetailsContainer
                  onClick={() => history.push('/settings')}
                >
                  <S.UserDetails>
                    <div>{name}</div>
                    <div>{surname}</div>
                    <S.RoleName>{t(`userRoles.${role}`)}</S.RoleName>
                  </S.UserDetails>
                </S.UserDetailsContainer>
              )}
              {/* {isTablet && <S.Vector src={Vector} />} */}
              {!isDesktop && (
                <S.MenuWrapper>
                  <S.PlainButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <MenuIcon isOpen={isMenuOpen} />
                    <S.Label>Menu</S.Label>
                  </S.PlainButton>
                </S.MenuWrapper>
              )}
              {!isDesktop && (
                <S.MobileMenuWrapper isVisible={isMenuOpen}>
                  <S.LinksWrapper>{getLinks()}</S.LinksWrapper>
                  <S.LogoutButton onClick={handleLogout}>
                    {t('navbar.logout')}
                  </S.LogoutButton>
                </S.MobileMenuWrapper>
              )}
            </S.NavigationWrapper>
          </S.NavBody>
          <CountdownBar />
        </S.Wrapper>
        {!isDesktop && renderSearch()}
      </S.ExternalWrapper>
    </S.SectionWrapper>
  );
}
