import { useCallback, useEffect, useState } from 'react';

function getTimeFromSeconds(secs: number) {
  const totalSeconds = Math.ceil(secs);
  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
    seconds,
    minutes,
    hours,
    days,
  };
}

function getSecondsFromExpiry(expiryTimestamp: any) {
  const now = new Date().getTime();
  const milliSecondsDistance = expiryTimestamp - now;
  if (milliSecondsDistance > 0) {
    return milliSecondsDistance / 1000;
  }
  return 0;
}

const DEFAULT_DELAY = 1000;
function getDelayFromExpiryTimestamp(expiryTimestamp: Date) {
  const isValid = () => new Date(expiryTimestamp).getTime() > 0;
  if (!isValid) {
    return null;
  }

  const seconds = getSecondsFromExpiry(expiryTimestamp);
  const extraMilliSeconds = Math.floor((seconds - Math.floor(seconds)) * 1000);
  return extraMilliSeconds > 0 ? extraMilliSeconds : DEFAULT_DELAY;
}

export default function useTimer({
  expiryTimestamp,
  onExpire,
}: {
  expiryTimestamp: Date;
  onExpire: () => void;
}) {
  const [seconds, setSeconds] = useState(getSecondsFromExpiry(expiryTimestamp));
  const [isRunning, setIsRunning] = useState(true);
  const [delay, setDelay] = useState(
    getDelayFromExpiryTimestamp(expiryTimestamp)
  );

  const handleExpire = useCallback(() => {
    onExpire && onExpire();
    setIsRunning(false);
    setDelay(null);
  }, [onExpire]);

  useEffect(() => {
    if (!delay) {
      return () => {};
    }

    const interval = setInterval(() => {
      if (isRunning) {
        const secondsValue = getSecondsFromExpiry(expiryTimestamp);
        setSeconds(secondsValue);
        if (secondsValue <= 0) {
          handleExpire();
        }
      }
    }, delay);
    return () => clearInterval(interval);
    // @ts-ignore
  }, [delay, expiryTimestamp, isRunning, handleExpire]);

  return getTimeFromSeconds(seconds);
}
