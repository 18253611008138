import { MOCK_MENU_ITEMS } from '../../utils/mocks';
import MenuWithHeader from './MenuWithHeader/MenuWithHeader';
import AnalysisFolder from './AnalysisItem/AnalysisFolderLink';
import AnalysisItem from './AnalysisItem/AnalysisItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchParams,
  useAllSubmissionCounters,
  useAllSubmissions,
  useIsLoadingSubmissionsList,
} from '../../redux/submission/selectors';
import { ANALYSIS_STATUS } from '../../utils/types';
import { useEffect, useState } from 'react';
import { fetchSubmissions } from '../../redux/submission/actions';
import BaseLoader from '../../common/Loaders/BaseLoader';
import { useTranslation } from 'react-i18next';
import * as S from './MyAnalysis.styles';

interface MyAnalysisProps {
  isInternal?: boolean
}

const MyAnalysis = ({ isInternal = false }: MyAnalysisProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubmissions());
  }, [dispatch]);

  const allSubmissions = useSelector(useAllSubmissions);
  const submissionCounters = useSelector(useAllSubmissionCounters);
  const isLoadingSubmissionsList = useSelector(useIsLoadingSubmissionsList);
  const { status, dateMax, dateMin } = useSelector(searchParams);

  const [selectedStatus, setSelectedStatus] = useState<ANALYSIS_STATUS>(
    ANALYSIS_STATUS.ANY
  );
  const handleSelect = (status: ANALYSIS_STATUS) => setSelectedStatus(status);

  let visibleSubmissions =
    selectedStatus === ANALYSIS_STATUS.ANY
      ? allSubmissions
      : allSubmissions.filter(({ status }) => status === selectedStatus);

  if (isInternal) {
    if (status !== ANALYSIS_STATUS.ANY) {
      visibleSubmissions = visibleSubmissions.filter(s => s.status === status)
    }
    if (dateMin && dateMax) {
      const dateFrom = new Date(dateMin)
      const dateTo = new Date(dateMax)
      console.log({ dateFrom, dateTo })
      visibleSubmissions = visibleSubmissions.filter(s => {
        const subDate = new Date(s.created_at);
        return dateFrom <= subDate && subDate <= dateTo
      })
    } else if (dateMin) {
      const dateFrom = new Date(dateMin)
      visibleSubmissions = visibleSubmissions.filter(s => new Date(s.created_at) >= dateFrom)
    } else if (dateMax) {
      const dateTo = new Date(dateMax)
      visibleSubmissions = visibleSubmissions.filter(s => new Date(s.created_at) <= dateTo)
    }
  }

  const ResultsSection = () => {
    if (isLoadingSubmissionsList) {
      return (
        <S.LoadingWrapper>
          <BaseLoader height={20} width={20} />
        </S.LoadingWrapper>
      );
    }
    return (
      <S.ResultsWrapper isInternal={isInternal}>
        {visibleSubmissions
          .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
          .map((analysis) => (
            <AnalysisItem key={analysis.id} {...analysis} />
          ))}
        {!isInternal && <AnalysisFolder />}
      </S.ResultsWrapper>
    );
  };

  if (isInternal) {
    return <ResultsSection />
  }

  return (
    <S.Wrapper>
      <MenuWithHeader
        header={t('analysis.myAnalysis')}
        menuItems={MOCK_MENU_ITEMS}
        selectedStatus={selectedStatus}
        handleSelect={handleSelect}
        counters={submissionCounters}
      />
      <ResultsSection />
    </S.Wrapper>
  );
};

export default MyAnalysis;
