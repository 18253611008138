import ReactPlayer from 'react-player/youtube';
import * as S from './ImageModal.styles';

interface ImageModalProps {
  src: string;
  onClose: () => void;
  title: string;
  isOpen: boolean;
  onClick: () => void;
  type?: 'next' | 'img' | 'movie';
}

const ImageModal = ({
  src,
  onClose,
  title,
  isOpen,
  onClick,
  type = 'img',
}: ImageModalProps) => {
  if (!src || !isOpen) {
    return null;
  }

  const playIcon = () => (
    <S.PlayWrapper>
      <S.PlayIcon />
    </S.PlayWrapper>
  );

  const renderBody = () => {
    if (type === 'img') {
      return <S.Image src={src} alt={`${title} photo`} onClick={onClick} />;
    }
    if (type === 'movie') {
      return (
        <ReactPlayer
          url={src}
          playIcon={playIcon()}
          width={'calc(100vh - 2rem)'}
          height={'calc((100vh - 2rem) * 0.50)'}
        />
      );
    }
    return null;
  };
  return (
    <S.Wrapper onClick={onClose}>
      <S.BodyWrapper>
        <S.CloseIcon onClick={onClose} />
        {renderBody()}
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default ImageModal;
