import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import colorTheme from '../../../colorTheme';
import { useTranslation } from 'react-i18next';
import * as S from './MenuWithHeader.styles';
interface IMenuOption {
  label: string;
  value: any;
}

interface IMenuSelect {
  options: IMenuOption[];
  selected: any;
  action: (id: any) => void;
}

export default function MenuSelect({ options, selected, action }: IMenuSelect) {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    action(value);
  };
  const selectedOption = options.find(({ value }) => value === selected);
  return (
    <Select
      value={selectedOption ? t(selectedOption.label) : undefined}
      onChange={handleChange}
      renderValue={(label) => <S.SelectLabel>{label}</S.SelectLabel>}
      MenuProps={{
        disableScrollLock: true,
      }}
      sx={{
        width: '100%',
        outline: `1px solid ${colorTheme.blue}`,
        borderRadius: '2px',
      }}
    >
      {options.map(({ label, value }: IMenuOption, index: number) => (
        <MenuItem
          key={`item_${index}`}
          value={value}
          sx={{
            background: 'transparent',
            fontSize: 15,
            fontFamily: 'Poppins',
            color: colorTheme.mainBlue,
            fontWeight: value === selected ? 600 : 500,
          }}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}
