import Loader from 'react-loader-spinner';

export interface IBaseLoader {
  height: number;
  width: number;
  color?: string;
}

const BaseLoader = ({ height, width, color = '#086ee7' }: IBaseLoader) => (
  <Loader type="TailSpin" color={color} height={height} width={width} />
);

export default BaseLoader;
