import styled from 'styled-components';
import { MdOutlineClose } from 'react-icons/md';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  top: 50px;
  background-color: #f4f5f9;
  padding: 50px 50px 30px 50px;
  border-radius: 8px;
  position: relative;
  cursor: auto;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
  z-index: 100;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const CloseContainer = styled.button`
  background-color: #f4f5f9;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 48px;
  height: 48px;
  border: none;
  position: absolute;
  top: 3px;
  right: 6px;
  cursor: pointer;
`;

export const CloseIcon = styled(MdOutlineClose)`
  width: 26px;
  height: 26px;
  color: gray;
  &:hover {
    color: #07005c;
  }
`;

export const Title = styled.span`
  font-size: 22px;
  font-family: 'Poppins';
  font-weight: 600;
  padding-bottom: 20px;
`;

export const Body = styled.span`
  font-family: 'Poppins';
`;

export const ActionButton = styled.button`
  height: 42px;
  width: fit-content;
  border-radius: 4px;
  white-space: nowrap;
  background: linear-gradient(to right, #41bbff, #086ee7);
  color: ${({ theme: { white } }) => white};
  cursor: ${({ disabled }) => (disabled ? 'default' : `pointer`)};
  filter: ${({ disabled }) => (disabled ? 'saturate(.4)' : `saturate(1)`)};
  &:hover {
    ${({ disabled }) => (disabled ? '' : `box-shadow: 0 0 8px #41bbff;`)}
  }
  font-family: Poppins;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  padding: 0 16px;
  border: none;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 18px;
`;
