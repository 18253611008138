import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, resetSignInError } from '../../../redux/auth/actions';
import { useSignInErrorMessage } from '../../../redux/auth/selectors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LanguageModal from '../../../common/LanguageModal/LanguageModal';
import i18n from '../../../i18n';
import * as S from './LoginForm.styles';

const LoginForm = () => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const dispatch = useDispatch();
  const signInErrorMessage = useSelector(useSignInErrorMessage);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = () =>
    dispatch(login({ email, password, language: selectedLanguage }));
  const handleKeyPress = (e: any) => {
    if (e.code === 'Enter') {
      handleSubmit();
    }
  };

  const isFormValid = email && password;

  useEffect(() => {
    if (signInErrorMessage) {
      toast.warn(t(signInErrorMessage));
      dispatch(resetSignInError());
    }
  }, [signInErrorMessage, dispatch, t]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.LanguagePickWrapper>
          <LanguageModal />
        </S.LanguagePickWrapper>
        <S.FormWrapper>
          <S.Title>{t('loginForm.login')}</S.Title>
          <TextField
            label={t('loginForm.mailPlaceholder')}
            variant="standard"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{
              paddingBottom: '6px',
            }}
            inputProps={{
              autoComplete: 'off',
            }}
          />
          <TextField
            type="password"
            label={t('loginForm.passwordPlaceholder')}
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            autoComplete="new-password"
          />
          <S.ButtonContainer>
            <S.ConfirmButton disabled={!isFormValid} onClick={handleSubmit}>
              {t('loginForm.loginButton')}
            </S.ConfirmButton>
          </S.ButtonContainer>
          <S.QuestionContainer>
            <S.Question>
              {t('loginForm.noAccountYet')}
              <S.ActionContainer to="/register">
                {t('loginForm.register')}
              </S.ActionContainer>
            </S.Question>
          </S.QuestionContainer>
          <S.QuestionContainer>
            <S.Question>
              {t('loginForm.forgottenPassword')}
              <S.ActionContainer to="/remind-password">
                {t('loginForm.remindPassword')}
              </S.ActionContainer>
            </S.Question>
          </S.QuestionContainer>
        </S.FormWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default LoginForm;
