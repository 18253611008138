import styled from 'styled-components';

export const Wrapper = styled.section`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;
export const CardContainer = styled.div`
  display: flex;
  padding-top: 20px;
  margin-left: 24px;
`;

export const Card = styled.img``;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-size: 15px;
  line-height: 20.8px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  padding: 37px 32px;
  text-align: center;
  max-width: 492px;
`;

export const Title = styled.div`
  font-family: Poppins;
  font-size: 26px;
  line-height: 31.2px;
  padding-bottom: 8px;
  font-weight: 650;
`;

export const Body = styled.div`
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme: { grayInfo } }) => grayInfo};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const NextStepButton = styled.button<{ disabled?: boolean }>`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  letter-spacing: 1px;
  justify-content: center;
  color: ${({ theme: { white } }) => white};
  background: ${({ disabled, theme: { grayFont, blue, gradientBlue } }) =>
    disabled
      ? grayFont
      : `linear-gradient(to right, ${blue}, ${gradientBlue})`};
  padding: 12.5px 0;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  width: 325px;
  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '' : '0px 4px 39px #41bbff')};
  }
  @media (min-width: 768px) {
    width: 333px;
    /* padding: 11.5px 40px; */
    margin: auto 0 auto auto;
  }
`;

export const NavigateToPanelCTA = styled.div`
  color: #07005c;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 14px;
  padding-top: 16px;
  cursor: pointer;
`;
