import styled from 'styled-components';
import { MdArrowDropDown } from 'react-icons/md';

export const TableHeaderWrapper = styled.div`
  display: flex;
  font-size: 15px;
  font-family: Poppins;
  color: #828282;
  margin: 0 0 8px;
  padding: 12px 16px;
  word-break: break-word;
`;

export const ColumnDivider = styled.div`
  flex: 0.1;
`;

export const TitleContainer = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const TypeContainer = styled.span`
  display: flex;
  flex: 0.5;
  justify-content: center;
`;

export const ImageContainer = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const UrlContainer = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const LocaleContainer = styled.span`
  display: flex;
  flex: 0.25;
  justify-content: flex-start;
`;

export const ActionContainer = styled.span`
  display: flex;
  flex: 0.25;
  justify-content: center;
`;

export const SortByButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: Poppins;
  color: #828282;
`;

export const Label = styled.label<{ isHover?: boolean }>`
  font-family: Poppins;
  font-size: 11px;
  @media (min-width: 1024px) {
    font-size: 15px;
  }
  line-height: 160%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 250ms;
  cursor: ${({ isHover }) => isHover && 'pointer'};
  color: ${({ isHover, theme: { grayInfo, mainBlue } }) =>
    isHover ? mainBlue : grayInfo};
`;

export const ArrowIcon = styled(MdArrowDropDown)<{
  props: { isHover?: boolean; isUp?: boolean; isVisible?: boolean };
}>`
  width: 16px;
  height: 16px;
  @media (min-width: 1024px) {
    width: 20px;
    height: 20px;
  }
  opacity: ${({ props: { isVisible, isHover } }) =>
    isHover ? 1 : isVisible ? 1 : 0};
  color: ${({ props: { isHover }, theme: { grayInfo, mainBlue } }) =>
    isHover ? mainBlue : grayInfo};
  /* margin: 0 0 0 4px; */
  transform: ${({ props: { isUp } }) => isUp && `rotate(180deg)`};
  transition: 250ms;
`;
