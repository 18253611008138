import { ANALYSIS_STATUS } from './types';

export const MOCK_MENU_ITEMS = [
  {
    status: ANALYSIS_STATUS.ANY,
    label: 'analysis.all',
    isSelected: true,
    counter: 1,
  },
  {
    status: ANALYSIS_STATUS.NEW_ANALYSIS,
    label: 'analysis.statuses.new',
    isSelected: false,
    counter: 0,
  },
  {
    status: ANALYSIS_STATUS.SENT,
    label: 'analysis.sent',
    isSelected: false,
    counter: 1,
  },
  {
    status: ANALYSIS_STATUS.NOT_PAID,
    label: 'analysis.notPaid',
    isSelected: false,
    counter: 0,
  },
  {
    status: ANALYSIS_STATUS.READY,
    label: 'analysis.complete',
    isSelected: false,
    counter: 0,
  },
  {
    status: ANALYSIS_STATUS.NO_FILES,
    label: 'analysis.noFiles',
    isSelected: false,
    counter: 0,
  },
];
