import { MdArrowDropDown } from 'react-icons/md';
import { BiRefresh } from 'react-icons/bi';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 16px;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const CounterCell = styled.div`
  width: 24px;
  min-width: 24px;
  margin: 0 14px 0 0;
  @media (min-width: 1024px) {
    flex: 0.5;
  }
`;

export const HeaderCell = styled.div`
  flex: 4;
`;

export const EmailCell = styled.div`
  flex: 4;
  display: flex;
  @media (min-width: 1024px) {
    flex: 6;
    display: block;
  }
`;

export const QuantityCell = styled.div`
  flex: 1;
  display: flex;
  @media (min-width: 1024px) {
    flex: 2;
    display: block;
  }
`;

export const DateCell = styled.div`
  flex: 2;
  display: flex;
  @media (min-width: 1024px) {
    justify-content: center;
    // flex-basis: 174px;
    flex: 6;
    display: block;
  }
`;

export const StatusCell = styled.div`
  flex: 6;
`;

export const ActionsCell = styled.div`
  /* max-width: 200px; */
  flex: 7;
`;

export const Label = styled.label<{ isHover?: boolean }>`
  font-family: Poppins;
  font-size: 11px;
  @media (min-width: 1024px) {
    font-size: 15px;
  }
  line-height: 160%;
  display: block;
  transition: 250ms;
  cursor: ${({ isHover }) => isHover && 'pointer'};
  color: ${({ isHover, theme: { grayInfo, mainBlue } }) =>
    isHover ? mainBlue : grayInfo};
`;

export const ArrowIcon = styled(MdArrowDropDown)<{
  props: { isHover?: boolean; isUp?: boolean; isVisible?: boolean };
}>`
  width: 16px;
  height: 16px;
  @media (min-width: 1024px) {
    width: 20px;
    height: 20px;
  }
  opacity: ${({ props: { isVisible, isHover } }) =>
    isHover ? 1 : isVisible ? 1 : 0};
  color: ${({ props: { isHover }, theme: { grayInfo, mainBlue } }) =>
    isHover ? mainBlue : grayInfo};
  /* margin: 0 0 0 4px; */
  transform: ${({ props: { isUp } }) => isUp && `rotate(180deg)`};
  transition: 250ms;
`;

export const RefreshButton = styled(BiRefresh)`
  cursor: pointer;
`;
export const RefreshButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
`;
export const SortByButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
`;
