import styled from 'styled-components';

export const TimerBar = styled.div`
  background-color: ${({ theme: { orange } }) => orange};
  color: ${({ theme: { white } }) => white};
  transition: background-color 3s ease;
  transition: height 300ms;
  width: 100%;
  height: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TimeLabel = styled.p`
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  display: block;
  align-items: center;
`;

export const Time = styled(TimeLabel)`
  font-weight: 600;
  width: 2.5rem;
  margin-left: 0.25rem;
`;

export const Minutes = styled.span`
  width: 1rem;
  min-width: 1rem;
`;

export const Seconds = styled(Minutes)``;

export const Extend = styled(Time)`
  text-decoration: underline;
  cursor: pointer;
`;
