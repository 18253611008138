import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  word-break: break-word;
  background-color: #f4f5f9;
  overflow-x: scroll;
  min-width: 1200px;
  @media (min-width: 768px) {
    padding: 32px;
  }
`;

export const ResultsContainer = styled.div`
  width: 100%;
  margin: 0 0 32px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: end;
`;

export const ActionButton = styled.button`
  height: 32px;
  width: fit-content;
  border-radius: 4px;
  white-space: nowrap;
  background: linear-gradient(to right, #41bbff, #086ee7);
  color: ${({ theme: { white } }) => white};
  cursor: ${({ disabled }) => (disabled ? 'default' : `pointer`)};
  filter: ${({ disabled }) => (disabled ? 'saturate(.4)' : `saturate(1)`)};
  &:hover {
    ${({ disabled }) => (disabled ? '' : `box-shadow: 0 0 8px #41bbff;`)}
  }
  font-family: Poppins;
  font-weight: 500;
  font-size: 13px;
  @media (min-width: 1024px) {
    font-size: 15px;
  }
  line-height: 19px;
  text-align: center;
  padding: 0 16px;
  border: none;
  margin-left: 20px;
`;
