import styled from 'styled-components';
import colorTheme from '../../colorTheme';
import TextField from '@mui/material/TextField';
import { styled as muiStyled } from '@mui/material/styles';

export const Wrapper = styled.div`
  padding: 32px 24px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 32px;
  }
`;

export const TopWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  margin: 0 0 40px;
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryButton = styled.button<{ isSelected?: boolean }>`
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  transition: 250ms;
  cursor: pointer;
  color: ${({ isSelected, theme: { mainBlue, grayInfo } }) =>
    isSelected ? mainBlue : grayInfo};
  padding: 0 0 8px;
  border: none;
  background-color: transparent;
  border-bottom: ${({ isSelected, theme: { blue } }) =>
    isSelected ? `4px solid ${blue}` : '4px solid transparent'};
  margin: 0 32px 0 0;
`;

export const SelectorsWrapper = styled(CategoriesWrapper)`
  flex-wrap: wrap;
  input {
    padding: 0 16px;
    height: 32px;
  }
  margin: 24px 0 10px;
  @media (min-width: 1130px) {
    margin: 0 0 10px;
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  * {
    border-bottom-color: transparent;
    border: none;
  }
`;

export const DatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (min-width: 502px) {
    align-items: center;
    flex-direction: row;
  }
  div {
    flex-direction: row;
    width: auto;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 0;
  @media (min-width: 846px) {
    margin: 0;
  }
`;

export const SelectorLabel = styled.label`
  display: block;
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  margin: 0 8px 0 0;
  color: ${({ theme: { black } }) => black};
`;

export const DateLabel = styled(SelectorLabel)`
  margin: 0 18px 0 0;
  display: block;
`;

export const SecondDateLabel = styled(DateLabel)`
  margin: 0 18px 0 0;
  @media (min-width: 502px) {
    margin: 0 8px;
  }
`;

export const DateInput = styled.input`
  :focus {
    outline: none;
  }
  background-color: transparent;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  :placeholder {
    color: ${({ theme: { grayInfo } }) => grayInfo};
  }
  font-family: Poppins;
  font-size: 15px;
  line-height: 24px;
  border: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 40px !important;
  z-index: 1;
`;

export const DateWrapper = styled.div`
  height: 32px;
  max-width: 170px;
  background-color: ${({ theme: { white } }) => white};
  position: relative;
  display: flex;
  align-items: center;
`;

export const CustomInput = muiStyled(TextField)({
  '& label.Mui-focused': {
    color: colorTheme.mainBlue,
  },
  '& input:invalid + label.Mui-focused': {
    color: colorTheme.red,
  },
  '& input:required': {
    backgroundColor: colorTheme.inputBackground,
  },
  backgroundColor: colorTheme.gray,
  color: colorTheme.mainBlue,
  '& input': {
    padding: 0,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      padding: 0,
      border: 'none',
      borderBottom: `2px solid ${colorTheme.textGray}`,
      borderRadius: '0',
    },
    '&:hover fieldset': {
      borderColor: colorTheme.mainBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colorTheme.mainBlue,
    },
  },
});

export const ResultsContainer = styled.div`
  width: 100%;
  margin: 0 0 32px;
`;
