import LoginRegisterPart from '../Authentication/LoginRegisterPart';
import useMediaQuery from '../../hooks/useMediaQuery';
import RemindPasswordForm from '../Authentication/RemindPasswordForm/RemindPasswordForm';
import MobileRemindPasswordForm from '../Authentication/RemindPasswordForm/MobileRemindPasswordForm';
import * as S from './RemindPasswordPage.styles';

const RemindPasswordPage = () => {
  const isTablet = useMediaQuery('(min-width: 500px');
  return (
    <S.Wrapper>
      {isTablet ? (
        <>
          <LoginRegisterPart />
          <RemindPasswordForm />
        </>
      ) : (
        <MobileRemindPasswordForm />
      )}
    </S.Wrapper>
  );
};

export default RemindPasswordPage;
