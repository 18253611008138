import styled from 'styled-components';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

export const Relative = styled.div`
  position: absolute;
  width: 0;
  top: 4.5rem;
  height: 0;
  left: 140px;
  z-index: ${({
    theme: {
      zIndexes: { modals },
    },
  }) => modals};
  @media (min-width: 420px) {
    left: auto;
  }
`;

export const Wrapper = styled.div`
  box-shadow: 0px 4px 39px rgba(0, 164, 255, 0.26);
  min-width: 300px;
  max-width: 300px;
  width: 100%;
  background-color: ${({ theme: { white } }) => white};
  border-radius: 8px;
  position: absolute;
  left: -100px;
  @media (min-width: 440px) {
    left: -160px;
  }
  @media (min-width: 540px) {
    left: -260px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme: { gray } }) => gray};
  padding: 1rem 1.25rem 0.75rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled.h3`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  margin: 0;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const Counter = styled.p`
  color: ${({ theme: { white } }) => white};
  font-family: Poppins;
  font-weight: 600;
  font-size: 11px;
  line-height: 120%;
  text-align: center;
  margin: 0;
`;

export const CounterContainer = styled.div`
  background-color: ${({ theme: { orange } }) => orange};
  border-radius: 100%;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BodyWrapper = styled.div`
  padding: 0.25rem 0 0.25rem;
  box-sizing: border-box;
  overflow: auto;
  width: 100%;
  flex-grow: 1;
  max-height: 900px;
  overflow-x: hidden;
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1.25rem 0.25rem;
  margin: 0 0 0.25rem;
  background-color: ${({ theme: { white } }) => white};
  transition: background 250ms;
  display: flex;
  cursor: pointer;
  :hover {
    background-color: ${({ theme: { gray } }) => gray};
  }
`;

const Icon = `
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  margin: 0 .75rem auto 0;
  padding-top: 1px;
`;

export const CheckIcon = styled(BiCheckCircle)`
  ${Icon}
  color: ${({ theme: { green } }) => green};
`;

export const XIcon = styled(BiXCircle)`
  ${Icon}
  color: ${({ theme: { orange } }) => orange};
`;

export const Body = styled.p`
  font-family: Poppins;
  font-size: 11px;
  line-height: 160%;
  flex-grow: 1;
  margin: 0;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const Date = styled.p`
  font-family: Poppins;
  font-weight: 600;
  font-size: 11px;
  line-height: 160%;
  color: ${({ theme: { textGray } }) => textGray};
  white-space: nowrap;
  margin: 0 0 auto 1rem;
`;
