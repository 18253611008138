import { useDispatch, useSelector } from 'react-redux';
import { sendTosChangedEmail } from '../../../redux/admin/actions';
import { useIsLoadingSendingEmail } from '../../../redux/admin/selectors';
import BaseLoader from '../../Loaders/BaseLoader';
import * as S from './ToSChangeModal.styles';

interface IToSChangeModal {
  isOpen: boolean;
  onClose: () => void;
  amountOfAffectedUsers: number;
}
const ToSChangeModal = ({
  isOpen,
  onClose,
  amountOfAffectedUsers,
}: IToSChangeModal) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(useIsLoadingSendingEmail);
  const sendEmail = () => dispatch(sendTosChangedEmail());
  return (
    <S.Wrapper onClick={onClose}>
      {isOpen && (
        <S.InnerWrapper>
          <S.Container onClick={(e: any) => e.stopPropagation()}>
            <S.CloseContainer onClick={onClose}>
              <S.CloseIcon />
            </S.CloseContainer>
            <S.Title>Potwierdź akcję - email o zmianie Regulaminu</S.Title>
            <S.Body>
              Wyślesz e-mail do <b>{amountOfAffectedUsers}</b> użytkowników. Czy
              na pewno chcesz to zrobić?
            </S.Body>
            <S.ActionButton onClick={sendEmail} disabled={isLoading}>
              Potwierdź
            </S.ActionButton>
          </S.Container>
        </S.InnerWrapper>
      )}
    </S.Wrapper>
  );
};
export default ToSChangeModal;
