const colorTheme = {
  white: '#FFFFFF',
  black: '#000',
  gray: '#F4F5F9',
  grayInfo: '#828282',
  grayFont: '#C9C9C9',
  mainBlue: '#07005C',
  gradientBlue: '#086EE7',
  greenDark: '#D3F0F6',
  blue: '#41BBFF',
  orange: '#FF4200',
  red: '#ff0000',
  inputBackground: '#EAECF4',
  green: '#0CA479',
  textGray: '#C9C9C9',
  grayBorder: '#E0E0E0',
  zIndexes: {
    hidden: -1,
    background: 0,
    firstPlane: 1,
    navbar: 2,
    modals: 3,
  },
};

export default colorTheme;
