import styled from 'styled-components';

export const Wrapper = styled.div<{ disabled: boolean; isWarning?: boolean }>`
  padding: 24px 32px;
  background: white;
  margin-bottom: 18px;
  border-radius: 16px;
  border: ${({ isWarning }) =>
    isWarning ? 'solid 1px rgb(228, 5, 55)' : 'dashed 1px lightgray'};
  cursor: ${({ disabled }) => (disabled ? 'auto ' : 'pointer')};
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:hover {
    box-shadow: ${({ isWarning }) =>
      isWarning ? 'none' : '0px 0px 10px #41bbff;'};
  }
`;

export const DropzoneContent = styled.div`
  width: 100%;
`;

export const DropzoneTopContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const DropzoneTopContentVertical = styled(DropzoneTopContent)`
  flex-direction: column;
  height: 170px;
`;

export const DropzoneBottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadIcon = styled.img`
  padding-bottom: 8px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

export const Text = styled.span<{ isVertical?: boolean }>`
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme: { grayInfo } }) => grayInfo};
  padding-bottom: 8px;
  max-width: ${({ isVertical }) => (isVertical ? '350px' : 'auto')};
  text-align: ${({ isVertical }) => (isVertical ? 'justify' : 'center')};
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

export const SendFilesButton = styled.button<{
  disabled?: boolean;
  isWarning?: boolean;
}>`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  letter-spacing: 1px;
  justify-content: center;
  background: ${({ isWarning }) =>
    isWarning
      ? 'rgb(228, 5, 55)'
      : 'linear-gradient(to right, #41bbff, #086ee7)'};
  border: ${({ isWarning }) =>
    isWarning ? '2px solid rgb(228, 5, 55)' : '2px solid #41bbff'};
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  max-width: 325px;
  &:hover {
    box-shadow: ${({ disabled, isWarning }) =>
      isWarning ? '' : disabled ? '' : '0px 4px 39px #41bbff'};
  }
  padding: 11.5px 22px;
`;
