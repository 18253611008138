import styled from 'styled-components';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export const ExternalWrapper = styled.div`
  position: relative;
  background-color: #07005c;
  z-index: ${({
    theme: {
      zIndexes: { background },
    },
  }) => background};
  min-height: 100vh;
  width: 50%;
`;

export const DotsTop = styled.img`
  max-width: auto;
  height: auto;
`;

export const DotsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-left: auto;
  z-index: 1;
`;

export const Wrapper = styled.div`
  justify-content: center;
  padding: 35px 25px;
  z-index: 2;
  position: relative;
  @media (min-width: 1200px) {
    padding: 35px 50px;
  }
  @media (min-width: 1600px) {
    padding: 35px 160px;
  }
`;

export const Logo = styled.div`
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const TechnologyWrapper = styled.div``;

export const TickIconWrapper = styled.div`
  min-width: 45px;
  margin-right: 10px;
  width: 32px;
  height: 32px;
`;

export const TechTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  padding: 20px 0 20px 0;
  @media (min-width: 1024px) {
    padding: 40px 0 20px 0;
  }
  @media (min-width: 1400px) {
    padding: 50px 0 20px 0;
  }
`;

export const TechTitleText = styled.div`
  font-family: Poppins;
  font-size: 22px;
  line-height: 31.2px;
  color: #ffffff;
  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

export const SupportWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 7px 0;
  /* min-height: 50px; */
`;

export const TechBodyContainer = styled.div``;

export const Body = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 300;
  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;

export const AboutUsWrapper = styled.div``;

export const AboutUsTextWrapper = styled.div``;

export const DoctorWrapper = styled.div`
  display: flex;
  padding-top: 25px;
  @media (min-width: 1600px) {
    padding-top: 30px;
  }
`;

export const PictureWrapper = styled.div`
  width: 60px;
  margin-right: 10px;
  margin-top: auto;
`;

export const DoctorPhoto = styled.img`
  border-radius: 50%;
`;

export const TextWrapper = styled.div``;

export const TitleContainer = styled.div``;

export const TitleText = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 21px;
  line-height: 25.5px;
  color: #fff;
`;

export const InfoContainer = styled.div``;

export const InfoText = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 1px;
  background: -webkit-linear-gradient(left, #41bbff, #086ee7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ContactWrapper = styled.div`
  display: flex;
  padding-top: 30px;
`;

export const TextContainer = styled.div`
  display: flex;
  font-family: Poppins;
  font-size: 12px;
  color: white;
  align-items: center;
`;

export const Bolded = styled.span`
  font-weight: 600;
`;

export const Contact = styled.span`
  padding-right: 4px;
`;

export const ContactButton = styled.a`
  display: flex;
  border-radius: 4px;
  border: 1px solid white;
  color: white;
  background: transparent;
  text-transform: uppercase;
  padding: 4px 6px;
  text-decoration: none;
  font-weight: 600;
  font-size: 10px;
  &:hover {
    box-shadow: 0 0 8px white;
  }
`;
