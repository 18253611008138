import TextField from '@mui/material/TextField';
import { useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import type { } from '@mui/lab/themeAugmentation';
import { useTranslation } from 'react-i18next';
import * as S from '../AnalysisTable.styles';

interface IDualDateSelect {
  dateMin?: string;
  dateMax?: string;
  changeDateMin: (value: string | null) => void;
  changeDateMax: (value: string | null) => void;
}

export default function DualDateSelect({
  dateMin,
  dateMax,
  changeDateMin,
  changeDateMax,
}: IDualDateSelect) {
  const { t } = useTranslation();
  const jsNow = new Date();
  const jsMinDate = dateMin ? new Date(dateMin) : null;
  const jsMaxDate = dateMax ? new Date(dateMax) : null;
  const maxThreshold = jsMaxDate ? jsMaxDate : jsNow;
  const minThreshold = jsMinDate ? jsMinDate : jsNow;
  const [localMinDate, setLocalMinDate] = useState<Date | null>(jsMinDate);
  const [localMaxDate, setLocalMaxDate] = useState<Date | null>(jsMaxDate);

  const handleSetMinDate = (value: Date | null) => {
    setLocalMinDate(value);
    if (value instanceof Date && !isNaN(value.getTime())) {
      changeDateMin(value.toISOString());
    }
    if (value === null) {
      changeDateMin(value);
    }
  };

  const handleSetMaxDate = (value: Date | null) => {
    setLocalMaxDate(value);
    if (value instanceof Date && !isNaN(value.getTime())) {
      changeDateMax(value.toISOString());
    }
    if (value === null) {
      changeDateMax(value);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <S.DatesWrapper>
        <S.DateContainer>
          <S.DateLabel>{t('analysis.table.date')}</S.DateLabel>
          <S.DateWrapper>
            <DesktopDatePicker
              inputFormat={'dd.MM.yyyy'}
              value={localMinDate}
              maxDate={maxThreshold}
              onChange={handleSetMinDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </S.DateWrapper>
        </S.DateContainer>
        <S.DateContainer>
          <S.SecondDateLabel>{t('analysis.table.dateTo')}</S.SecondDateLabel>
          <S.DateWrapper>
            <DesktopDatePicker
              inputFormat={'dd.MM.yyyy'}
              minDate={minThreshold}
              maxDate={jsNow}
              value={localMaxDate}
              onChange={handleSetMaxDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </S.DateWrapper>
        </S.DateContainer>
      </S.DatesWrapper>
    </LocalizationProvider>
  );
}
