import { MdOutlineClose } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BsCloudDownload, BsFolder2 } from 'react-icons/bs';
import styled from 'styled-components';
import { commonButton } from '../MyAnalysis/AnalysisItem/AnalysisFolder.styles';

export const DrawerContainer = styled.div`
  position: fixed;
  width: 100%;
  right: 0;
  z-index: 1;
  background-color: white;
  top: 96px;
  height: calc(100vh - 96px);
  overflow: scroll;
  @media (min-width: 768px) {
    width: 568px;
    /* overflow: hidden; */
  }
`;
export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 28px 24px;
`;

export const HeaderWrapper = styled.div`
  padding-bottom: 32px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-family: Poppins;
  font-size: 26px;
  line-height: 31.2px;
  color: #07005c;
  font-weight: 600;
  @media (min-width: 768px) {
    font-size: 35px;
  }
`;
export const CloseContainer = styled.button`
  border-radius: 50%;
  background-color: #f4f5f9;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 48px;
  height: 48px;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 20px #41bbff;
  }
`;

export const CloseIcon = styled(MdOutlineClose)`
  width: 26px;
  height: 26px;
  color: #07005c;
`;

export const StatusWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  justify-content: space-between;
`;

export const StatusContainer = styled.div``;

export const Status = styled.div``;

export const DateContainer = styled.div`
  display: flex;
`;

export const Date = styled.div`
  display: flex;
  font-family: Poppins;
  font-size: 14px;
  line-height: 22.4px;
  color: #828282;
  font-weight: 400;
  align-items: center;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 6px;
`;

export const VisualizationWrapper = styled.div`
  padding-bottom: 16px;
`;

export const VisualizationTitle = styled(Title)`
  font-size: 26px;
  padding-bottom: 18px;
`;
export const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 19.5px;
  color: white;
  border: none;
  background: linear-gradient(to right, #41bbff, #086ee7);
  padding: 14px 10px;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  max-width: 388px;
  &:hover {
    box-shadow: 0px 4px 20px #41bbff;
  }
`;
export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 19.5px;
  color: white;
  border: none;
  background: #d94456;
  padding: 14px 10px;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 14px;
  cursor: pointer;
  max-width: 388px;
  &:hover {
    box-shadow: 0px 4px 20px #d94456;
  }
`;

export const DownloadIcon = styled(FiDownload)`
  color: white;
  width: 20px;
  height: 20px;
  padding-right: 12px;
`;

export const DescriptionWrapper = styled.div``;

export const DescriptionTitle = styled(Title)`
  padding-bottom: 18px;
  font-size: 26px;
`;

export const DicomWrapper = styled.div`
  padding-bottom: 32px;
`;

export const DicomTitle = styled(Title)`
  padding-bottom: 18px;
  font-size: 26px;
`;

export const DicomContainer = styled.div`
  background-color: #f4f5f9;
  border-radius: 4px;
  padding: 20px 14px;
  @media (min-width: 768px) {
    padding: 22px 30px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FolderIconContainer = styled.div`
  display: flex;
  padding-right: 18px;
`;

export const InfoContainer = styled.div`
  display: flex;
`;

export const TitleContainer2 = styled.div``;

export const Title2 = styled(Title)`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
`;

export const DetailsContainer = styled.div`
  font-family: Poppins;
  color: #828282;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 14px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

export const FolderIcon = styled(BsFolder2)`
  color: #41bbff;
  width: 48px;
  height: 48px;
`;

export const DownloadAllWrapper = styled.div``;
export const DownloadAnalysisWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e0e0e0;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const DownloadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeleteContainer = styled.button`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #ff4200;
  border: none;
  margin-left: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 20px #ff4200;
  }
`;

export const DeleteIcon = styled(RiDeleteBin6Line)`
  color: white;
  width: 20px;
  height: 20px;
  padding-right: 12px;
`;

export const DownloadContainer = styled(DeleteContainer)`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #41bbff;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 20px #41bbff;
  }
`;

export const DownloadIcon2 = styled(BsCloudDownload)`
  color: white;
  width: 100%;
  height: 100%;
`;

export const DownloadAllButton = styled(DownloadButton)`
  margin-top: 14px;
`;

export const SubmissionFilesWrapper = styled.div`
  display: flex;
  padding-bottom: 18px;
  flex-wrap: wrap;
`;

export const ImageWrapper = styled.div`
  height: 80px;
  width: 120px;
  position: relative;
  padding: 2px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const MovieIconContainer = styled.div`
  font-size: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #41bbff;
  background-color: #f3f6fd;
`;

export const DownloadImageContainer = styled(DownloadContainer)`
  position: absolute;
  right: 4px;
`;

export const PaymentRequiredSection = styled.div`
  background-color: #ffeff1;
  border-radius: 8px;
  padding: 24px 32px;
  display: flex;
  align-items: center;
`;

export const ExclamationMarkContainer = styled.div`
  padding-right: 24px;
`;

export const PaymentRequiredBody = styled.div`
  padding-bottom: 16px;
  color: #07005c;
  line-height: 24px;
  font-size: 15px;
`;

export const PayButton = styled.button`
  ${commonButton}
  border: none;
  text-decoration: none;
  padding: 2px;
  width: auto;
  background-color: #ffeff1;
  margin: 0;
`;

export const Label = styled.p`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme: { white } }) => white};
  margin: 0;
`;

export const WhiteLabel = styled(Label)`
  background: linear-gradient(to right, #41bbff, #086ee7);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.25rem 32px;
  background-color: #ffeff1;
`;

export const WhiteLabelContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffeff1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const DeleteModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #0000006b;
  padding: 1rem;
  @media (min-width: 450px) {
    padding: 2rem;
  }
  @media (min-width: 600px) {
    padding: 4rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
`;

export const DeleteModalContainer = styled.div`
  border-radius: 8px;
  /* min-height: 30vh; */
  min-width: 20vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  /* @media (min-width: 768px){
    min-width: 40vh;
  } */
`;

export const DeleteModalTitle = styled.div`
  display: flex;
  font-family: Poppins;
  font-size: 16px;
  color: #07005c;
  font-weight: 600;
  justify-content: center;
  font-size: 24px;
  padding-bottom: 8px;
`;

export const Checkbox = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

export const DeleteModalBody = styled.div`
  flex-grow: 1;
`;
export const DeleteModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

export const LabelContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;
`;

export const FileLabel = styled.span`
  font-family: Poppins;
  font-size: 16px;
  color: #07005c;
`;
export const DeleteModalButton = styled.button`
  font-family: Poppins;
  color: white;
  background: orange;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  padding: 0 24px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  min-width: 140px;
  &:hover {
    box-shadow: 0px 0px 10px orange;
  }
`;
export const CancelButton = styled(DeleteModalButton)`
  margin-bottom: 8px;
  @media (min-width: 768px) {
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

export const ConfirmButton = styled(DeleteModalButton)`
  background: green;
  &:hover {
    box-shadow: 0px 0px 10px green;
  }
`;
