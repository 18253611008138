import {
  getSortedSubmissions,
  getSubmissionStatus,
} from '../../utils/submission';
import { ANALYSIS_STATUS } from '../../utils/types';
import { RESET_SEARCH_PARAMS } from '../admin/actionTypes';
import { UPLOAD_TYPE } from './actions';
import * as A from './actionTypes';
import { ISubmission, SubmissionReducerState } from './types';

const emptySubmissionCounters = {
  [ANALYSIS_STATUS.ANONYMIZATION]: 0,
  [ANALYSIS_STATUS.SENT]: 0,
  [ANALYSIS_STATUS.READY]: 0,
  [ANALYSIS_STATUS.NOT_PAID]: 0,
  [ANALYSIS_STATUS.NO_FILES]: 0,
  [ANALYSIS_STATUS.ANY]: 0,
  [ANALYSIS_STATUS.NEW_ANALYSIS]: 0,
};

export enum SUBMISSION_FIELDS {
  ID = 'id',
  TITLE = 'title',
  QUANTITY = 'quantity',
  DATE = 'date',
  EMAIL = 'email',
  STATUS = 'status',
}

export interface SubmissionSortParams {
  field: SUBMISSION_FIELDS;
  direction: 'asc' | 'desc';
}

export const initialState: SubmissionReducerState = {
  submissionsList: [],
  submissionCounters: emptySubmissionCounters,
  submissionData: undefined,
  upload: {
    currentFile: 0,
    currentFilename: '',
    filesCount: 0,
    finished: false,
  },
  isLoading: {
    submissionsList: false,
    upload: false,
    isZipping: false,
  },
  hasError: {
    submissionsList: false,
    upload: false,
  },
  paymentSessionId: '',
  drawerSubmissionId: '',
  percentage: 0,
  uploadPercentage: 0,
  uploadType: UPLOAD_TYPE.UPLOAD,
  isZipping: false,
  search: {
    query: '',
    perPage: 10,
    page: 0,
    status: ANALYSIS_STATUS.ANY,
  },
};

const submissionReducer = (
  state = initialState,
  action: any
): SubmissionReducerState => {
  switch (action.type) {
    case A.CREATE_SUBMISSION: {
      return {
        ...state,
      };
    }
    case A.FETCH_SUBMISSIONS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          submissionsList: true,
        },
      };
    }
    case A.FETCH_SUBMISSIONS_SUCCESS: {
      const newSubmissionCounters = { ...emptySubmissionCounters };
      const { subs, isAdmin } = action.data;
      const submissionsList = subs.map((sub: ISubmission) => {
        const status = getSubmissionStatus(sub, isAdmin);
        newSubmissionCounters[status] += 1;
        return { ...sub, status };
      });
      const sortedSubmissions = getSortedSubmissions(submissionsList);
      newSubmissionCounters[ANALYSIS_STATUS.ANY] = submissionsList.length;
      return {
        ...state,
        submissionsList: sortedSubmissions,
        submissionCounters: newSubmissionCounters,
        isLoading: {
          ...state.isLoading,
          submissionsList: false,
        },
      };
    }
    case A.CREATE_PAYMENT_SESSION_SUCCESS: {
      const { paymentSessionId } = action.data;
      return {
        ...state,
        paymentSessionId,
      };
    }
    case A.SET_DRAWER_SUBMISSION_ID: {
      const { id } = action.data;
      return {
        ...state,
        drawerSubmissionId: id,
      };
    }
    case A.SET_SEARCH_PARAMS: {
      const data = action.data;
      return {
        ...state,
        search: {
          ...state.search,
          ...data,
        },
      };
    }
    case A.UPLOAD_PERCENTAGE_CHANGED: {
      const { percentage, type } = action.data;
      return {
        ...state,
        uploadPercentage: percentage,
        uploadType: type,
      };
    }
    case A.UPLOAD_SUBMISSION_PHOTOS: {
      return {
        ...state,
        uploadPercentage: 1,
      };
    }
    case A.UPLOAD_SUBMISSION_PHOTOS_SUCCESS: {
      return {
        ...state,
        uploadPercentage: 0,
      };
    }

    case A.DESTROY_ARCHIVED_SUBMISSION_SUCCESS: {
      const { id } = action.data;
      return {
        ...state,
        submissionsList: [...state.submissionsList.filter((l) => l.id !== id)],
      };
    }

    case A.REMOVE_NEW_STATUS: {
      const { submissionId } = action.data as { submissionId: string };

      const elementIndex = state.submissionsList.findIndex(
        (l) => l.id === submissionId
      );

      if (elementIndex === -1) {
        return state;
      }

      if (!state.submissionsList[elementIndex].paid) {
        return state;
      }

      const newSubmissionList = [
        ...state.submissionsList.slice(0, elementIndex),
        {
          ...state.submissionsList[elementIndex],
          isTouchedByOperator: true,
          status: ANALYSIS_STATUS.SENT,
        },
        ...state.submissionsList.slice(elementIndex + 1),
      ];

      return {
        ...state,
        submissionsList: newSubmissionList,
      };
    }

    case A.RESET_UPLOAD_PERCENTAGE: {
      return {
        ...state,
        uploadPercentage: 0,
      };
    }

    case A.ARCHIVE_SUBMISSION_SUCCESS: {
      const { id } = action.data;
      return {
        ...state,
        submissionsList: [...state.submissionsList.filter((l) => l.id !== id)],
      };
    }

    case A.SORT_SUBMISSIONS_TABLE: {
      const { direction, field } = action.data as {
        direction: 'desc' | 'asc';
        field: SUBMISSION_FIELDS;
      };
      let submissionList = [...state.submissionsList];

      const sortStrings = (a: string, b: string) =>
        a.toUpperCase().localeCompare(b.toUpperCase());
      const isAsc = direction === 'desc';
      if (field === SUBMISSION_FIELDS.DATE || field === SUBMISSION_FIELDS.ID) {
        submissionList = isAsc
          ? submissionList.sort(
            (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
          )
          : submissionList.sort(
            (a, b) => +new Date(a.created_at) - +new Date(b.created_at)
          );
      }
      if (field === SUBMISSION_FIELDS.QUANTITY) {
        submissionList = submissionList.sort((a, b) => {
          return isAsc
            ? (a.filesUploaded?.resultFiles?.length || 0) -
            (b.filesUploaded?.resultFiles?.length || 0)
            : (b.filesUploaded?.resultFiles?.length || 0) -
            (a.filesUploaded?.resultFiles?.length || 0);
        });
      }
      if (field === SUBMISSION_FIELDS.EMAIL) {
        submissionList = submissionList.sort(
          (
            { createdBy: { email: aEmail = '' } },
            { createdBy: { email: bEmail = '' } }
          ) =>
            isAsc ? sortStrings(aEmail, bEmail) : sortStrings(bEmail, aEmail)
        );
      }
      if (field === SUBMISSION_FIELDS.STATUS) {
        submissionList = submissionList.sort(
          ({ status: aStatus }, { status: bStatus }) =>
            isAsc
              ? sortStrings(aStatus, bStatus)
              : sortStrings(bStatus, aStatus)
        );
      }
      if (field === SUBMISSION_FIELDS.TITLE) {
        submissionList = submissionList.sort(
          ({ description: aDescription }, { description: bDescription }) =>
            isAsc
              ? sortStrings(aDescription, bDescription)
              : sortStrings(bDescription, aDescription)
        );
      }
      return {
        ...state,
        submissionsList: submissionList,
      };
    }
    case RESET_SEARCH_PARAMS: {
      const data = action.data;
      return {
        ...state,
        search: {
          ...state.search,
          ...data,
        },
      };
    }
    default:
      return state;
  }
};

export default submissionReducer;
