import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { passwordReset } from '../../../redux/auth/actions';
import LanguageModal from '../../../common/LanguageModal/LanguageModal';
import * as S from './RemindPasswordForm.styles';

const RemindPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const [email, setEmail] = useState('');
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(passwordReset(email, selectedLanguage, t));
  };

  const isFormValid = email;
  return (
    <S.Wrapper>
      <S.Container>
        <S.LanguagePickWrapper>
          <LanguageModal />
        </S.LanguagePickWrapper>
        <S.FormWrapper>
          <S.Title>{t('remindPasswordForm.remindPassword')}</S.Title>
          <S.Body>{t('remindPasswordForm.body')}</S.Body>
          <TextField
            type="email"
            label={t('remindPasswordForm.email')}
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
          />
        </S.FormWrapper>
        <S.ButtonContainer>
          <S.ConfirmButton
            type="submit"
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            {t('remindPasswordForm.remindButton')}
          </S.ConfirmButton>
        </S.ButtonContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default RemindPasswordForm;
