import styled from 'styled-components';

export const Wrapper = styled.form`
  width: 50%;
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (min-width: 1200px) {
    width: 520px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* min-width: 275px; */
  padding: 80px 20px 30px 20px;
`;

export const Title = styled.span`
  font-family: Poppins;
  font-size: 32px;
  line-height: 38.5px;
  font-weight: 650;
  padding-bottom: 20px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;
export const Body = styled.div`
  font-family: Poppins;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  padding-bottom: 20px;
`;
export const LanguagePickWrapper = styled.div`
  margin-left: auto;
  padding: 10px 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 30px auto 30px 0;
  padding: 0 20px;
`;

export const ConfirmButton = styled.button<{ disabled?: boolean }>`
  padding: 12.5px 30px;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  max-width: 325px;
  letter-spacing: 1px;
  text-align: center;
  color: ${({ theme: { white } }) => white};
  background: ${({ disabled, theme: { grayFont, blue, gradientBlue } }) =>
    disabled
      ? grayFont
      : `linear-gradient(to right, ${blue}, ${gradientBlue})`};
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '' : '0px 4px 39px #41bbff')};
  }
  @media (min-width: 950px) {
    width: 215px;
    margin: auto 0 auto auto;
    padding: 12.5px 0;
    order: 2;
  }
`;

export const LocaleSelect = styled.select`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  border: none;
  cursor: pointer;
  background-color: ${({ theme: { white } }) => white};
  :focus-visible {
    outline: none;
  }
`;

export const Option = styled.option`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  background-color: ${({ theme: { white } }) => white};
`;
