import { extractNonZIPFiles, extractZIPFiles } from '../../utils/submission';
import { ANALYSIS_STATUS } from '../../utils/types';
import { AppState } from '../store';

export const submissionState = (state: AppState) => state.submission;

export const searchParams = (state: AppState) => state.submission.search;

export const useAllSubmissions = (state: AppState) =>
  state.submission.submissionsList;

export const useSubmissionDetails =
  (submissionId: string) => (state: AppState) =>
    state.submission.submissionsList.find((s) => s.id === submissionId);

export const filteredSubmissions = (state: AppState) => {
  const {
    submissionsList,
    search: { query, status, dateMin, dateMax, page, perPage },
  } = state.submission;
  const uppercaseQuery = query ? query.toUpperCase() : '';

  const filtered = submissionsList.filter(
    ({
      description,
      email = '',
      status: currentStatus,
      created_at,
      createdBy,
      id,
    }) => {
      const uppercaseDescription = description.toUpperCase();
      const uppercaseEmail = email.toUpperCase();
      const uppercaseName = createdBy ? createdBy.name.toUpperCase() : '';
      const uppercaseSurname = createdBy ? createdBy.surname.toUpperCase() : '';
      const isQueryFulfilled =
        id === query ||
        uppercaseDescription === uppercaseQuery ||
        uppercaseEmail === uppercaseQuery ||
        uppercaseSurname === uppercaseQuery ||
        uppercaseName === uppercaseQuery ||
        uppercaseDescription.includes(uppercaseQuery) ||
        uppercaseEmail.includes(uppercaseQuery) ||
        uppercaseSurname.includes(uppercaseQuery) ||
        uppercaseName.includes(uppercaseQuery);
      const isStatusFulfilled =
        status === ANALYSIS_STATUS.ANY ? true : currentStatus === status;
      const dateA = dateMin ? new Date(dateMin) : false;
      const dateB = dateMax ? new Date(dateMax) : false;
      const currentDate = new Date(created_at);
      const isMinDateFulfilled = dateA ? currentDate > dateA : true;
      const isMaxDateFulfilled = dateB ? currentDate < dateB : true;
      const isDateFulfiller = isMinDateFulfilled && isMaxDateFulfilled;
      return isQueryFulfilled && isStatusFulfilled && isDateFulfiller;
    }
  );
  const countedFiltered = filtered.map((submission, index: number) => ({
    ...submission,
    count: index + 1,
  }));
  const firstIndex = page * perPage,
    lastIndex = firstIndex + perPage;
  const results = countedFiltered.slice(firstIndex, lastIndex);
  const count = countedFiltered.length;
  const maxPage = Math.ceil(count / perPage);
  return { results, count, maxPage };
};

export const useAllSubmissionCounters = (state: AppState) =>
  state.submission.submissionCounters;

export const usePaymentSessionId = (state: AppState) =>
  state.submission.paymentSessionId;

export const useDrawerSubmissionId = (state: AppState) =>
  state.submission.drawerSubmissionId;

export const useSelectedDrawerSubmission = (state: AppState) =>
  state.submission.submissionsList.find(
    (s) => s.id === state.submission.drawerSubmissionId
  );

export const useUploadPercentage = (state: AppState) =>
  state.submission.uploadPercentage;

export const useIsLoadingSubmissionsList = (state: AppState) =>
  state.submission.isLoading.submissionsList;

export const useSelectedSubmissionFiles = (state: AppState) => {
  const submission = useSelectedDrawerSubmission(state);

  let output = {
    submitted: {
      files: extractNonZIPFiles(submission),
      zip: extractZIPFiles(submission),
    },
    results: {
      files: extractNonZIPFiles(submission, true),
      zip: extractZIPFiles(submission, true),
    },
  };

  return output;
};

export const useUploadType = (state: AppState) => state.submission.uploadType;
