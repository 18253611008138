import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(229, 251, 255, 1);
  width: 48px;
  height: 48px;
  margin-right: 12px;
  cursor: pointer;
`;
export const Initials = styled.span`
  display: flex;
  color: rgba(65, 187, 255, 1);
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 16px;
  font-family: Poppins;
  text-transform: uppercase;
`;

export const AvatarCircleModal = styled.div`
  display: flex;
  position: absolute;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #dcdada;
  top: 86px;
  z-index: 10;
  cursor: auto;
  min-width: 160px;
  box-shadow: 0 0 6px rgba(0, 164, 255, 0.26);
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AvatarItem = styled.span`
  display: flex;
  font-family: Poppins;
  font-size: 12px;
  color: #07005c;
  cursor: pointer;
  margin: 12px;
  &:hover {
    font-weight: 600;
  }
`;
