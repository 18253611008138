export const getFilesSizeFormatted = (bytes: number) => {
  if (!bytes) {
    return 0;
  }

  const mb = Math.max(bytes / (1000 * 1000), 0.01);

  if (mb >= 1000) {
    return `${(mb / 1000).toFixed(2)}GB`;
  }

  return `${mb.toFixed(2)}MB`;
};

export const getFileSizeInMb = (bytes: number) => {
  if (!bytes) {
    return 0;
  }

  return Math.max(bytes / (1000 * 1000), 0.01);
};
