import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchImageAlts,
  setSearchSeoParams,
  setSEOImagesListSort,
} from '../../../redux/admin/actions';
import {
  filteredSEOAlts,
  seoSearchParams,
} from '../../../redux/admin/selectors';
import Pagination from '../../AnalysisTable/Pagination/Pagination';
import SEOTableImagesRow, {
  ISEOImageAlt,
} from './SEOTableImagesRow/SEOTableImagesRow';
import SEOTableImagesHeader from './SEOTableImagesHeader/SEOTableImagesHeader';
import {
  SEOImagesSortParams,
  SEO_IMAGES_FIELD,
} from '../../../redux/admin/adminReducer';
import * as S from '../SEOTable.styles';

const SEOTableImages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchImageAlts());
  }, [dispatch]);

  const { maxPage, results } = useSelector(filteredSEOAlts);
  const { page, perPage } = useSelector(seoSearchParams);
  const [data, setData] = useState<ISEOImageAlt[]>(results);
  const [sortBy, setSortBy] = useState<SEOImagesSortParams>({
    field: SEO_IMAGES_FIELD.IMAGE_TITLE,
    direction: 'desc',
  });

  const setSelectedPage = (value: number) =>
    dispatch(setSearchSeoParams({ page: value }));
  const setItemsPerPage = (value: number) =>
    dispatch(setSearchSeoParams({ perPage: value }));

  useEffect(() => {
    setData(results);
  }, [results]);

  const handleSortBy = (params: SEOImagesSortParams) => {
    const { direction, field } = params;
    dispatch(setSEOImagesListSort(direction, field));
    setSortBy(params);
  };
  return (
    <S.Wrapper>
      <SEOTableImagesHeader sortBy={sortBy} setSortBy={handleSortBy} />
      <S.ResultsContainer>
        {data.map((row, index: number) => (
          <SEOTableImagesRow {...row} key={index} />
        ))}
      </S.ResultsContainer>
      <Pagination
        selectedPage={page}
        setSelectedPage={setSelectedPage}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={perPage}
        maxPage={maxPage}
      />
    </S.Wrapper>
  );
};

export default SEOTableImages;
