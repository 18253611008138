import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LanguageModal from '../../../common/LanguageModal/LanguageModal';
import { toast } from 'react-toastify';
import { confirmPasswordReset } from '../../../redux/auth/actions';
import { NavLink, useParams } from 'react-router-dom';
import Logo from '../../../assets/authentication/LogoBlue.png';

import * as S from './MobileConfirmPasswordResetForm.styles';
import MobileLoginRegisterPart from '../MobileLoginRegisterPart';

const MobileConfirmPasswordResetForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const { token } = useParams() as { token: string };

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isPasswordLengthCorrect =
      password.length >= 10 && confirmPassword.length >= 10;
    const arePasswordsEqual = password === confirmPassword;
    if (!isPasswordLengthCorrect) {
      return toast.warn(t('settings.passwordTooShort'));
    }
    if (!arePasswordsEqual) {
      return toast.warn(t('settings.passwordsDoNotMatch'));
    }
    dispatch(confirmPasswordReset(password, selectedLanguage, token, t));
  };

  const handleKeyPress = (e: any) => {
    if (e.code === 'Enter') {
      handleSubmit(e);
    }
  };

  const isFormValid = password && confirmPassword;

  return (
    <S.Wrapper>
      <S.Container>
        <S.HeaderWrapper>
          <S.LogoWrapper>
            <NavLink to="/">
              <S.Logo src={Logo}></S.Logo>
            </NavLink>
          </S.LogoWrapper>
          <S.LanguagePickWrapper>
            <LanguageModal />
          </S.LanguagePickWrapper>
        </S.HeaderWrapper>
        <S.FormWrapper>
          <S.Title>{t('remindPasswordForm.confirmRemindPassword')}</S.Title>
          <S.Body>{t('remindPasswordForm.confirmBody')}</S.Body>
          <TextField
            type="password"
            label={t('remindPasswordForm.passwordPlaceholder')}
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            autoComplete="new-password"
          />
          <TextField
            type="password"
            label={t('remindPasswordForm.confirmPasswordPlaceholder')}
            variant="standard"
            onChange={(e) => setConfirmPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            autoComplete="new-password"
          />
        </S.FormWrapper>
        <S.ConfirmButton
          type="submit"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          {t('remindPasswordForm.changePasswordConfirm')}
        </S.ConfirmButton>
      </S.Container>
      <MobileLoginRegisterPart />
    </S.Wrapper>
  );
};

export default MobileConfirmPasswordResetForm;
