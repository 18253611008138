import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { userData, useUserLoggedIn } from '../../redux/auth/selectors';
import Layout from '../Layout/Layout';

export interface ProtectedProps {
  path?: string;
  component?: any;
  exact?: boolean;
}

export const OperatorRoute = ({
  component: Component,
  ...rest
}: ProtectedProps) => {
  const isUserLoggedIn = useSelector(useUserLoggedIn);
  const user = useSelector(userData);
  const isPriviliged = user.role === 'admin' || user.role === 'operator';
  const isValidUser = isUserLoggedIn && isPriviliged;
  return (
    <Route
      {...rest}
      render={(props) =>
        isValidUser ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default OperatorRoute;
