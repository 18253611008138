import { TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/authentication/LogoBlue.png';
import MobileLoginRegisterPart from '../MobileLoginRegisterPart';
import { login, resetSignInError } from '../../../redux/auth/actions';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSignInErrorMessage } from '../../../redux/auth/selectors';
import LanguageModal from '../../../common/LanguageModal/LanguageModal';
import i18n from '../../../i18n';
import * as S from './MobileLoginForm.styles';

const MobileLoginForm = () => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const dispatch = useDispatch();
  const signInErrorMessage = useSelector(useSignInErrorMessage);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(login({ email, password, language: selectedLanguage }));
  };

  const isFormValid = email && password;

  useEffect(() => {
    if (signInErrorMessage) {
      toast.warn(t(signInErrorMessage));
      dispatch(resetSignInError());
    }
  }, [signInErrorMessage, dispatch, t]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.HeaderWrapper>
          <S.LogoWrapper>
            <NavLink to="/">
              <S.Logo src={Logo}></S.Logo>
            </NavLink>
          </S.LogoWrapper>
          <S.LanguagePickWrapper>
            <LanguageModal />
          </S.LanguagePickWrapper>
        </S.HeaderWrapper>
        <S.FormWrapper>
          <S.Title>{t('loginForm.login')}</S.Title>
          <TextField
            type="email"
            label={t('loginForm.mailPlaceholder')}
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
            style={{ paddingBottom: '5px' }}
          />
          <TextField
            type="password"
            label={t('loginForm.passwordPlaceholder')}
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
          />
        </S.FormWrapper>
        <S.ConfirmButton
          type="submit"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          {t('loginForm.loginButton')}
        </S.ConfirmButton>
        <S.QuestionContainer>
          <S.Question>
            {t('loginForm.noAccountYet')}&nbsp;
            <S.ActionContainer to="/register">
              {t('loginForm.register')}
            </S.ActionContainer>
          </S.Question>
        </S.QuestionContainer>
      </S.Container>
      <MobileLoginRegisterPart />
    </S.Wrapper>
  );
};

export default MobileLoginForm;
