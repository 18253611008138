import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import colorTheme from '../../../colorTheme';

export const CustomSelect = muiStyled(Select)({
  width: '192px !important',
  '@media(min-width: 352px)': {
    width: '224px !important',
  },
  borderRadius: '4px',
  height: '2rem',
  backgroundColor: colorTheme.white,
  margin: '0 3rem 0 0',
  '& .MuiSelect': {
    border: '2px slid red',
  },
});

export const PaginationSelect = muiStyled(Select)({
  width: '164px !important',
  borderRadius: '4px',
  height: '2rem',
  backgroundColor: colorTheme.white,
  margin: '0',
  '& .MuiSelect': {
    border: '2px slid red',
  },
});

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 1rem;
  height: 1rem;
  margin: 0 0.75rem 0 0;
`;

export const Label = styled.p<{ color?: string }>`
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  color: ${({ color, theme: { mainBlue } }) => (color ? color : mainBlue)};
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
