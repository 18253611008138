import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/authentication/LogoBlue.png';
import MobileLoginRegisterPart from '../MobileLoginRegisterPart';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import LanguageModal from '../../../common/LanguageModal/LanguageModal';
import { passwordReset } from '../../../redux/auth/actions';
import { useDispatch } from 'react-redux';
import * as S from './MobileRemindPasswordForm.styles';

const MobileRemindPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);
  const [email, setEmail] = useState('');
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(passwordReset(email, selectedLanguage, t));
  };

  const isFormValid = email;
  return (
    <S.Wrapper>
      <S.Container>
        <S.HeaderWrapper>
          <S.LogoWrapper>
            <NavLink to="/">
              <S.Logo src={Logo}></S.Logo>
            </NavLink>
          </S.LogoWrapper>
          <S.LanguagePickWrapper>
            <LanguageModal />
          </S.LanguagePickWrapper>
        </S.HeaderWrapper>
        <S.FormWrapper>
          <S.Title>{t('remindPasswordForm.remindPassword')}</S.Title>
          <S.Body>{t('remindPasswordForm.body')}</S.Body>
          <TextField
            type="email"
            label={t('remindPasswordForm.email')}
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
          />
        </S.FormWrapper>
        <S.ConfirmButton
          type="submit"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          {t('remindPasswordForm.remindButton')}
        </S.ConfirmButton>
      </S.Container>
      <MobileLoginRegisterPart />
    </S.Wrapper>
  );
};

export default MobileRemindPasswordForm;
