import { useTranslation } from 'react-i18next';
import * as S from './AnalysisFolder.styles';

const AnalysisFolder = () => {
  const { t } = useTranslation();
  return (
    <S.LinkWrapper to={'/submission/new'}>
      <S.PlusIcon />
      <S.PlusLabel>{t('analysis.startNew')}</S.PlusLabel>
    </S.LinkWrapper>
  );
};
export default AnalysisFolder;
