import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import AuthReducer from './auth/authReducer';
import SubmissionReducer from './submission/submissionReducer';
import { AuthReducerState } from './auth/types';
import { SubmissionReducerState } from './submission/types';
// import logger from 'redux-logger';
import { AdminReducerState } from './admin/types';
import AdminReducer from './admin/adminReducer';
import { UtilsReducerState } from './utils/types';
import { UtilsReducer } from './utils';

const rootReducer = combineReducers({
  auth: AuthReducer,
  submission: SubmissionReducer,
  admin: AdminReducer,
  utils: UtilsReducer,
});

export interface AppState {
  auth: AuthReducerState;
  submission: SubmissionReducerState;
  admin: AdminReducerState;
  utils: UtilsReducerState;
}

const store = (preloadedState: AppState) =>
  createStore(rootReducer, preloadedState, applyMiddleware(thunkMiddleware));
// createStore(
//   rootReducer,
//   preloadedState,
//   applyMiddleware(thunkMiddleware, logger)
// );

export default store;
