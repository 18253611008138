import * as A from './actionTypes';
import { AuthReducerState, emptyUser, authReducerInitialState } from './types';

const authReducer = (
  state = authReducerInitialState,
  action: any
): AuthReducerState => {
  switch (action.type) {
    case A.SET_SIGN_IN_ERROR_MESSAGE: {
      return {
        ...state,
        signInErrorMessage: action.data,
      };
    }
    case A.SET_VERIFIED_USER: {
      return {
        ...state,
        user: { ...state.user, ...action.user },
      };
    }
    case A.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        user: {
          ...emptyUser,
          ...action.data.user,
          notifications: action.data.notifications,
        },
      };
    }
    case A.LOGIN_USER_FAILED: {
      return {
        ...state,
        signInErrorMessage: 'errors.loginError',
      };
    }
    case A.LOGIN_USER_LOCKED_OUT: {
      return {
        ...state,
        signInErrorMessage: 'errors.userLockedOut',
      };
    }
    case A.RESET_USER_SIGN_IN_ERROR: {
      return {
        ...state,
        signInErrorMessage: '',
      };
    }
    case A.LOGOUT_USER_SUCCESS: {
      return authReducerInitialState;
    }
    case A.SET_USER_DATA: {
      return {
        ...state,
        user: { ...emptyUser, ...action.data },
        isLoading: false,
      };
    }
    case A.USER_CONTACT_UPDATE_SUCCESS: {
      return {
        ...state,
        user: { ...emptyUser, ...action.data },
      };
    }
    case A.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        emailForPasswordReset: action.email,
      };
    }
    case A.SET_PASSWORD_ERROR_MESSAGE: {
      return {
        ...state,
        changePasswordErrorMessage: action.message,
      };
    }
    case A.SET_LOADING_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.SET_LOADING_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.CONFIRM_ACCOUNT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.CONFIRM_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isAccountConfirmationConfirmed: true,
        isLoading: false,
      };
    }
    case A.CONFIRM_ACCOUNT_FAILED: {
      return {
        ...state,
        isAccountConfirmationConfirmed: false,
        isLoading: false,
      };
    }
    case A.SUBMIT_ACCOUNT_FOR_REMOVAL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.SUBMIT_ACCOUNT_FOR_REMOVAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.SUBMIT_ACCOUNT_FOR_REMOVAL_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
