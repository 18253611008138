import styled from 'styled-components';
import playIcon from '../../assets/common/playIcon.svg';
import { AiOutlineClose } from 'react-icons/ai';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgb(7, 0, 92, 0.2);
  padding: 1rem;
  @media (min-width: 450px) {
    padding: 2rem;
  }
  @media (min-width: 600px) {
    padding: 4rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
`;
export const BodyWrapper = styled.div`
  display: flex;
  position: fixed;
  width: 40vw;
  max-height: 650px;
`;

export const CloseIcon = styled(AiOutlineClose)`
  position: absolute;
  top: -20px;
  right: -20px;
  color: #4e576d;
  border-radius: 50%;
  background: white;
  width: 40px;
  height: 40px;
  &:hover {
    box-shadow: 0 0 10px white;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
`;

export const PlayWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  div {
    width: 100%;
    height: 100%;
  }
  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

export const PlayIcon = styled.img.attrs({
  alt: 'play index',
  src: `${playIcon}`,
})`
  position: absolute;
  z-index: ${({
    theme: {
      zIndexes: { firstPlane },
    },
  }) => firstPlane};
  max-width: 28%;
  @media (min-width: 700px) {
    max-width: 22%;
  }
`;
