import SubmissionNavigation from '../SubmissionNavigation/SubmissionNavigation';
import CreditCard from '../../../assets/steps/paymentSuccess.svg';
// import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as S from './SuccessStep.styles';

export default function SuccessStep() {
  const { t } = useTranslation();
  const history = useHistory();
  const GoBackToStartPanel = () => {
    const path = `/my-analysis`;
    history.push(path);
  };

  // const { submissionId } = useParams() as { submissionId: string }
  // SHOULD BE FETCHING SUB DATA HERE

  return (
    <S.Wrapper>
      <SubmissionNavigation activeStep={3} />
      <S.CardContainer>
        <S.Card src={CreditCard} />
      </S.CardContainer>
      <S.DescriptionContainer>
        <S.Title>{t('steps.payment.success.thanks')}</S.Title>
        <S.Body>{t('steps.payment.success.info')}</S.Body>
      </S.DescriptionContainer>
      <S.ButtonContainer>
        <S.NextStepButton onClick={GoBackToStartPanel}>
          {t('steps.payment.success.goToPanel')}
        </S.NextStepButton>
      </S.ButtonContainer>
    </S.Wrapper>
  );
}
