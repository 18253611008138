import { useState } from 'react';
import Select from '../SelectWithIcon/Select';
import usePagination from '@mui/material/usePagination';
import { useTranslation } from 'react-i18next';
import * as S from './Pagination.styles';

interface IPagination {
  selectedPage: number;
  setSelectedPage: (value: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;
  maxPage: number;
}

const options = [
  {
    label: 'analysis.table.pagination10',
    value: 10,
  },
  {
    label: 'analysis.table.pagination25',
    value: 25,
  },
  {
    label: 'analysis.table.pagination50',
    value: 50,
  },
];

export default function Pagination({
  selectedPage,
  setSelectedPage,
  itemsPerPage,
  setItemsPerPage,
  maxPage,
}: IPagination) {
  const { items } = usePagination({
    count: maxPage + 1,
    page: selectedPage + 1,
  });
  const { t } = useTranslation();
  const [hoverItem, setHoverItem] = useState<undefined | string>();
  const item = (page: number, selected: boolean) => (
    <S.PageButton
      key={`button_${page}`}
      onClick={() => setSelectedPage(page - 1)}
    >
      <S.PageLabel isSelected={selected}>{page}</S.PageLabel>
    </S.PageButton>
  );

  const handleSetItemsPerPage = (value: number) => {
    setItemsPerPage(value);
    setSelectedPage(0);
  };
  const ellipsis = (index: number) => (
    <S.DotsLabel key={`elipsis_${index}`}>...</S.DotsLabel>
  );
  const isPreviousDisabled = selectedPage === 0;
  const previous = () => (
    <S.LeftButton
      onMouseEnter={() => setHoverItem('left')}
      onMouseLeave={() => setHoverItem(undefined)}
      disabled={isPreviousDisabled}
      onClick={() => selectedPage > 0 && setSelectedPage(selectedPage - 1)}
    >
      <S.ArrowLeft
        props={{
          isHover: hoverItem === 'left',
          isDisabled: isPreviousDisabled,
        }}
      />
    </S.LeftButton>
  );
  const isNextDisabled = selectedPage === maxPage - 1;
  const next = () => (
    <S.RightButton
      onMouseEnter={() => setHoverItem('right')}
      onMouseLeave={() => setHoverItem(undefined)}
      disabled={isNextDisabled}
      onClick={() =>
        selectedPage < maxPage && setSelectedPage(selectedPage + 1)
      }
    >
      <S.ArrowRight
        props={{ isHover: hoverItem === 'right', isDisabled: isNextDisabled }}
      />
    </S.RightButton>
  );

  const pages = () =>
    items
      .filter(
        ({ type, page }) =>
          !['previous', 'next'].includes(type) && page <= maxPage
      )
      .map(({ type, page, selected }, index) => {
        if (type === 'page' || type === 'first' || type === 'last') {
          return item(page, selected);
        }
        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          return ellipsis(index);
        }
        return <></>;
      });

  return (
    <S.Wrapper>
      <S.PageSelectorContainer>
        {previous()}
        <S.PagesContainer>{pages()}</S.PagesContainer>
        {next()}
      </S.PageSelectorContainer>
      <S.PerPageContainer>
        <S.Label>{t('analysis.table.pagination')}</S.Label>
        <Select
          action={handleSetItemsPerPage}
          options={options}
          selected={itemsPerPage}
        />
      </S.PerPageContainer>
    </S.Wrapper>
  );
}
