import SubmissionNavigation from '../SubmissionNavigation/SubmissionNavigation';
import CreditCard from '../../../assets/steps/paymentDeclined.svg';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { executePaymentIntent } from '../../../redux/submission/actions';
import { userData } from '../../../redux/auth/selectors';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import * as S from './SuccessStep.styles';
import { useState, useEffect } from 'react';

export default function FailureStep() {
  const { t } = useTranslation();
  const history = useHistory();
  const { submissionId } = useParams() as { submissionId: string };
  const dispatch = useDispatch();
  const { email } = useSelector(userData);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const goBackToStartPanel = () => history.push('/my-analysis');

  const handlePaymentIntent = (e: any) => {
    e.stopPropagation();
    dispatch(
      executePaymentIntent({
        submissionId,
        email,
        locale: selectedLanguage ? selectedLanguage : 'pl',
      })
    );
  };
  return (
    <S.Wrapper>
      <SubmissionNavigation activeStep={3} isFailure />
      <S.CardContainer>
        <S.Card src={CreditCard} />
      </S.CardContainer>
      <S.DescriptionContainer>
        <S.Title>{t('steps.payment.failure.failed')}</S.Title>
        <S.Body>{t('steps.payment.failure.process')}</S.Body>
      </S.DescriptionContainer>
      <S.ButtonContainer>
        <S.NextStepButton onClick={handlePaymentIntent}>
          {t('steps.payment.failure.tryAgain')}
        </S.NextStepButton>
      </S.ButtonContainer>
      <S.NavigateToPanelCTA onClick={goBackToStartPanel}>
        {t('steps.payment.failure.goToPanel')}
      </S.NavigateToPanelCTA>
    </S.Wrapper>
  );
}
