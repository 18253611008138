import { ANALYSIS_STATUS } from '../../../utils/types';
import {
  executePaymentIntent,
  setDrawerSubmissionId,
} from '../../../redux/submission/actions';
import { useDispatch, useSelector } from 'react-redux';
import { userData } from '../../../redux/auth/selectors';
import { getISOFormatDate } from '../../../utils/date';
import { history } from '../../../App';
import { getDownloadUrl } from '../../../api/common-api';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import * as S from './AnalysisFolder.styles';
import { statusesLabelsThemes } from '../../../utils/submission';
import { useEffect, useState } from 'react';

export interface ILabelTheme {
  background: string;
  color: string;
  icon: string;
  message: string;
}

interface IAnalysisItem {
  id: string;
  created_at: string;
  description: string;
  status: ANALYSIS_STATUS;
}

const AnalysisItem = ({
  description,
  status,
  created_at,
  id: submissionId,
}: IAnalysisItem) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector(userData);
  const renderLabel = () => {
    const { background, color, icon, message } = statusesLabelsThemes[status];
    return (
      <S.LabelWrapper backgroundColor={background}>
        <S.LabelIcon src={icon} alt={t(message)} />
        <S.LabelText color={color}>{t(message)}</S.LabelText>
      </S.LabelWrapper>
    );
  };
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const handlePaymentIntent = (e: any) => {
    e.stopPropagation();
    dispatch(
      executePaymentIntent({
        submissionId,
        email,
        locale: selectedLanguage ? selectedLanguage : 'pl',
      })
    );
  };

  const handleSendFiles = (e: any) => {
    e.stopPropagation();
    history.push(`/submission/${submissionId}/dicom`);
  };

  const handleDownloadFiles = async (e: any) => {
    e.stopPropagation();
    const url = await getDownloadUrl(submissionId);
    window.location.assign(url);
  };

  const renderButton = () => {
    switch (status) {
      case ANALYSIS_STATUS.READY: {
        return (
          <S.DownloadButton onClick={(e: any) => handleDownloadFiles(e)}>
            <S.DownloadIcon />
            <S.Label>{t('analysis.statuses.download')}</S.Label>
          </S.DownloadButton>
        );
      }
      case ANALYSIS_STATUS.NO_FILES: {
        return (
          <S.SendFilesButton onClick={(e: any) => handleSendFiles(e)}>
            <S.WhiteLabelContainer>
              <S.WhiteLabel>{t('analysis.statuses.sendFiles')}</S.WhiteLabel>
            </S.WhiteLabelContainer>
          </S.SendFilesButton>
        );
      }
      case ANALYSIS_STATUS.NOT_PAID: {
        return (
          <S.SendFilesButton onClick={(e: any) => handlePaymentIntent(e)}>
            <S.WhiteLabelContainer>
              <S.WhiteLabel>
                {t('analysis.statuses.paymentRequired')}
              </S.WhiteLabel>
            </S.WhiteLabelContainer>
          </S.SendFilesButton>
        );
      }
    }
  };

  const handleSelectSubmission = () =>
    dispatch(setDrawerSubmissionId(submissionId));

  return (
    <S.Wrapper onClick={handleSelectSubmission}>
      <S.DateRow>
        <S.CalendarIcon />
        <S.Date>{getISOFormatDate(created_at, true)}</S.Date>
      </S.DateRow>
      <S.Header>{description}</S.Header>
      {renderLabel()}
      {renderButton()}
    </S.Wrapper>
  );
};

export default AnalysisItem;
