import styled from 'styled-components';

export const LabelWrapper = styled.div<{ backgroundColor: string }>`
  border-radius: 8px;
  height: 26px;
  padding: 0.125rem 0.75rem;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const LabelText = styled.p<{ color: string }>`
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  line-height: 160%;
  color: ${({ color }) => color};
`;

export const LabelIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0;
`;
