import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSortParams, USER_FIELDS } from '../../../redux/admin/adminReducer';
import { userData } from '../../../redux/auth/selectors';
import * as S from './UserTableHeader.styles';

interface IUserTableHeader {
  sortBy: UserSortParams;
  setSortBy: (value: UserSortParams) => void;
}

const UserTableHeader = ({
  sortBy: { field, direction },
  setSortBy,
}: IUserTableHeader) => {
  const { t } = useTranslation();
  const [hoverField, setHoverField] = useState<undefined | USER_FIELDS>();
  const handleSort = (selectedField: USER_FIELDS) => {
    if (selectedField === field && direction === 'asc') {
      return setSortBy({ field: selectedField, direction: 'desc' });
    }
    setSortBy({ field: selectedField, direction: 'asc' });
  };
  const { role } = useSelector(userData);
  const isAdmin = role === 'admin';

  return (
    <S.TableHeaderWrapper>
      <S.EmailContainer>
        <S.SortByButton
          onClick={() => handleSort(USER_FIELDS.EMAIL)}
          onMouseEnter={() => setHoverField(USER_FIELDS.EMAIL)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === USER_FIELDS.EMAIL}>
            {t('userTable.contact')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === USER_FIELDS.EMAIL,
              isUp: field === USER_FIELDS.EMAIL && direction === 'desc',
              isHover: hoverField === USER_FIELDS.EMAIL,
            }}
          />
        </S.SortByButton>
      </S.EmailContainer>
      <S.NameContainer>
        <S.SortByButton
          onClick={() => handleSort(USER_FIELDS.NAME)}
          onMouseEnter={() => setHoverField(USER_FIELDS.NAME)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === USER_FIELDS.NAME}>
            {t('userTable.name')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === USER_FIELDS.NAME,
              isUp: field === USER_FIELDS.NAME && direction === 'desc',
              isHover: hoverField === USER_FIELDS.NAME,
            }}
          />
        </S.SortByButton>
      </S.NameContainer>
      <S.SurnameContainer>
        <S.SortByButton
          onClick={() => handleSort(USER_FIELDS.SURNAME)}
          onMouseEnter={() => setHoverField(USER_FIELDS.SURNAME)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === USER_FIELDS.SURNAME}>
            {t('userTable.surname')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === USER_FIELDS.SURNAME,
              isUp: field === USER_FIELDS.SURNAME && direction === 'desc',
              isHover: hoverField === USER_FIELDS.SURNAME,
            }}
          />
        </S.SortByButton>
      </S.SurnameContainer>
      <S.DateContainer>
        <S.SortByButton
          onClick={() => handleSort(USER_FIELDS.CREATION)}
          onMouseEnter={() => setHoverField(USER_FIELDS.CREATION)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === USER_FIELDS.CREATION}>
            {t('userTable.date')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === USER_FIELDS.CREATION,
              isUp: field === USER_FIELDS.CREATION && direction === 'desc',
              isHover: hoverField === USER_FIELDS.CREATION,
            }}
          />
        </S.SortByButton>
      </S.DateContainer>
      <S.ConfirmContainer>
        <S.SortByButton
          onClick={() => handleSort(USER_FIELDS.CONFIRMED)}
          onMouseEnter={() => setHoverField(USER_FIELDS.CONFIRMED)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === USER_FIELDS.CONFIRMED}>
            {t('userTable.confirmation')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === USER_FIELDS.CONFIRMED,
              isUp: field === USER_FIELDS.CONFIRMED && direction === 'desc',
              isHover: hoverField === USER_FIELDS.CONFIRMED,
            }}
          />
        </S.SortByButton>
      </S.ConfirmContainer>
      <S.ArchivedContainer>
        <S.SortByButton
          onClick={() => handleSort(USER_FIELDS.ARCHIVED)}
          onMouseEnter={() => setHoverField(USER_FIELDS.ARCHIVED)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === USER_FIELDS.ARCHIVED}>
            {t('userTable.archived')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === USER_FIELDS.ARCHIVED,
              isUp: field === USER_FIELDS.ARCHIVED && direction === 'desc',
              isHover: hoverField === USER_FIELDS.ARCHIVED,
            }}
          />
        </S.SortByButton>
      </S.ArchivedContainer>
      <S.LocaleContainer>
        <S.SortByButton
          onClick={() => handleSort(USER_FIELDS.LOCALE)}
          onMouseEnter={() => setHoverField(USER_FIELDS.LOCALE)}
          onMouseLeave={() => setHoverField(undefined)}
        >
          <S.Label isHover={hoverField === USER_FIELDS.LOCALE}>locale</S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === USER_FIELDS.LOCALE,
              isUp: field === USER_FIELDS.LOCALE && direction === 'desc',
              isHover: hoverField === USER_FIELDS.LOCALE,
            }}
          />
        </S.SortByButton>
      </S.LocaleContainer>
      {isAdmin && (
        <S.ActionContainer>
          <S.Label isHover={hoverField === USER_FIELDS.ACTIONS}>
            {t('userTable.actions')}
          </S.Label>
        </S.ActionContainer>
      )}
    </S.TableHeaderWrapper>
  );
};

export default UserTableHeader;
