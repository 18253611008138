import styled from 'styled-components';
import playIcon from '../../assets/common/playIcon.svg';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export const Wrapper = styled.main`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 44px 24px;
  max-width: 664px;
  @media (min-width: 768px) {
    padding: 34px 32px;
  }
  @media (min-width: 1060px) {
    margin: 0;
    max-width: 732px;
    padding: 34px 32px 34px 102px;
  }
`;

export const Header = styled.h2`
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  margin: 0 auto 8px;
  width: 100%;
`;

export const CollapsibleBody = styled.div`
  width: 100%;
  padding: 0 0 16px;
`;

export const MovieContainer = styled.div`
  display: flex;
  padding: 0;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto 32px;
  max-width: 270px;
  min-width: 270px;
  height: 204px;
  @media (min-width: 768px) {
    max-width: 294px;
    min-width: 294px;
    height: 204px;
  }
  @media (min-width: 768px) {
    margin: 0 32px 0 0;
  }
`;

export const MediaBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px auto 0;
  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const MediaBody = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-family: Poppins;
  font-weight: 400;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const PlayWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  div {
    width: 100%;
    height: 100%;
  }
  img {
    border-radius: 8px;
    object-fit: cover;
  }
  &:hover {
    box-shadow: 0px 4px 39px #41bbff;
  }
  margin: 0 auto;
  border-radius: 4px;
`;

export const Image = styled.img`
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
`;

export const Veil = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.17;
  background: linear-gradient(92.52deg, #249acd 14.89%, #1300ff 86.67%);
`;

export const PlayIcon = styled.img.attrs({
  alt: 'play index',
  src: `${playIcon}`,
})`
  position: absolute;
  z-index: ${({
    theme: {
      zIndexes: { firstPlane },
    },
  }) => firstPlane};
  max-width: 28%;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
`;

export const Question = styled.div`
  display: flex;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const ExpandedStatus = styled.div`
  margin: auto 0 auto auto;
`;

export const CollapsibleWrapper = styled.div`
  margin-bottom: 16px;
`;

export const CollapsibleBreak = styled.div`
  border-bottom: 1px solid ${({ theme: { grayBorder } }) => grayBorder};
  margin: 16px 0 0;
`;

export const FaqAnswer = styled.p`
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  color: #07005c;
`;

export const ContactWrapper = styled.div`
  display: flex;
  text-decoration: none;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  align-items: center;
  text-transform: uppercase;
  padding-top: 16px;
`;

export const QuestionMarkIcon = styled(AiOutlineQuestionCircle)`
  width: 28px;
  height: 28px;
  padding-right: 8px;
`;

export const Contact = styled.span`
  display: flex;
`;

export const ContactLink = styled.a`
  display: flex;
  text-decoration: none;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  align-items: center;
  text-transform: uppercase;
  padding-left: 8px;
  text-decoration: underline;
  :hover {
    text-shadow: 0 0 8px ${({ theme: { blue } }) => blue};
  }
`;
