import MenuItem from '@mui/material/MenuItem/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import colorTheme from '../../../colorTheme';
import { useTranslation } from 'react-i18next';
import * as S from './SelectWithLabel.styles';

interface IMenuOption {
  label: string;
  value: any;
}

interface IMenuSelect {
  selected: number;
  options: IMenuOption[];
  action: (value: number) => void;
}

export default function Select({ selected, action, options }: IMenuSelect) {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    action(+value);
  };
  return (
    <S.PaginationSelect
      value={selected}
      onChange={handleChange}
      MenuProps={{
        disableScrollLock: true,
      }}
      renderValue={(currentValue: number) => {
        const selectedOption = options.find(
          ({ value }) => value === currentValue
        );
        if (!selectedOption) {
          return <S.Label>{currentValue}</S.Label>;
        }
        return <S.Label>{t(selectedOption.label)}</S.Label>;
      }}
    >
      {options.map(({ label, value }: IMenuOption, index: number) => {
        return (
          <MenuItem
            key={`item_${index}`}
            value={value}
            sx={{
              background: 'transparent',
              fontSize: 15,
              fontFamily: 'Poppins',
              color: colorTheme.mainBlue,
              fontWeight: value === selected ? 600 : 500,
            }}
          >
            <S.Label>{t(label)}</S.Label>
          </MenuItem>
        );
      })}
    </S.PaginationSelect>
  );
}
