import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '../../hooks/useMediaQuery';
import useTimer from '../../hooks/useTimer';
import { logout } from '../../redux/auth/actions';
import {
  getSessionStartTimestamp,
  resetSessionStartTimestamp,
} from '../../utils/cookies';
import { getFormattedCountdownTimer } from '../../utils/date';
import { useTranslation } from 'react-i18next';
import * as S from './CountdownBar.styles';

export default function CountdownBar() {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const dispatch = useDispatch();
  const [isTimerOn, setIsTimerOn] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const sessionStartTime = getSessionStartTimestamp();
  const [isUserInteraction, setIsUserInteraction] = useState(false);
  const extendTimer = () => {
    resetSessionStartTimestamp();
    setIsUserInteraction(true);
  };

  const { days, hours, seconds, minutes } = useTimer({
    expiryTimestamp: new Date(sessionStartTime),
    onExpire: () => {
      setIsTimerOn(false);
      setIsExpired(true);
    },
  });

  useEffect(() => {
    if (isExpired) {
      dispatch(logout());
    }
    const isInitialTimer = hours === 0 && minutes === 0 && seconds === 0;
    if (isInitialTimer) {
      return;
    }
    const showTimer = hours === 0 && minutes < 5;

    if (isTimerOn && !showTimer) {
      setIsTimerOn(false);
    } else if (!isTimerOn && showTimer) {
      setIsUserInteraction(false);
      setIsTimerOn(true);
    }
  }, [
    sessionStartTime,
    days,
    hours,
    seconds,
    minutes,
    dispatch,
    isExpired,
    isTimerOn,
  ]);

  if (!isTimerOn || isUserInteraction) {
    return null;
  }

  return (
    <S.TimerBar>
      <S.TimeLabel>
        {isDesktop ? t('countdownBar.desktopBody') : t('countdownBar.body')}
      </S.TimeLabel>
      <S.Time>{getFormattedCountdownTimer(minutes, seconds)}</S.Time>
      <S.Extend onClick={extendTimer}>{t('countdownBar.extend')}</S.Extend>
    </S.TimerBar>
  );
}
