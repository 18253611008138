import styled from 'styled-components';
import { BsCloudUpload } from 'react-icons/bs';

export const RowWrapper = styled.div`
  display: flex;
  margin: 0 0 8px;
  word-break: break-word;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  color: #828282;
  font-size: 14px;
  padding: 12px 16px;
`;

export const ValueContainer = styled.div`
  flex: 1;
`;

export const ColumnDivider = styled.div`
  flex: 0.1;
`;

export const TitleContainer = styled.textarea`
  display: flex;
  flex: 1;
  justify-content: left;
  background-color: #f4f5f9;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  min-height: 24px;
`;

export const UploadImageButton = styled.button``;

export const ImageEditTextArea = styled.textarea`
  display: flex;
  flex: 1;
  justify-content: left;
  background-color: #f4f5f9;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  min-height: 24px;
  margin-right: 10px;
`;

export const TypeContainer = styled.span`
  display: flex;
  flex: 0.5;
  justify-content: center;
`;

export const EditImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.a`
  display: flex;
  flex: 1;
  justify-content: left;
  padding: 0 4px;
  text-decoration: none;
  color: rgb(65, 187, 255);
`;

export const UploadedImage = styled.img`
  max-height: 120px;
  max-width: 120px;
  margin-right: 20px;
`;

export const UrlContainer = styled.span`
  display: flex;
  flex: 1;
  justify-content: left;
  padding: 0 4px;
`;

export const LocaleContainer = styled.span`
  display: flex;
  flex: 0.25;
  padding-right: 10px;
  justify-content: flex-start;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UploadIcon = styled(BsCloudUpload)`
  padding-right: 2px;
  width: 32px;
  height: 32px;
  color: #41bbff;
  padding-right: 2px;
  cursor: pointer;
`;
