import JSZip from 'jszip';
import axios, { AxiosResponse } from 'axios';
import {
  IAccountConfirmationParams,
  ICreateSubmissionParams,
  IFileWithPath,
  ILoginParams,
  INewPaymentParams,
  IRegisterParams,
  ISingleFileUpload,
  IUpdateImageAlt,
  IUploadSubmissionFilesParams,
} from './types';
import { emptyUser } from '../redux/auth/types';
import { UPLOAD_TYPE } from '../redux/submission/actions';

const PORT = process.env.REACT_APP_SERVER_PORT
  ? `:${process.env.REACT_APP_SERVER_PORT}`
  : '';
export const BASE_URL = process.env.REACT_APP_SERVER_IP
  ? `${process.env.REACT_APP_SERVER_IP}${PORT}/api/v1`
  : 'http://localhost:8080/api/v1';
export const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axios.defaults.withCredentials = true;

// export const register = async (params: { email: string; password: string }): Promise<any> => {
//   const res = await instance.post(`/auth/register`, { ...params });
//   return res.data;
// };

export const checkLoggedIn = async () => {
  try {
    const res = await instance.get(`/users/session`);

    const { notifications } = res.data;

    const orderedNotification = notifications.sort(
      (a: any, b: any) => +new Date(b.created_at) - +new Date(a.created_at)
    );
    const preloadedState = {
      auth: {
        user: {
          ...emptyUser,
          ...res.data.user,
          notifications: orderedNotification,
        },
        loggedIn: true,
      },
    };
    return preloadedState;
  } catch (e) {
    const preloadedState = {
      auth: {
        user: {
          ...emptyUser,
        },
        loggedIn: false,
      },
    };
    return preloadedState;
  }
};

export const logout = async (): Promise<any> => {
  const res = await instance.delete(`/users/session`);
  return res.data;
};

export const login = async (loginParams: ILoginParams): Promise<any> => {
  const res = await instance.post('/users/login', loginParams);
  return res.data;
};

export const register = async (
  registerParams: IRegisterParams
): Promise<any> => {
  const res = await instance.post('/users', registerParams);
  return res.data;
};

export const confirmRegistration = async ({
  token,
}: IAccountConfirmationParams): Promise<any> => {
  const res = await instance.post(`/users/confirmation/${token}`);
  return res.data;
};

export const resetPassword = async (
  email: string,
  language: string
): Promise<any> => {
  const res = await instance.post(`/users/password-reset`, { email, language });
  return res.data;
};

export const confirmPasswordReset = async (
  password: string,
  language: string,
  validationCode: string
): Promise<any> => {
  const res = await instance.post(`/users/password-reset/${validationCode}`, {
    password,
    language,
  });
  return res.data;
};

export const updateUsersPassword = async (params: {
  password: string;
  newPassword: string;
}): Promise<any> => {
  const res = await instance.post(`/users/password-update`, { ...params });
  return res.data;
};

export const createSubmission = async (
  createSubmissionParams: ICreateSubmissionParams
): Promise<AxiosResponse<{ id: string }>> => {
  const res = await instance.post('/submission', createSubmissionParams);
  return res.data;
};

export const uploadFile = async ({
  id,
  file,
  onUploadProgress,
  fileName,
}: ISingleFileUpload): Promise<AxiosResponse<never>> => {
  const formData = new FormData();
  fileName
    ? formData.append(file.path, file, fileName)
    : formData.append(file.path, file);
  formData.append('size', String(file.size));
  const res = await instance.post(`/submission/${id}`, formData, {
    onDownloadProgress: onUploadProgress,
  });
  return res.data;
};

export const getFile = async (submissionId: string, id: string) => {
  const res = await instance({
    url: `/submission/${submissionId}/files/${id}`,
    method: 'GET',
    responseType: 'blob',
  });
  return window.URL.createObjectURL(new Blob([res.data]));
};

export const fetchSubmissions = async () => {
  const res = await instance.get(`/submission`);
  return res.data;
};

export const fetchSubmission = async (id: string) => {
  const res = await instance.get(`/submission/${id}`);
  return res.data;
};

export const createPaymentsSession = async (
  newPaymentParams: INewPaymentParams
): Promise<{ id: string }> => {
  const res = await instance
    .post(`/payment/new`, newPaymentParams)
    .catch((e) => {
      return { data: {} };
    });
  return res.data;
};

export const uploadFilesQueued = async (
  params: IUploadSubmissionFilesParams,
  onPercentageIncrease: (percentage: number) => void
) => {
  const { submissionId, files, onUploadCompleted } = params;
  const maxFilesCount = files.length;
  let currentFileIndex = 0;
  try {
    const fn = (file: IFileWithPath) =>
      uploadFile({
        id: submissionId,
        file,
        onUploadProgress: (progressEvent) => {
          if (progressEvent.loaded === progressEvent.total) {
            currentFileIndex = currentFileIndex + 1;
            let percentCompleted = Math.floor(
              (currentFileIndex * 100) / maxFilesCount
            );
            onPercentageIncrease(percentCompleted);
          }
        },
      });

    var actions = files.map(fn);

    await Promise.all(actions);
    onUploadCompleted && (await onUploadCompleted());
  } catch {}
};

export const uploadFilesAsZip = async (
  params: IUploadSubmissionFilesParams,
  onPercentageIncrease: (percentage: number, type: UPLOAD_TYPE) => void
) => {
  const { isResults, files, submissionId, onUploadCompleted } = params;
  let percentage = 0;

  try {
    const zip = new JSZip();

    const lengthMax = files.length;
    for (let i = 0; i < lengthMax; i++) {
      const f = files[i];
      zip.file(f.path.replace(/^\/|\/$/g, ''), f, { createFolders: true });
      let percentCompleted = Math.floor(((i + 1) * 100) / lengthMax);
      percentage = percentCompleted;
      onPercentageIncrease(percentage, UPLOAD_TYPE.ZIP);
    }

    // const fileName = `Pliki_${submissionId}_dane.zip`;
    const fileName = `Rework_${submissionId}_dane.zip`;

    const contentType = 'application/zip';
    const options = {
      params: {
        Key: fileName,
        ContentType: contentType,
      },
      headers: {
        'Content-Type': contentType,
      },
    };
    const uploadUrlData = await getUploadUrl(submissionId, fileName, options);
    const { putURL } = uploadUrlData;
    const zippedFile = await zip.generateAsync({ type: 'blob' }, (metadata) => {
      percentage = Math.floor(metadata.percent);
    });
    await uploadToSignedUrl({
      url: putURL,
      file: zippedFile,
      options: {
        headers: { 'Content-Type': 'application/zip' },
      },
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        percentage = percentCompleted;
        onPercentageIncrease(percentage, UPLOAD_TYPE.UPLOAD);
      },
    });
    await storeUploadedZipLocation(
      submissionId,
      fileName,
      zippedFile.size,
      lengthMax,
      isResults
    );
    // this.isLoading.upload = false
    // this.upload.finished = true
    onUploadCompleted && (await onUploadCompleted());
  } catch (e: any) {
    console.log({ e });
  } finally {
  }
};

const getUploadUrl = async (
  submissionId: string,
  fileName: string,
  options: any
): Promise<{ putURL: string }> => {
  const res = await instance.get(
    `/submission/signedUrl/${submissionId}`,
    options
  );
  return res.data;
};

const uploadToSignedUrl = async ({
  url,
  file,
  onUploadProgress,
}: any): Promise<AxiosResponse<never>> => {
  const res = await instance.put(url, file, {
    headers: { 'Content-Type': 'application/zip' },
    onUploadProgress,
  });
  return res.data;
};

const storeUploadedZipLocation = async (
  submissionId: string,
  fname: string,
  size: number,
  numberOfFiles: number,
  isResults?: boolean
): Promise<AxiosResponse<{ id: string }>> => {
  const res = await instance.post(`/submission/signedUrl/${submissionId}`, {
    fname,
    isResults,
    size,
    numberOfFiles,
  });
  return res.data;
};

export const markNotificationAsRead = async (
  notificationId: string
): Promise<any> => {
  const res = await instance.post(`/notification/read/${notificationId}`);
  return res.data;
};

export const getDownloadUrl = async (submissionId: string): Promise<any> => {
  const res = await instance.get(
    `/submission/signedUrl/download/${submissionId}`
  );
  return res.data;
};

export const markSubmissionAsArchived = async (
  submissionId: string
): Promise<any> => {
  const res = await instance.delete(`/submission/${submissionId}`);
  return res.data;
};

export const restoreArchivedSubmission = async (
  submissionId: string
): Promise<any> => {
  const res = await instance.post(`/submission/restore/${submissionId}`);
  return res.data;
};

export const destroyArchivedSubmission = async (
  submissionId: string
): Promise<any> => {
  const res = await instance.post(`/submission/destroy/${submissionId}`);
  return res.data;
};

export const reminderSubmissionPayment = async (
  submissionId: string
): Promise<any> => {
  const res = await instance.post(
    `/submission/reminder/payment/${submissionId}`
  );
  return res.data;
};

export const reminderSubmissionFiles = async (
  submissionId: string
): Promise<any> => {
  const res = await instance.post(`/submission/reminder/files/${submissionId}`);
  return res.data;
};

export const archiveOwnSubmission = async (
  submissionId: string
): Promise<any> => {
  const res = await instance.post(`/submission/archive/${submissionId}`);
  return res.data;
};

export const submitOwnAccountForRemoval = async (): Promise<any> => {
  const res = await instance.post(`/users/resign`);
  return res.data;
};

export const archiveUser = async (userId: string): Promise<any> => {
  const res = await instance.post(`/users/archive/${userId}`);
  return res.data;
};

export const unarchiveUser = async (userId: string): Promise<any> => {
  const res = await instance.post(`/users/unarchive/${userId}`);
  return res.data;
};

export const getAllUsers = async (): Promise<any> => {
  const res = await instance.get(`/users`);
  return res.data;
};

export const getAllImageAlts = async (): Promise<any> => {
  const res = await instance.get(`/seo/imageAlt`);
  return res.data;
};

export const getAllOgTags = async (): Promise<any> => {
  const res = await instance.get(`/seo/ogTag`);
  return res.data;
};

export const updateOgImage = async (id: number, form: any): Promise<any> => {
  const res = await instance.post(`/seo/ogTag-image/${id}`, form);
  return res.data;
};

export const updateImageAlt = async (
  id: number,
  params: IUpdateImageAlt
): Promise<any> => {
  const res = await instance.patch(`/seo/imageAlt/${id}`, { ...params });
  return res.data;
};

export const sendTosChangedEmail = async (): Promise<any> => {
  const res = await instance.post(`/users/tos/changed`);
  return res.data;
};

export const sendPrivacyChangedEmail = async (): Promise<any> => {
  const res = await instance.post(`/users/privacy/changed`);
  return res.data;
};
