import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme: { gray } }) => gray};
`;

export const Body = styled.div<{ isTablePage?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  min-height: ${({ isTablePage }) =>
    isTablePage ? 'calc(100vh - 172px)' : 'calc(100vh - 96px)'};
  @media (min-width: 1024px) {
    min-height: calc(100vh - 96px);
  }
`;

export const SendAnalysisButton = styled.button<{ isSideMenu?: boolean }>`
  font-size: ${({ isSideMenu }) => (isSideMenu ? '10px' : '15px')};
  font-size: 15px;
  line-height: 22.5px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  color: ${({ theme: { white } }) => white};
  background: linear-gradient(to right, #41bbff, #086ee7);
  padding: 12.5px 8px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 20px #41bbff;
  }
`;

export const SideMenuWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  flex-grow: 1;
  min-width: 274px;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${({ theme: { white } }) => white};
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 21px;
  box-sizing: border-box;
`;

export const LogoutButton = styled.button<{ isSideMenu?: boolean }>`
  font-size: ${({ isSideMenu }) => (isSideMenu ? '10px' : '15px')};
  font-size: 15px;
  line-height: 22.5px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  color: #41bbff;
  background: white;
  border: 3px solid #41bbff;
  padding: 12.5px 8px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: 250ms;
  &:hover {
    box-shadow: 0px 4px 11px #41bbff;
  }
`;
