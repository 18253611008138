// import { getISOFormatDate } from '../../../utils/date';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateImageAlt } from '../../../../redux/admin/actions';
import { t } from 'i18next';
import * as S from './SEOTableImagesRow.styles';

export interface ISEOImageAlt {
  image_title: string;
  alt: string;
  id: number;
}

const SEOTableImagesRow = ({ image_title, alt, id }: ISEOImageAlt) => {
  useEffect(() => {
    setCurrentImageTitle(image_title);
    setCurrentImageAlt(alt);
    setIsEdit(false);
  }, [image_title, alt, id]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentImageTitle, setCurrentImageTitle] = useState(image_title);
  const [currentImageAlt, setCurrentImageAlt] = useState(alt);

  const dispatch = useDispatch();

  const handleSave = () => {
    setIsEdit(false);
    const data = {
      image_title: currentImageTitle,
      alt: currentImageAlt,
    };
    dispatch(updateImageAlt(id, data));
  };

  const handleAction = () => {
    if (!isEdit) {
      setIsEdit(true);
    } else {
      handleSave();
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setCurrentImageTitle(image_title);
    setCurrentImageAlt(alt);
  };

  const actionText = isEdit ? t('seo.action.save') : t('seo.action.edit');

  return (
    <S.RowWrapper>
      {isEdit ? (
        <S.TitleContainer
          value={currentImageTitle}
          onChange={(e) => setCurrentImageTitle(e.target.value)}
        />
      ) : (
        <S.ValueContainer>{image_title}</S.ValueContainer>
      )}
      {isEdit ? (
        <S.AltContainer
          value={currentImageAlt}
          onChange={(e) => setCurrentImageAlt(e.target.value)}
        />
      ) : (
        <S.ValueContainer>{alt}</S.ValueContainer>
      )}
      <S.ActionContainer>
        <Button onClick={handleAction}>{actionText}</Button>
        {isEdit && (
          <Button onClick={handleCancel}>{t('seo.action.cancel')}</Button>
        )}
      </S.ActionContainer>
    </S.RowWrapper>
  );
};

export default SEOTableImagesRow;
