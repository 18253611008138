import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userData } from '../../../redux/auth/selectors';
import { archiveUser, unarchiveUser } from '../../../redux/admin/actions';
import { getISOFormatDate } from '../../../utils/date';
import pl from '../../../assets/common/flag-PL-160.png';
import us from '../../../assets/common/flag-USA-160.png';
import de from '../../../assets/common/flag-DE-160.png';
import * as S from './UserTableRow.styles';

export interface IUserTableRow {
  name: string;
  surname: string;
  email: string;
  created_at: string;
  isAccountConfirmed: boolean;
  isArchived: boolean;
  count: number;
  phoneNumber: string;
  id: string;
  registeredLocale: string;
}

const getFlagSrc = (locale: string) => {
  if (locale === 'en') {
    return us;
  } else if (locale === 'de') {
    return de;
  } else {
    return pl;
  }
};

const UserTableRow = ({
  id,
  name,
  surname,
  email,
  created_at,
  isAccountConfirmed,
  isArchived,
  phoneNumber,
  registeredLocale,
}: IUserTableRow) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formattedDate = getISOFormatDate(created_at);
  const dateVals = formattedDate.split(', ');
  const [isTooltipOn, setIsTooltipOn] = useState(false);
  const formattedDay = dateVals[0];

  const handleArchiveUser = () => {
    isArchived ? dispatch(unarchiveUser(id)) : dispatch(archiveUser(id));
  };
  const { role, id: currentUserId } = useSelector(userData);
  const isAdmin = role === 'admin';
  const isActionAvailable = isAdmin && id !== currentUserId;

  return (
    <S.RowWrapper>
      <S.EmailContainer>
        <S.ContactContainer>
          <div>{email}</div>
          <div>{phoneNumber}</div>
        </S.ContactContainer>
      </S.EmailContainer>
      <S.NameContainer>{name}</S.NameContainer>
      <S.SurnameContainer>{surname}</S.SurnameContainer>
      <S.DateContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {formattedDay}
        </div>
      </S.DateContainer>
      <S.ConfirmContainer>
        {isAccountConfirmed ? <S.TrueIcon /> : <S.FalseIcon />}
      </S.ConfirmContainer>
      <S.ArchivedContainer>
        {isArchived ? <S.TrueIcon /> : <S.FalseIcon />}
      </S.ArchivedContainer>
      <S.LocaleContainer>
        <img src={getFlagSrc(registeredLocale)} />
      </S.LocaleContainer>
      {isAdmin && (
        <S.RemoveButton
          onMouseOver={() => setIsTooltipOn(true)}
          onMouseOut={() => setIsTooltipOn(false)}
          onClick={handleArchiveUser}
          disabled={!isActionAvailable}
        >
          {isTooltipOn && isArchived && (
            <S.UnarchiveTooltip>
              {t('analysis.table.restore')}
            </S.UnarchiveTooltip>
          )}
          {isActionAvailable && isArchived && <S.RestoreIcon />}
          {isTooltipOn && !isArchived && (
            <S.ArchiveTooltip>
              {t('analysis.table.archiveUser')}
            </S.ArchiveTooltip>
          )}
          {isActionAvailable && !isArchived && <S.TrashIcon />}
        </S.RemoveButton>
      )}
    </S.RowWrapper>
  );
};

export default UserTableRow;
