import * as A from './actionTypes';
import * as api from '../../api/common-api';
import { toast } from 'react-toastify';
import { SEO_IMAGES_FIELD, SEO_TAGS_FIELD, USER_FIELDS } from './adminReducer';
import { IUpdateImageAlt } from '../../api/types';

export function fetchUsers() {
  return async function (dispatch: any) {
    dispatch({ type: A.FETCH_USERS });
    return api.getAllUsers().then(
      (data: any) => {
        dispatch({ type: A.FETCH_USERS_SUCCESS, data });
        dispatch({
          type: A.SORT_USERS_TABLE,
          data: { direction: 'desc', field: 'creation' },
        });
      },
      (error: any) => {
        dispatch({ type: A.FETCH_USERS_FAIL });
        console.log('An error occurred.', error);
      }
    );
  };
}

export interface ISearchUserParams {
  query?: string;
  perPage?: number;
  page?: number;
}

export const setSearchUsersParams = (data: ISearchUserParams) => ({
  type: A.SET_SEARCH_USERS_PARAMS,
  data,
});

export const setSearchSeoParams = (data: ISearchUserParams) => ({
  type: A.SET_SEARCH_SEO_PARAMS,
  data,
});

export const resetSearchParams = (data: ISearchUserParams) => ({
  type: A.RESET_SEARCH_PARAMS,
  data,
});

export const setUserListSort = (
  direction: 'desc' | 'asc',
  field: USER_FIELDS
) => ({
  type: A.SORT_USERS_TABLE,
  data: { direction, field },
});

export const setSEOTagsListSort = (
  direction: 'desc' | 'asc',
  field: SEO_TAGS_FIELD
) => ({
  type: A.SORT_SEO_TAGS_TABLE,
  data: { direction, field },
});

export const setSEOImagesListSort = (
  direction: 'desc' | 'asc',
  field: SEO_IMAGES_FIELD
) => ({
  type: A.SORT_SEO_IMAGES_TABLE,
  data: { direction, field },
});

export function fetchImageAlts() {
  return async function (dispatch: any) {
    dispatch({ type: A.FETCH_IMAGE_ALTS });
    return api.getAllImageAlts().then(
      (data: any) => {
        dispatch({ type: A.FETCH_IMAGE_ALTS_SUCCESS, data });
      },
      (error: any) => {
        dispatch({ type: A.FETCH_IMAGE_ALTS_FAIL });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function fetchOgTags() {
  return async function (dispatch: any) {
    dispatch({ type: A.FETCH_OG_TAGS });
    return api.getAllOgTags().then(
      (data: any) => {
        dispatch({ type: A.FETCH_OG_TAGS_SUCCESS, data });
      },
      (error: any) => {
        dispatch({ type: A.FETCH_OG_TAGS_FAIL });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function updateImageAlt(id: number, dataToUpdate: IUpdateImageAlt) {
  return async function (dispatch: any) {
    dispatch({ type: A.UPDATE_IMAGE_ALTS });
    return api.updateImageAlt(id, dataToUpdate).then(
      () => {
        dispatch({
          type: A.UPDATE_IMAGE_ALTS_SUCCESS,
          data: { id, ...dataToUpdate },
        });
      },
      (error: any) => {
        dispatch({ type: A.UPDATE_IMAGE_ALTS_FAILED });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function updateOgImage(id: number, dataToUpdate: any) {
  const formData = new FormData();
  const { picture, title, description } = dataToUpdate;
  if (picture) {
    formData.append(picture.path, picture, picture.fileName);
  }
  formData.append('title', title);
  formData.append('description', description);
  return async function (dispatch: any) {
    dispatch({ type: A.UPDATE_OG_IMAGE });
    return api.updateOgImage(id, formData).then(
      (data) => {
        dispatch({ type: A.UPDATE_OG_IMAGE_SUCCESS, data });
      },
      (error: any) => {
        dispatch({ type: A.UPDATE_OG_IMAGE_FAILED });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function archiveUser(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.ARCHIVE_USER });
    return api.archiveUser(id).then(
      (data: any) => {
        dispatch({ type: A.ARCHIVE_USER_SUCCESS, data: { id } });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function unarchiveUser(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.UNARCHIVE_USER });
    return api.unarchiveUser(id).then(
      (data: any) => {
        dispatch({ type: A.UNARCHIVE_USER_SUCCESS, data: { id } });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function sendTosChangedEmail() {
  return async function (dispatch: any) {
    dispatch({ type: A.SEND_TOS_CHANGED_MAIL });
    return api.sendTosChangedEmail().then(
      () => {
        dispatch({ type: A.SEND_TOS_CHANGED_MAIL_SUCCESS });
        toast.success('Wiadomość wysłana');
      },
      (error: any) => {
        dispatch({ type: A.SEND_TOS_CHANGED_MAIL_FAILED });
        toast.warning('Nie udało się wysłać wiadomości');
        console.log('An error occurred.', error);
      }
    );
  };
}

export function sendPrivacyChangedEmail() {
  return async function (dispatch: any) {
    dispatch({ type: A.SEND_PRIVACY_CHANGED_MAIL });
    return api.sendPrivacyChangedEmail().then(
      () => {
        dispatch({ type: A.SEND_PRIVACY_CHANGED_MAIL_SUCCESS });
        toast.success('Wiadomość wysłana');
      },
      (error: any) => {
        dispatch({ type: A.SEND_PRIVACY_CHANGED_MAIL_FAILED });
        toast.warning('Nie udało się wysłać wiadomości');
        console.log('An error occurred.', error);
      }
    );
  };
}
