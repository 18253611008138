import * as A from './actionTypes';
import * as api from '../../api/common-api';
import { User } from './types';
import { history } from '../../App';
import { ILoginParams, IRegisterParams } from '../../api/types';
import { toast } from 'react-toastify';
import {
  removeSharedCookie,
  resetSessionStartTimestamp,
  setSharedCookie,
} from '../../utils/cookies';

export const resetSignInError = () => ({
  type: A.RESET_USER_SIGN_IN_ERROR,
});

export const setUserData = (data: User) => ({
  type: A.SET_USER_DATA,
  data,
});

export const setSignInErrorMessage = (message: string) => ({
  type: A.SET_SIGN_IN_ERROR_MESSAGE,
  data: message,
});

export const setIsLoadingData = () => ({
  type: A.SET_LOADING_DATA,
});

export const setIsLoadingDataSuccess = () => ({
  type: A.SET_LOADING_DATA_SUCCESS,
});

export function register(data: IRegisterParams, t: any) {
  return async () => {
    await toast
      .promise(api.register(data), {
        pending: t('errors.registerPending'),
        success: t('errors.registerSuccess'),
        error: {
          render({ data }: any) {
            if (data?.response?.status === 409) {
              return t('errors.registerError1');
            }
            return t('errors.registerError2');
          },
        },
      })
      .then(
        (_response: any) => {
          history.push('/');
        },
        (_error: any) => {}
      );
  };
}

export function passwordReset(email: string, language: string, t: any) {
  return async function (dispatch: any) {
    dispatch({ type: A.SUBMIT_ACCOUNT_PASSWORD_RESET });
    return api.resetPassword(email, language).then(
      () => {
        dispatch({ type: A.SUBMIT_ACCOUNT_PASSWORD_RESET_SUCCESS });
        toast.success(t('settings.resetPasswordSuccess'));
        history.push('/');
      },
      () => {
        dispatch({ type: A.SUBMIT_ACCOUNT_PASSWORD_RESET_FAILED });
        toast.info(t('settings.resetPasswordFailed'));
      }
    );
  };
}

export function confirmPasswordReset(
  password: string,
  language: string,
  validationCode: string,
  t: any
) {
  return async function (dispatch: any) {
    dispatch({ type: A.SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION });
    return api.confirmPasswordReset(password, language, validationCode).then(
      () => {
        dispatch({
          type: A.SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION_SUCCESS,
        });
        toast.success(t('settings.confirmPasswordChangeSuccess'));
        history.push('/');
      },
      () => {
        dispatch({
          type: A.SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION_FAILURE,
        });
        toast.info(t('settings.confirmPasswordChangeFailure'));
      }
    );
  };
}

export function login(params: ILoginParams) {
  return async function (dispatch: any) {
    return api.login(params).then(
      (response: any) => {
        dispatch({ type: A.LOGIN_USER_SUCCESS, data: response });
        resetSessionStartTimestamp();
        setSharedCookie(response);
        history.push('/');
      },
      (_error: any) => {
        const errorCode = _error?.response?.status;
        if (errorCode === 403) {
          dispatch({ type: A.LOGIN_USER_LOCKED_OUT });
        } else {
          dispatch({ type: A.LOGIN_USER_FAILED });
        }
      }
    );
  };
}

export function logout() {
  return async function (dispatch: any) {
    return api.logout().then(
      (response: any) => {
        dispatch({ type: A.LOGOUT_USER_SUCCESS, data: response });
        removeSharedCookie();
        history.push('/login');
      },
      () => {
        console.log('An error occurred.');
        history.push('/login');
      }
    );
  };
}

export function changeUsersPassword(
  password: string,
  newPassword: string,
  t: any
) {
  return async function (dispatch: any) {
    return api.updateUsersPassword({ password, newPassword }).then(
      (response: any) => {
        dispatch({ type: A.CHANGE_PASSWORD_SUCCESS, data: response });
        toast.info(t('settings.changePasswordSuccess'));
        history.push('/');
      },
      (error: any) => console.log('An error occurred.')
    );
  };
}

export function markNotificationAsRead(notificationId: string) {
  return async function (dispatch: any) {
    return api.markNotificationAsRead(notificationId).then(
      (response: any) => {
        dispatch({ type: A.MARK_NOTIFICATION_AS_READ_SUCCESS, data: response });
      },
      (error: any) => console.log('An error occurred.')
    );
  };
}

export function submitOwnAccountForRemoval(t: any) {
  return async function (dispatch: any) {
    dispatch({ type: A.SUBMIT_ACCOUNT_FOR_REMOVAL });
    return api.submitOwnAccountForRemoval().then(
      (data: any) => {
        dispatch({ type: A.SUBMIT_ACCOUNT_FOR_REMOVAL_SUCCESS });
        dispatch(logout());
        toast.success(t('settings.deleteAccountSuccess'));
      },
      (error: any) => {
        dispatch({ type: A.SUBMIT_ACCOUNT_FOR_REMOVAL_FAILED });
        if (error?.response?.status === 405) {
          toast.info(t('settings.deleteNotPossible'));
        } else {
          toast.info(t('settings.deleteAccountFailure'));
        }
      }
    );
  };
}
