export enum MODAL_NAME {
  NOTIFICATION_BELL = 'NOTIFICATION_BELL',
  AVATAR_MENU = 'AVATAR_MENU',
}

export interface IModalState {
  name: MODAL_NAME;
  isOpen: boolean;
}

export const initialModalActiveStates = [
  { name: MODAL_NAME.NOTIFICATION_BELL, isOpen: false },
  { name: MODAL_NAME.AVATAR_MENU, isOpen: false },
];

export const utilsReducerInitialState: UtilsReducerState = {
  modalsActiveStates: initialModalActiveStates,
};

export interface UtilsReducerState {
  modalsActiveStates: IModalState[];
}
