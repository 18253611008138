import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 36px;
`;

export const Header = styled.h2`
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  margin: 0 0 2.25rem;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme: { grayFont } }) => grayFont};
  justify-content: space-around;
`;

export const MenuItem = styled.button<{ isSelected: boolean }>`
  display: flex;
  background-color: transparent;
  padding: 0 0 0.5rem;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  border-bottom: ${({ isSelected, theme: { blue } }) =>
    isSelected ? `4px solid ${blue}` : `4px solid transparent`};
  transition: 250ms;
  margin: 0 0.5rem 0 0;
  @media (min-width: 860px) {
    margin: 0 1.5rem 0 0;
  }
  @media (min-width: 1024px) {
    margin: 0 0.5rem 0 0;
  }
  @media (min-width: 1260px) {
    margin: 0 1.5rem 0 0;
  }
`;

export const Counter = styled.div<{ isSelected: boolean }>`
  width: 16px;
  min-width: 14px;
  height: 16px;
  border-radius: 100%;
  background-color: ${({ isSelected, theme: { grayInfo, grayFont } }) =>
    isSelected ? grayInfo : grayFont};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0.25rem;
`;

export const CounterValue = styled.p`
display: flex;
  font-family: Poppins;
  font-weight: 600;
  font-size: 11px;
  line-height: 120%;
  margin: 0
  width: 13px;
  height: 12px;
  text-align: center;
  color: ${({ theme: { white } }) => white};
`;

export const Label = styled.p<{ isSelected: boolean }>`
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
  color: ${({ isSelected, theme: { mainBlue, grayInfo } }) =>
    isSelected ? mainBlue : grayInfo};
`;

export const SelectLabel = styled.label`
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;
