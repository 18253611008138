import i18n from '../../i18n';
import * as S from './LanguageModal.styles';
import { useCallback, useEffect, useState } from 'react';

interface IFlag {
  lng: string;
  flag: any;
  name: string;
  currency: string;
}

const languagesFlags: IFlag[] = [
  {
    lng: 'pl',
    flag: <S.PlFlag />,
    name: 'Polski',
    currency: 'Polish (PLN)',
  },
  {
    lng: 'en',
    flag: <S.EnFlag />,
    name: 'English/USA',
    currency: 'English (USD)',
  },
  {
    lng: 'de',
    flag: <S.DeFlag />,
    name: 'Deutsch',
    currency: 'German (EUR)',
  },
];

const LanguageModal = () => {
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = (e: any) => {
    setIsModalOpen(!isModalOpen);
  };
  const handleChangeLanguage = (lng: string) => {
    setSelectedLanguage(lng);
    setIsModalOpen(false);
    changeLanguage(lng);
  };
  const renderFlag = useCallback(() => {
    switch (selectedLanguage) {
      case 'pl': {
        return <S.PlFlag />;
      }
      case 'en': {
        return <S.EnFlag />;
      }
      case 'de': {
        return <S.DeFlag />;
      }
      default: {
        return <S.PlFlag />;
      }
    }
  }, [selectedLanguage]);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [renderFlag]);
  const handleClose = () => setIsModalOpen(false);

  return (
    <S.Wrapper onClick={(e) => handleOpenModal(e)}>
      {isModalOpen && (
        <S.LanguagePickerWrapper>
          <S.LanguagePickerContainer onClick={(e) => e.stopPropagation()}>
            <S.CloseContainer onClick={handleClose}>
              <S.CloseIcon />
            </S.CloseContainer>
            {languagesFlags.map((language, index: number) => {
              const { lng, flag, name, currency } = language;
              return (
                <S.LanguageContainer
                  key={index}
                  onClick={() => {
                    handleChangeLanguage(lng);
                  }}
                >
                  <S.FlagWrapper>{flag}</S.FlagWrapper>
                  <S.LanguageInfoContainer>
                    <S.LanguageName>{name}</S.LanguageName>
                    <S.LanguageCurrency>{currency}</S.LanguageCurrency>
                  </S.LanguageInfoContainer>
                </S.LanguageContainer>
              );
            })}
          </S.LanguagePickerContainer>
        </S.LanguagePickerWrapper>
      )}
      {renderFlag()}
    </S.Wrapper>
  );
};
export default LanguageModal;
