import { statusesLabelsThemes } from '../../utils/submission';
import { ANALYSIS_STATUS } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import * as S from './StatusLabel.styles';

interface IStatusLabelProps {
  status: ANALYSIS_STATUS;
}

const StatusLabel = ({ status }: IStatusLabelProps) => {
  const { t } = useTranslation();
  const { background, color, icon, message } = statusesLabelsThemes[status];
  return (
    <S.LabelWrapper backgroundColor={background}>
      <S.LabelIcon src={icon} alt={t(message)} />
      <S.LabelText color={color}>{t(message)}</S.LabelText>
    </S.LabelWrapper>
  );
};

export default StatusLabel;
