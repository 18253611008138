import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { register } from '../../../redux/auth/actions';
import Logo from '../../../assets/authentication/LogoBlue.png';
import MobileLoginRegisterPart from '../MobileLoginRegisterPart';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { toast } from 'react-toastify';
import MuiPhoneNumber from 'material-ui-phone-number';
import * as S from './MobileRegisterForm.styles';
import LanguageModal from '../../../common/LanguageModal/LanguageModal';

const MobileRegisterForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const activeButton = () => (checked ? setChecked(false) : setChecked(true));
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { language } = i18n;
  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (password.length < 10) {
      return toast.info(t('registerForm.minimumLengthInfo'));
    }
    if (password !== repeatPassword) {
      return toast.info(t('registerForm.passwordDontMatch'));
    }
    dispatch(
      register(
        {
          name,
          surname,
          email,
          phoneNumber,
          password,
          language: selectedLanguage ? selectedLanguage : 'pl',
        },
        t
      )
    );
  };

  const isFormValid =
    email &&
    password &&
    name &&
    surname &&
    repeatPassword &&
    phoneNumber &&
    checked;

  return (
    <S.Wrapper>
      <S.Container>
        <S.HeaderWrapper>
          <S.LogoWrapper>
            <NavLink to="/">
              <S.Logo src={Logo}></S.Logo>
            </NavLink>
          </S.LogoWrapper>
          <S.LanguagePickWrapper>
            <LanguageModal />
          </S.LanguagePickWrapper>
        </S.HeaderWrapper>
        <S.FormWrapper>
          <S.Title>{t('registerForm.signUp')}</S.Title>
          <TextField
            label={t('registerForm.firstName')}
            variant="standard"
            onChange={(e) => setName(e.target.value)}
            style={{ paddingBottom: '5px' }}
          />
          <TextField
            label={t('registerForm.lastName')}
            variant="standard"
            onChange={(e) => setSurname(e.target.value)}
            style={{ paddingBottom: '5px' }}
          />
          <TextField
            type="email"
            label={t('registerForm.email')}
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
            style={{ paddingBottom: '5px' }}
          />
          <MuiPhoneNumber
            type={'phone'}
            defaultCountry="pl"
            value={phoneNumber}
            onChange={(e: any) => setPhoneNumber(e)}
            style={{ paddingBottom: '5px', paddingTop: '10px' }}
          />
          <TextField
            label={t('registerForm.password')}
            variant="standard"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ paddingBottom: '5px' }}
          />
          <TextField
            label={t('registerForm.confirmPassowrd')}
            variant="standard"
            type="password"
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
        </S.FormWrapper>
        <S.LabelContainer id="id1">
          <input
            type="checkbox"
            id="id1"
            onClick={activeButton}
            width="100%"
            style={{ cursor: 'pointer' }}
          />
          <S.Info>
            {t('registerForm.haveRead')}{' '}
            <S.LinkContainer href={t('registerForm.tosLink')}>
              {t('registerForm.tos')}
            </S.LinkContainer>{' '}
            {t('registerForm.and')}{' '}
            <S.LinkContainer href={t('registerForm.privacyLink')}>
              {t('registerForm.privacy')}
            </S.LinkContainer>
            .<S.Red>*</S.Red>
          </S.Info>
        </S.LabelContainer>
        <S.LabelContainer id="id2">
          <input
            type="checkbox"
            id="id2"
            width="100%"
            style={{ cursor: 'pointer' }}
          />
          <S.Info>{t('registerForm.agree')}</S.Info>
        </S.LabelContainer>
        <S.ConfirmButton
          type="submit"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          {t('registerForm.signUp')}
        </S.ConfirmButton>
        <S.QuestionContainer>
          <S.Question>
            {t('registerForm.alreadyHaveAnAccount')}
            <S.ActionContainer to="/login">
              {t('registerForm.login')}
            </S.ActionContainer>
          </S.Question>
        </S.QuestionContainer>
      </S.Container>
      <MobileLoginRegisterPart />
    </S.Wrapper>
  );
};

export default MobileRegisterForm;
