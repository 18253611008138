import { ANALYSIS_STATUS } from '../../utils/types';
import { UPLOAD_TYPE } from './actions';

export enum Role {
  USER = 'user',
  OPERATOR = 'operator',
  ADMIN = 'admin',
  SEO = 'seo',
}

export interface IPublicUser {
  id: string;
  email: string;
  role: Role;
  isEulaAccepted: boolean;
  name: string;
  surname: string;
  isAccountConfirmed: boolean;
}

export enum AnalysisStatus {
  ANONIMIZATION = 'anonimization',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
}

export enum SubmissionStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
}

export enum ProcessingState {
  RAW = 'raw',
  PROCESSING = 'processing',
  DONE = 'done',
}

export interface ISubmissionFile {
  id: string;
  created_at: string;
  location: string;
  submission_id: string;
  filename: string;
  encoding: string;
  mimetype: string;
  file_path: string;
  uploaded_to_s3: boolean;
  analysis_status?: AnalysisStatus;
  size: number;
  upload_status: boolean;
  number_of_files: number;
  signedUrl: string;
}

export interface ISubmissionMutableFields {
  upload_state: SubmissionStatus;
  description: string;
}
export interface ISubmission extends ISubmissionMutableFields {
  id: string;
  paid: boolean;
  isArchived: boolean;
  archivedWhen: string;
  created_at: string;
  created_by: string;
  createdBy: IPublicUser;
  referencedBy?: ISubmission[];
  references?: string;
  files?: ISubmissionFile[];
  paymentId?: string;
  processing_state: ProcessingState;
  analysis_status: AnalysisStatus;
  analysisFiles?: ISubmissionFile[];
  email?: string;
  status: ANALYSIS_STATUS;
  isTouchedByOperator: boolean;
  filesUploaded?: {
    resultFiles?: ISubmissionFile[];
    submittedFiles?: ISubmissionFile[];
  };
}

export interface SubmissionReducerState {
  submissionsList: ISubmission[];
  submissionData?: ISubmission;
  submissionCounters: { [key: string]: number };
  upload: {
    filesCount: number;
    currentFile: number;
    currentFilename: string;
    finished: boolean;
  };
  isLoading: { upload: boolean; submissionsList: boolean; isZipping: boolean };
  hasError: { upload: boolean; submissionsList: boolean };
  paymentSessionId: string;
  drawerSubmissionId: string;
  percentage: number;
  uploadPercentage: number;
  uploadType: UPLOAD_TYPE;
  isZipping: boolean;
  search: {
    query: string;
    perPage: number;
    page: number;
    status: ANALYSIS_STATUS;
    dateMin?: string;
    dateMax?: string;
  };
}

export interface User {
  id: string;
  avatar: string;
  email: string;
  name: string;
  surname: string;
  notifications: {
    count: number;
    rows: Notification[];
  };
}

export interface Notification {
  id: string;
  body: string;
  timestamp: Date;
  isRead: boolean;
}
