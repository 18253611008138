import useMediaQuery from '../../../hooks/useMediaQuery';
import { IMenuItemExtended, IMenuWithHeader } from '../../../utils/types';
import MenuItem from './MenuItem';
import MenuSelect from './MenuSelect';
import { useTranslation } from 'react-i18next';
import * as S from './MenuWithHeader.styles';

export default function MenuWithHeader({
  header,
  menuItems,
  selectedStatus,
  handleSelect,
  counters,
}: IMenuWithHeader) {
  const { t } = useTranslation();
  const mobileMenuOptions = menuItems
    .filter((i) => i.counter > 0)
    .map(({ status, label, counter }: IMenuItemExtended) => ({
      value: status,
      label: `${t(label)} (${counters[status]})`,
    }));

  const isTablet = useMediaQuery('(min-width: 768px)');
  return (
    <S.Wrapper>
      <S.Header>{t(header)}</S.Header>
      {isTablet && (
        <S.MenuWrapper>
          {menuItems.map(({ status, label, ...rest }: IMenuItemExtended) => (
            <MenuItem
              {...rest}
              label={t(label)}
              counter={counters[status]}
              isSelected={status === selectedStatus}
              action={() => handleSelect(status)}
              key={status}
            />
          ))}
        </S.MenuWrapper>
      )}
      {!isTablet && (
        <MenuSelect
          action={handleSelect}
          options={mobileMenuOptions}
          selected={selectedStatus}
        />
      )}
    </S.Wrapper>
  );
}
