import sendIcon from '../assets/myAnalysis/clipboard-text.svg';
import notPaidIcon from '../assets/myAnalysis/circle-x.svg';
import readyIcon from '../assets/myAnalysis/circle-checked.svg';
import anonymIcon from '../assets/myAnalysis/eye-no.svg';
import newIcon from '../assets/myAnalysis/atom.svg';
import warningIcon from '../assets/myAnalysis/alert-triangle.svg';
import allIcon from '../assets/myAnalysis/all.svg';
import { ISubmission } from '../redux/submission/types';
import { ANALYSIS_STATUS } from './types';
import { useTranslation } from 'react-i18next';

export const GetActiveStateLabel = (step: number) => {
  const { t } = useTranslation();
  switch (step) {
    case 0: {
      return t('submissionSteps.step1');
    }
    case 1: {
      return t('submissionSteps.step2');
    }
    case 2: {
      return t('submissionSteps.step3');
    }
    case 3: {
      return t('submissionSteps.step4');
    }
    default: {
      return 'missing translation';
    }
  }
};

export const getSubmissionStatus = (
  submission: ISubmission,
  isAdmin: false
): ANALYSIS_STATUS => {
  let finalStatus = ANALYSIS_STATUS.ANY;

  const dicomFiles = submission?.files?.filter((file) =>
    file.filename.includes('.zip')
  );
  const isDicomUploaded = dicomFiles && dicomFiles.length > 0;

  const { analysis_status, paid, isTouchedByOperator } = submission;

  if (paid) {
    if (analysis_status === 'anonimization') {
      finalStatus = ANALYSIS_STATUS.ANONYMIZATION;
    }
    if (analysis_status === 'in_progress') {
      const isNewStateAvailable = isAdmin && !isTouchedByOperator;
      finalStatus = isNewStateAvailable
        ? ANALYSIS_STATUS.NEW_ANALYSIS
        : ANALYSIS_STATUS.SENT;
    }
    if (analysis_status === 'complete') {
      finalStatus = ANALYSIS_STATUS.READY;
    }
  } else {
    if (isDicomUploaded) {
      finalStatus = ANALYSIS_STATUS.NOT_PAID;
    } else {
      finalStatus = ANALYSIS_STATUS.NO_FILES;
    }
  }

  return finalStatus;
};

export const statusesLabelsThemes = {
  [ANALYSIS_STATUS.SENT]: {
    background: '#DDF3FF',
    color: '#2993CD',
    icon: sendIcon,
    message: 'analysis.statuses.sentForAnalysis',
  },
  [ANALYSIS_STATUS.NOT_PAID]: {
    background: '#FFEFF1',
    color: '#D94456',
    icon: notPaidIcon,
    message: 'analysis.statuses.paymentRequired',
  },
  [ANALYSIS_STATUS.READY]: {
    background: '#DCFFF5',
    color: '#0CA479',
    icon: readyIcon,
    message: 'analysis.statuses.complete',
  },
  [ANALYSIS_STATUS.NO_FILES]: {
    background: '#FFEEE4',
    color: '#F2874B',
    icon: warningIcon,
    message: 'analysis.statuses.missingFiles',
  },
  [ANALYSIS_STATUS.NEW_ANALYSIS]: {
    background: '#FFF0D0',
    color: '#D49408',
    icon: newIcon,
    message: 'analysis.statuses.new',
  },
  [ANALYSIS_STATUS.ANONYMIZATION]: {
    background: '#D7D4F9',
    color: '#5852A7',
    icon: anonymIcon,
    message: 'analysis.statuses.anonymization',
  },
  [ANALYSIS_STATUS.ANY]: {
    background: '#fff',
    color: '#000',
    icon: allIcon,
    message: 'analysis.all',
  },
};

export const extractZIPFiles = (
  submission: ISubmission | undefined,
  results?: boolean
) => {
  const ZIP_MIMETYPE = 'application/zip';

  if (!submission || !submission.files) {
    return [];
  }

  const { files, analysisFiles } = submission;

  if (results) {
    return analysisFiles
      ? analysisFiles.filter((f) => f.mimetype === ZIP_MIMETYPE)
      : [];
  } else {
    return files.filter((f) => f.mimetype === ZIP_MIMETYPE);
  }
};

export const extractNonZIPFiles = (
  submission: ISubmission | undefined,
  results?: boolean
) => {
  const ZIP_MIMETYPE = 'application/zip';
  if (!submission || !submission.files) {
    return [];
  }

  const { files, analysisFiles } = submission;

  if (results) {
    return analysisFiles
      ? analysisFiles.filter((f) => f.mimetype !== ZIP_MIMETYPE)
      : [];
  } else {
    return files.filter((f) => f.mimetype !== ZIP_MIMETYPE);
  }
};

export const getSortedSubmissions = (submission: ISubmission[]) => {
  const weekdayOrder = Object.values(ANALYSIS_STATUS);

  const sortedData = submission.sort(
    (a, b) => weekdayOrder.indexOf(a.status) - weekdayOrder.indexOf(b.status)
  );
  return sortedData;
};
