import * as S from './SubmissionNavigation.styles';
import { GetActiveStateLabel } from '../../../utils/submission';
import SubmissionNavigationStep from './SubmissionNavigationStep';
import { ISubmissionNavigationStep } from './SubmissionNavigationStep';

interface ISubmissionNavigation {
  activeStep: number;
  isFailure?: boolean;
  isResults?: boolean;
}

const SubmissionNavigation = ({
  activeStep,
  isFailure,
  isResults,
}: ISubmissionNavigation) => {
  const steps: ISubmissionNavigationStep[] = [];
  const maxSteps = isResults ? 0 : 3;

  for (let i = 0; i <= maxSteps; i++) {
    const newStep = {
      isActive: isFailure ? false : activeStep === i,
      hideLeftSide: i === 0,
      hideRightSide: i === maxSteps,
      isCompleted: i < activeStep,
      order: i,
      label: GetActiveStateLabel(i),
      isFailure: i === 2 && isFailure,
      isGrey: i === 3 && isFailure,
    };
    steps.push(newStep);
  }
  if (steps.length === 0) {
    return null;
  }
  return (
    <S.Container>
      {steps.map((s) => (
        <SubmissionNavigationStep key={s.order} {...s} />
      ))}
    </S.Container>
  );
};

export default SubmissionNavigation;
