import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  word-break: break-word;
  background-color: #f4f5f9;
  overflow-x: scroll;
  min-width: 1200px;
  @media (min-width: 768px) {
    padding: 32px;
  }
`;

export const ResultsContainer = styled.div`
  width: 100%;
  margin: 0 0 32px;
`;

