import styled from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  margin: 0 0 8px;
  word-break: break-word;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  color: #828282;
  font-size: 14px;
  padding: 12px 16px;
`;

export const TitleContainer = styled.textarea`
  display: flex;
  flex: 1;
  justify-content: left;
  background-color: #f4f5f9;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  min-height: 24px;
`;

export const AltContainer = styled.textarea`
  display: flex;
  flex: 1;
  justify-content: left;
  background-color: #f4f5f9;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  min-height: 24px;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex: 1;
`;
