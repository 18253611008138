export const emptyUser: User = {
  id: '',
  email: '',
  name: '',
  avatar: '',
  surname: '',
  notifications: [],
  role: '',
};

export const authReducerInitialState: AuthReducerState = {
  user: emptyUser,
  isLoading: false,
  loggedIn: false,
  signInErrorMessage: '',
  emailForPasswordReset: '',
  changePasswordErrorMessage: '',
  isAccountConfirmationConfirmed: false,
  sessionStartTime: new Date(),
};

export interface AuthReducerState {
  changePasswordErrorMessage: string;
  emailForPasswordReset: string;
  isLoading: boolean;
  loggedIn: boolean;
  signInErrorMessage: string;
  isAccountConfirmationConfirmed: boolean;
  user: User;
  sessionStartTime: Date;
}

export interface User {
  id: string;
  avatar: string;
  email: string;
  name: string;
  surname: string;
  notifications: Notification[];
  role: string;
}

export interface Notification {
  id: string;
  text: string;
  created_at: string;
  isRead: boolean;
  isSuccess: boolean;
  submissionId: string;
}
