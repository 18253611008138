import styled from 'styled-components';
import { MdAdd } from 'react-icons/md';
import { IoCalendarOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import BlankFolderOutline from '../../../assets/myAnalysis/blankFolderOutline.png';
import BlankFolder from '../../../assets/myAnalysis/blankFolder.png';
import { AiOutlineDownload } from 'react-icons/ai';

const commonWrapper = `
  width: 256px;
  height: 228px;
  background-position: center;
  background-size: contain;
  padding: 0.5rem 1.375rem 1.375rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: .75rem;
  background-repeat: no-repeat;
`;

export const Wrapper = styled.article`
  ${commonWrapper}
  width: 254px;
  background: url(${BlankFolder});
  cursor: pointer;
`;

export const LinkWrapper = styled(Link)`
  ${commonWrapper}
  background: url(${BlankFolderOutline});
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 1.5rem;
`;

export const PlusIcon = styled(MdAdd)`
  width: 2.625rem;
  height: 2.625rem;
  margin: 0 0 0.5rem;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const PlusLabel = styled.label`
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  width: 146px;
  text-align: center;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  text-align: center;
  display: block;
  cursor: pointer;
`;

export const DateRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 auto;
`;

export const CalendarIcon = styled(IoCalendarOutline)`
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0;
  color: ${({ theme: { grayInfo } }) => grayInfo};
`;

export const Date = styled.p`
  color: ${({ theme: { grayInfo } }) => grayInfo};
  font-family: Poppins;
  font-size: 14px;
  line-height: 160%;
  margin: 0;
`;

export const Header = styled.h6`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 20px;
  line-height: 160%;
  font-weight: 500;
  margin: 0 0 0.5rem;
  word-break: break-word;
`;

export const LabelWrapper = styled.div<{ backgroundColor: string }>`
  border-radius: 8px;
  height: 26px;
  padding: 0.125rem 0.75rem;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const LabelText = styled.p<{ color: string }>`
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  line-height: 160%;
  color: ${({ color }) => color};
`;

export const LabelIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0;
`;

export const DownloadIcon = styled(AiOutlineDownload)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme: { white } }) => white};
  margin: 0 0.75rem 0 0;
`;

export const Label = styled.p`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme: { white } }) => white};
  margin: 0;
`;

export const commonButton = `
background: linear-gradient(to right, #41bbff, #086ee7);
transition: 250ms;
margin: 1.25rem 0 0;
padding: .5rem 1rem;
width: 100%;
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: center;
border-radius: 4px;
cursor: pointer;
&:hover {
  box-shadow: 0px 0px 12px #41bbff;
}
`;

export const DownloadButton = styled.a`
  text-decoration: none;
  ${commonButton}
`;

export const WhiteLabel = styled(Label)`
  background: linear-gradient(to right, #41bbff, #086ee7);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.25rem;
`;

export const WhiteLabelContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { white } }) => white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const SendFilesButton = styled.button`
  ${commonButton}
  border: none;
  text-decoration: none;
  padding: 2px;
`;
