import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../redux/auth/actions';
import { userData } from '../../../redux/auth/selectors';
import { Role } from '../../../redux/submission/types';
import { toggleModal } from '../../../redux/utils/actions';
import { isModalOpen } from '../../../redux/utils/selectors';
import { MODAL_NAME } from '../../../redux/utils/types';
import { getUserNameAndSurnameLetter } from '../../../utils/user';
import * as S from './AvatarCircle.styles';

const AvatarCircle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isOpen = useSelector(isModalOpen(MODAL_NAME.AVATAR_MENU));

  const handleLogout = () => dispatch(logout());
  const { name, surname, role } = useSelector(userData);
  const myAccountLink =
    role === Role.USER ? '/my-analysis' : '/analysis-details';
  const handleGoToMyAccount = () => history.push(myAccountLink);
  const initials = getUserNameAndSurnameLetter(name, surname);

  const handleClickOnAvatar = (e: any) => {
    e.stopPropagation();
    dispatch(toggleModal(MODAL_NAME.AVATAR_MENU));
  };

  const handleClickOnAvatarMouseLeave = () => {
    dispatch(toggleModal(MODAL_NAME.AVATAR_MENU));
  };

  return (
    <S.AvatarWrapper onClick={(e) => handleClickOnAvatar(e)}>
      {isOpen && (
        <S.AvatarCircleModal
          onClick={(e) => e.stopPropagation()}
          onMouseLeave={() => handleClickOnAvatarMouseLeave()}
        >
          <S.ActionsContainer>
            <S.AvatarItem onClick={handleGoToMyAccount}>
              {t('avatarCircle.myAccount')}
            </S.AvatarItem>
            <S.AvatarItem onClick={handleLogout}>
              {t('avatarCircle.logout')}
            </S.AvatarItem>
          </S.ActionsContainer>
        </S.AvatarCircleModal>
      )}
      <S.Initials>{initials}</S.Initials>
    </S.AvatarWrapper>
  );
};

export default AvatarCircle;
