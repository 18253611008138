import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 96px;
  height: 96px;
  margin: 0 auto;
`;

export const SuccessWrapper = styled.div`
  color: #41bbff;
  size: 16px;
  font-weight: 400;
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
`;

export const SuccessImage = styled.img`
  padding-bottom: 16px;
`;

export const TextContainer = styled.div`
  color: #41bbff;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 28px;
  padding-left: 5px;
`;

export const MovingCircleContainer = styled.div<{ rotateDeg: string }>`
  height: 74px;
  width: 74px;
  transform: ${({ rotateDeg }) => `rotate(${rotateDeg})`};
  position: absolute;
  transition-duration: 0.5s;
`;

export const MovingCircle = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #41bbff;
  position: absolute;
`;

export const PleaseWaitText = styled.div`
  font-family: Poppins;
  font-weight: 600;
  color: #07005c;
`;
