import Cookies from 'universal-cookie';
import { getUserNameAndSurnameLetter } from './user';

const SESSION_START_TIMESTAMP = 't3d-countdown';
const SHARED_COOKIE = 't3d-shared';
const MINUTE_IN_MS = 60 * 1000;
const DOMAIN = '.tumor3d.com';

export const resetSessionStartTimestamp = () => {
  const cookies = new Cookies();
  cookies.set(
    SESSION_START_TIMESTAMP,
    `${new Date(Date.now() + 15 * MINUTE_IN_MS)}`,
    {
      path: '/',
      domain: DOMAIN,
    }
  );
};

export const getSessionStartTimestamp = () => {
  const cookies = new Cookies();
  return cookies.get(SESSION_START_TIMESTAMP);
};

export const setSharedCookie = (session: any) => {
  const cookies = new Cookies();
  if (
    !session ||
    !session.user ||
    !session.user.name ||
    !session.user.surname
  ) {
    return null;
  }

  const { name, surname } = session.user;

  const avatarText = getUserNameAndSurnameLetter(name, surname);
  cookies.set(
    SHARED_COOKIE,
    {
      avatarText,
    },
    {
      path: '/',
      // domain: DOMAIN,
    }
  );
};

export const getSharedCookie = () => {
  const cookies = new Cookies();
  return cookies.get(SHARED_COOKIE);
};

export const removeSharedCookie = () => {
  const cookies = new Cookies();
  cookies.remove(SHARED_COOKIE, {
    path: '/',
    // domain: DOMAIN,
  });
};
