import { useState, useEffect } from 'react';
import SelectWithIcon from './SelectWithIcon/SelectWithIcon';
import { ANALYSIS_STATUS } from '../../utils/types';
import TableRow, { ITableRow } from './TableRow/TableRow';
import Pagination from './Pagination/Pagination';
import TableHeader from './TableHeader/TableHeader';
import { useDispatch, useSelector } from 'react-redux';
import DualDateSelect from './DualDateSelect/DualDateSelect';
import {
  fetchSubmissions,
  setSearchParams,
  setSubmissionListSort,
} from '../../redux/submission/actions';
import {
  filteredSubmissions,
  searchParams,
} from '../../redux/submission/selectors';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { history } from '../../App';
import {
  SubmissionSortParams,
  SUBMISSION_FIELDS,
} from '../../redux/submission/submissionReducer';
import MyAnalysis from '../../components/MyAnalysis/MyAnalysis'
import * as S from './AnalysisTable.styles';

export default function AnalysisTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { query } = useParams() as { query: string };

  useEffect(() => {
    dispatch(setSearchParams({ query }));
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(fetchSubmissions());
  }, [dispatch]);

  const { maxPage, results } = useSelector(filteredSubmissions);
  const { page, perPage, status, dateMax, dateMin } = useSelector(searchParams);
  const [data, setData] = useState<ITableRow[]>(results);
  const isSelectedAnalysisDetailsTab = pathname.includes('analysis-details');
  const isSelectedAnalysisTab = pathname.includes('analysis-list');

  const [sortBy, setSortBy] = useState<SubmissionSortParams>({
    field: SUBMISSION_FIELDS.ID,
    direction: 'desc',
  });
  const setSelectedPage = (value: number) =>
    dispatch(setSearchParams({ page: value }));
  const setItemsPerPage = (value: number) =>
    dispatch(setSearchParams({ perPage: value }));
  const setStatus = (value: ANALYSIS_STATUS) =>
    dispatch(setSearchParams({ status: value }));
  const setMinDate = (value: string | null) =>
    dispatch(setSearchParams({ dateMin: value }));
  const setMaxDate = (value: string | null) =>
    dispatch(setSearchParams({ dateMax: value }));

  const handleAllAnalysisButton = () => history.push('/analysis-list');
  const handleSelectAnalysisDetailsTab = () => history.push('/analysis-details');

  useEffect(() => {
    setData(results);
  }, [results]);

  const handleSortBy = (params: SubmissionSortParams) => {
    const { direction, field } = params;
    dispatch(setSubmissionListSort(direction, field));
    setSortBy(params);
  };

  const renderAllAnalysisBody = () =>
    <>
      <TableHeader sortBy={sortBy} setSortBy={handleSortBy} />
      <S.ResultsContainer>
        {data.map((row) => (
          <TableRow {...row} key={row.id} />
        ))}
      </S.ResultsContainer>
      <Pagination
        selectedPage={page}
        setSelectedPage={setSelectedPage}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={perPage}
        maxPage={maxPage}
      />
    </>

  const renderAnalysisDetailsBody = () => <MyAnalysis isInternal />

  return (
    <S.Wrapper>
      <S.TopWrapper>
        <S.CategoriesWrapper>
          <S.CategoryButton
            isSelected={isSelectedAnalysisTab}
            onClick={handleAllAnalysisButton}
          >
            {t('analysis.table.allAnalysis')}
          </S.CategoryButton>
          <S.CategoryButton
            isSelected={isSelectedAnalysisDetailsTab}
            onClick={handleSelectAnalysisDetailsTab}
          >
            {t('analysis.table.myAnalysis')}
          </S.CategoryButton>
        </S.CategoriesWrapper>
        <S.SelectorsWrapper>
          <S.SelectorWrapper>
            <S.SelectorLabel>{t('analysis.table.status')}</S.SelectorLabel>
            <SelectWithIcon selected={t(status)} action={setStatus} />
          </S.SelectorWrapper>
          <DualDateSelect
            dateMin={dateMin}
            dateMax={dateMax}
            changeDateMax={setMaxDate}
            changeDateMin={setMinDate}
          />
        </S.SelectorsWrapper>
      </S.TopWrapper>
      {isSelectedAnalysisDetailsTab ? renderAnalysisDetailsBody() : renderAllAnalysisBody()}
    </S.Wrapper>
  );
}
