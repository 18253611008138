import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUsers,
  setSearchUsersParams,
  setUserListSort,
} from '../../redux/admin/actions';
import {
  filteredMembers,
  usersSearchParams,
} from '../../redux/admin/selectors';
import UserTableHeader from './TableHeader/UserTableHeader';
import UserTableRow, { IUserTableRow } from './TableRow/UserTableRow';
import Pagination from '../AnalysisTable/Pagination/Pagination';
import { useParams } from 'react-router-dom';
import { UserSortParams, USER_FIELDS } from '../../redux/admin/adminReducer';
import { userData } from '../../redux/auth/selectors';
import PrivacyChangeModal from '../../common/Modals/PrivacyChangeModal/PrivacyChangeModal';
import ToSChangeModal from '../../common/Modals/ToSChangeModal/ToSChangeModal';
import * as S from './UsersTable.styles';

const UsersTable = () => {
  const dispatch = useDispatch();
  const { query } = useParams() as { query: string };
  const [isToSModalOpen, setIsToSModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const user = useSelector(userData);
  const isAdmin = user.role === 'admin' || user.role === 'operator';

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const { maxPage, results } = useSelector(filteredMembers);
  const { page, perPage } = useSelector(usersSearchParams);

  const [data, setData] = useState<IUserTableRow[]>(results);
  const amountOfUsersThatAccepted = data.filter(
    (u) => u.isAccountConfirmed && !u.isArchived ///added
  ).length;

  const [sortBy, setSortBy] = useState<UserSortParams>({
    field: USER_FIELDS.CREATION,
    direction: 'desc',
  });

  const setSelectedPage = (value: number) =>
    dispatch(setSearchUsersParams({ page: value }));
  const setItemsPerPage = (value: number) =>
    dispatch(setSearchUsersParams({ perPage: value }));

  useEffect(() => {
    setData(results);
  }, [results]);

  useEffect(() => {
    dispatch(setSearchUsersParams({ query }));
  }, [dispatch, query]);

  const handleSortBy = (params: UserSortParams) => {
    const { direction, field } = params;
    dispatch(setUserListSort(direction, field));
    setSortBy(params);
  };

  const handleToSChangeEmails = () => setIsToSModalOpen(true);
  const handlePrivacyChangeEmails = () => setIsPrivacyModalOpen(true);

  return (
    <S.Wrapper>
      <ToSChangeModal
        isOpen={isToSModalOpen}
        onClose={() => setIsToSModalOpen(!isToSModalOpen)}
        amountOfAffectedUsers={amountOfUsersThatAccepted}
      />
      <PrivacyChangeModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(!isPrivacyModalOpen)}
        amountOfAffectedUsers={amountOfUsersThatAccepted}
      />
      {isAdmin && (
        <S.ActionButtons>
          <S.ActionButton onClick={handleToSChangeEmails}>
            E-mail zmiana Regulaminu
          </S.ActionButton>
          <S.ActionButton onClick={handlePrivacyChangeEmails}>
            E-mail zmiana Polityki Prywatności
          </S.ActionButton>
        </S.ActionButtons>
      )}
      <UserTableHeader sortBy={sortBy} setSortBy={handleSortBy} />
      <S.ResultsContainer>
        {data.map((row, index: number) => (
          <UserTableRow {...row} key={index} />
        ))}
      </S.ResultsContainer>
      <Pagination
        selectedPage={page}
        setSelectedPage={setSelectedPage}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={perPage}
        maxPage={maxPage}
      />
    </S.Wrapper>
  );
};

export default UsersTable;
