import StartPanel from './components/StartPanel/StartPanel';
import { createBrowserHistory } from 'history';
import colorTheme from './colorTheme';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ScanStep from './components/Steps/ScanStep/ScanStep';
import MyAccount from './components/MyAccount/MyAccount';
import MyAnalysis from './components/MyAnalysis/MyAnalysis';
import LoginPage from './components/LoginPage/LoginPage';
import ProtectedRoute from './common/ProtectedRoute/ProtectedRoute';
import AdminRoute from './common/AdminRoute/AdminRoute';
import DicomStep from './components/Steps/DicomStep/DicomStep';
import SuccessStep from './components/Steps/PaymentResultSteps/SuccessStep';
import HelpPage from './components/HelpPage/HelpPage';
import RegisterPage from './components/RegisterPage/RegisterPage';
import AnalysisTable from './components/AnalysisTable/AnalysisTable';
import ConfirmAccountPage from './components/ConfirmAccountPage/ConfirmAccountPage';
import RemindPasswordPage from './components/RemindPasswordPage/RemindPasswordPage';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './App.css';
import FailureStep from './components/Steps/PaymentResultSteps/FailureStep';
import PaymentStep from './components/Steps/PaymentStep/PaymentStep';
import UsersTable from './components/UsersTable/UsersTable';
import OperatorRoute from './common/OperatorRoute/OperatorRoute';
import SEOTableImages from './components/SEO/SEOTableImages/SEOTableImages';
import SEOTableTags from './components/SEO/SEOTableTags/SEOTableTags';
import LanguageSwitchPage from './components/LanguageSwitchPage/LanguageSwitchPage';
import ConfirmPasswordResetPage from './components/ConfirmPasswordResetPage/ConfirmPasswordResetPage';
export const history = createBrowserHistory();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '');

const App = () => (
  <Router history={history}>
    <ToastContainer />
    <ThemeProvider theme={colorTheme}>
      <Elements stripe={stripePromise}>
        <Switch>
          <Route
            path="/en/*"
            exact
            component={() => <LanguageSwitchPage language="en" />}
          />
          <Route
            path="/pl/*"
            exact
            component={() => <LanguageSwitchPage language="pl" />}
          />
          <Route
            path="/de/*"
            exact
            component={() => <LanguageSwitchPage language="de" />}
          />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/register" exact component={RegisterPage} />
          <Route path="/signup" exact component={RegisterPage} />
          <Route path="/remind-password" exact component={RemindPasswordPage} />
          <Route
            path="/confirmation/:token"
            exact
            component={ConfirmAccountPage}
          />
          <Route
            path="/password-reset/:token"
            exact
            component={ConfirmPasswordResetPage}
          />
          <ProtectedRoute path="/" exact component={() => <StartPanel />} />
          <ProtectedRoute
            path="/submission/new"
            exact
            component={() => <ScanStep />}
          />
          <ProtectedRoute
            path="/submission/:submissionId/dicom"
            exact
            component={() => <DicomStep />}
          />
          <ProtectedRoute
            path="/submission/:submissionId/payment/:paymentId"
            exact
            component={() => <PaymentStep />}
          />
          <ProtectedRoute
            path="/payment/processed/:submissionId"
            exact
            component={() => <SuccessStep />}
          />
          <ProtectedRoute
            path="/payment/failed/:submissionId"
            exact
            component={() => <FailureStep />}
          />
          <ProtectedRoute
            path="/settings"
            exact
            component={() => <MyAccount />}
          />
          <ProtectedRoute path="/help" exact component={() => <HelpPage />} />
          <ProtectedRoute path="/my-analysis" exact component={MyAnalysis} />
          <OperatorRoute
            path="/analysis-list/:query"
            exact
            component={AnalysisTable}
          />
          <OperatorRoute
            path="/analysis-list"
            exact
            component={AnalysisTable}
          />
          <AdminRoute
            path="/results/:submissionId/file"
            exact
            component={ScanStep}
          />
          <OperatorRoute path="/users" exact component={UsersTable} />
          <OperatorRoute path="/seo-images" exact component={SEOTableImages} />
          <OperatorRoute path="/seo-tags" exact component={SEOTableTags} />
          <OperatorRoute
            path="/analysis-details"
            exact
            component={AnalysisTable}
          />
          {/* <Route path="*">
              <Redirect to="/" />
            </Route> */}
          {/* <Redirect exact to="/" /> */}
          {/* <ProtectedRoute path="*" exact component={() => <StartPanel />} /> */}
          {/* </Layout> */}
          <Route path="*">
            <Redirect to="/" />
          </Route>

          {/* <Route exact path="*">
            <Redirect to="/" />
          </Route> */}
        </Switch>
      </Elements>
    </ThemeProvider>
  </Router>
);

export default App;
