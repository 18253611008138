import styled from 'styled-components';
import colorTheme from '../../../colorTheme';
import TextField from '@mui/material/TextField';
import { styled as muiStyled } from '@mui/material/styles';
import { BsTrash } from 'react-icons/bs';
export const Background = styled.div`
  background-color: ${({ theme: { gray } }) => gray};
  flex-grow: 1;
`;

export const Wrapper = styled.main`
  padding: 38px 25px;
  box-sizing: border-box;
  max-width: 805px;
  width: 100%;
  @media (min-width: 800px) {
    padding: 38px 102px;
  }
`;

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h2`
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  margin: 0 auto 24px;
  width: 100%;
  max-width: 600px;
`;

// TODO: copied from start-panel: needs to be exported as separate component.
export const SubmitButton = styled.button`
  max-width: 298px;
  max-height: 48px;
  font-size: 15px;
  line-height: 22.5px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  color: ${({ theme: { white } }) => white};
  background: linear-gradient(to right, #41bbff, #086ee7);
  padding: 12.5px 40px;
  border-radius: 4px;
  text-transform: uppercase;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : `pointer`)};
  font-weight: 600;
  transition: 250ms;
  filter: ${({ disabled }) => (disabled ? 'saturate(.4)' : `saturate(1)`)};
  &:hover {
    ${({ disabled }) => (disabled ? '' : `box-shadow: 0px 4px 39px #41bbff;`)}
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #b2b5ba;
  background: transparent;
  border: none;
  margin: 26px 0 0;
  padding: 0;
  cursor: pointer;
  transition: 250ms;
  :hover {
    text-shadow: 0 0 8px ${({ theme: { blue } }) => blue};
  }
`;

export const TrashIcon = styled(BsTrash)`
  margin: auto 0.5rem auto 0;
  color: #a5a8ad;
  width: 14px;
  height: 14px;
`;

export const CustomInput = muiStyled(TextField)({
  '& label.Mui-focused': {
    color: colorTheme.mainBlue,
  },
  '& label.MuiInputLabel-shrink': {
    top: '10px',
  },
  '& input:invalid + label.Mui-focused': {
    color: colorTheme.red,
  },
  '& input:disabled': {
    backgroundColor: colorTheme.inputBackground,
  },
  backgroundColor: colorTheme.gray,
  color: colorTheme.mainBlue,
  margin: '0 0 26px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      borderBottom: `2px solid ${colorTheme.textGray}`,
      borderRadius: '0',
    },
    '&:hover fieldset': {
      borderColor: colorTheme.mainBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colorTheme.mainBlue,
    },
    '& input': {
      padding: '16px 0 6px 14px',
    },
  },
});
