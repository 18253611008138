import styled from 'styled-components';

export const Wrapper = styled.main`
  max-width: 1050px;
  width: 100%;
  box-sizing: border-box;
  padding: 44px 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1400px) {
    padding: 38px 102px;
  }
`;

export const ResultsWrapper = styled.div<{ isInternal: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 1.75rem -0.75rem 0;
  @media (min-width: 620px) {
    margin: 0 -0.75rem;
    flex-direction: row;
    align-items: flex-start;
    justify-content: ${({ isInternal }) => isInternal ? 'center' : 'left'};
  }
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
