import SubmissionNavigation from '../SubmissionNavigation/SubmissionNavigation';
import infoIcon from '../../../assets/steps/infoIcon.png';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  createResultsSubmission,
  createSubmission,
  resetUploadPercentage,
} from '../../../redux/submission/actions';
import Dropzone from '../../Dropzone/Dropzone';
import { useTranslation } from 'react-i18next';
import { useUploadPercentage } from '../../../redux/submission/selectors';
import { fetchSubmission } from '../../../api/common-api';
import { ISubmission } from '../../../redux/submission/types';
import { history } from '../../../App';
import * as S from './ScanStep.styles';

const ScanStep = () => {
  const { submissionId } = useParams() as { submissionId?: string };
  const isResults = !!submissionId;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [description, setDescription] = useState('');
  const [isNumberInfoDisplayed, setIsNumberInfoDisplayed] = useState(false);
  const [isSizeInfoDisplayed, setIsSizeInfoDisplayed] = useState(false);
  const uploadPercentage = useSelector(useUploadPercentage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(resetUploadPercentage);
  }, [dispatch]);

  const onFileSizeExceeded = () => {
    setIsSizeInfoDisplayed(true);
    setFiles([]);
  };

  const onFilesNumberExceeded = () => {
    setIsNumberInfoDisplayed(true);
    setFiles([]);
  };

  useEffect(() => {
    const getSubmissionData = async () => {
      if (!submissionId) {
        return;
      }
      const submissionData = (await fetchSubmission(
        submissionId
      )) as ISubmission;
      setDescription(submissionData.description);
    };
    getSubmissionData();
  }, [submissionId]);

  const [files, setFiles] = useState<File[]>([]);
  const activeButton = () => setChecked(!checked);

  const goBack = () => history.goBack();
  const onDrop = useCallback((acceptedFiles) => setFiles(acceptedFiles), []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSubmit = useCallback((): void => {
    if (isResults) {
      dispatch(
        createResultsSubmission({
          references: submissionId,
          description: `Results: ${description}`,
          files,
        })
      );
    } else {
      dispatch(createSubmission({ description, files }));
    }
  }, [description, dispatch, files, isResults, submissionId]);

  const isUploadingFiles = uploadPercentage > 0;
  const enabledButton =
    (checked &&
      description.length > 0 &&
      files.length > 0 &&
      !isUploadingFiles) ||
    (isResults && files.length > 0 && !isUploadingFiles);
  const dropzoneDisabled = isUploadingFiles;

  const NumberInfoModal = () => (
    <S.InfoModal>{t('infoModal.infoNumber')}</S.InfoModal>
  );
  const SizeInfoModal = () => (
    <S.InfoModal>{t('infoModal.infoSize')}</S.InfoModal>
  );

  const clearWarnings = () => {
    setIsNumberInfoDisplayed(false);
    setIsSizeInfoDisplayed(false);
  };
  const showWarnings = isNumberInfoDisplayed || isSizeInfoDisplayed;

  const videoFileTypes = 'video/mp4, video/x-m4v, video/*';
  const imageFileTypes = 'image/*, .pdf';

  const acceptedFileTypes = isResults
    ? `${videoFileTypes}, ${imageFileTypes}`
    : imageFileTypes;

  return (
    <S.Wrapper>
      {!isResults && (
        <SubmissionNavigation activeStep={0} isResults={isResults} />
      )}
      <S.StepWrapper>
        {!isResults && (
          <S.NameContainer>
            <S.Name>{t('steps.scan.analysisName')}</S.Name>
            <S.StyledInput
              type="text"
              placeholder={t('steps.scan.analysisNamePlaceholder')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </S.NameContainer>
        )}
        {isResults && (
          <S.ResultsInfo>
            {t('steps.scan.uploadScanResults')}{' '}
            <S.ResultsName>{description}</S.ResultsName>
          </S.ResultsInfo>
        )}
        <S.DescriptionContainer>
          <S.Name>{t('steps.scan.uploadScan')}</S.Name>
        </S.DescriptionContainer>
        <Dropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          files={files}
          disabled={dropzoneDisabled}
          maxFilesAmount={10}
          onFileSizeExceeded={onFileSizeExceeded}
          onFilesNumberExceeded={onFilesNumberExceeded}
          isVertical={true}
          onClick={() => clearWarnings()}
          isWarning={showWarnings}
          acceptedFiles={acceptedFileTypes}
        />
        {isNumberInfoDisplayed && <NumberInfoModal />}
        {isSizeInfoDisplayed && <SizeInfoModal />}
        {!isResults && (
          <S.InfoWrapper>
            <S.InfoContainer>
              <S.CustomContainer>
                <S.Icon src={infoIcon}></S.Icon>
              </S.CustomContainer>
              <S.Info>{t('steps.scan.info')}</S.Info>
            </S.InfoContainer>
            <S.InfoContainer>
              <S.LabelContainer id="confirmation">
                <S.CustomContainer>
                  <S.StyledCheckbox
                    disabled={isUploadingFiles}
                    type="checkbox"
                    id="confirmation"
                    onClick={activeButton}
                  />
                </S.CustomContainer>
                <S.Info>{t('steps.scan.agree')}</S.Info>
              </S.LabelContainer>
            </S.InfoContainer>
          </S.InfoWrapper>
        )}
        <S.ButtonContainer>
          <S.NextStepButton disabled={!enabledButton} onClick={handleSubmit}>
            {t('steps.nextStep')}
          </S.NextStepButton>
          <S.BackButton
            isDisabled={isUploadingFiles}
            disabled={isUploadingFiles}
            onClick={goBack}
          >
            {isUploadingFiles ? <S.BackArrowDisabled /> : <S.BackArrow />}
            {t('steps.backToPanel')}
          </S.BackButton>
        </S.ButtonContainer>
      </S.StepWrapper>
    </S.Wrapper>
  );
};

export default ScanStep;
