import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import NavbarLink, {
  /*ILink,*/ Links,
} from '../../components/Navbar/NavbarLink';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/auth/actions';
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from 'react';
import { useDrawerSubmissionId } from '../../redux/submission/selectors';
import { setDrawerSubmissionId } from '../../redux/submission/actions';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import { userData } from '../../redux/auth/selectors';
import { useTranslation } from 'react-i18next';
import * as S from './Layout.styles';
import { closeModals } from '../../redux/utils/actions';

export default function Layout({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const routeChange = () => history.push('/submission/new');
  const isDesktop = useMediaQuery('(min-width: 1024px');
  const location = useLocation();
  const { role } = useSelector(userData);
  const drawerSubmissionId = useSelector(useDrawerSubmissionId);
  const { pathname } = location;
  const isScanPage = pathname.includes('/new');
  const isDicomPage = pathname.includes('/dicom');
  const isSuccessPage = pathname.includes('/success');
  const isTablePage = pathname.includes('/analysis-list');
  const isAnalysisDetailsTablePage = pathname.includes('/analysis-details');
  const isResultsPage = pathname.includes('/results/');
  const isUserTablePage = pathname.includes('/users');
  const isSEOPage = pathname.includes('/seo');
  const isPaymentPage =
    pathname.includes('/payment/processed') ||
    pathname.includes('/payment/failed');
  const handleLogout = () => dispatch(logout());
  const [isBackdropOpen, setIsBackDropOpen] = useState(!!drawerSubmissionId);

  useEffect(() => {
    setIsBackDropOpen(!!drawerSubmissionId);
  }, [drawerSubmissionId]);

  const handleClickBackdrop = (e: any) => {
    dispatch(setDrawerSubmissionId(''));
  };

  const canAddNewAnalysis = role !== 'admin';
  const clearModals = () => dispatch(closeModals());

  return (
    <S.Wrapper onClick={() => clearModals()}>
      <Navbar />
      <S.Body isTablePage={isTablePage}>
        <Backdrop
          style={{ zIndex: 1 }}
          open={isBackdropOpen}
          onClick={handleClickBackdrop}
        >
          <SideDrawer />
        </Backdrop>
        {isDesktop &&
          !isPaymentPage &&
          !isScanPage &&
          !isDicomPage &&
          !isSuccessPage &&
          !isTablePage &&
          !isResultsPage &&
          !isUserTablePage &&
          !isSEOPage &&
          !isAnalysisDetailsTablePage &&
          !isUserTablePage && (
            <S.SideMenuWrapper>
              {canAddNewAnalysis && (
                <S.SendAnalysisButton onClick={routeChange} isSideMenu={true}>
                  {t('navbar.sendFiles')}
                </S.SendAnalysisButton>
              )}
              <S.LinksWrapper>
                {Links(role).map((row: any, index: number) => (
                  <NavbarLink key={`row_${index}`} {...row} />
                ))}
              </S.LinksWrapper>
              <S.LogoutButton onClick={handleLogout}>
                {t('navbar.logout')}
              </S.LogoutButton>
            </S.SideMenuWrapper>
          )}
        {children}
      </S.Body>
    </S.Wrapper>
  );
}
