import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 30px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 275px;
  padding: 20px 20px;
`;

export const Title = styled.span`
  font-family: Poppins;
  font-size: 35px;
  line-height: 38.5px;
  font-weight: 650;
  padding-bottom: 10px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const LanguagePickWrapper = styled.div`
  margin-left: auto;
  padding: 10px 20px;
`;

export const Option = styled.option`
  color: ${({ theme: { white } }) => white};
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const ConfirmButton = styled.button<{ disabled?: boolean }>`
  padding: 12.5px 0;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  min-width: 240px;
  letter-spacing: 1px;
  text-align: center;
  color: ${({ theme: { white } }) => white};
  background: ${({ disabled, theme: { grayFont, blue, gradientBlue } }) =>
    disabled
      ? grayFont
      : `linear-gradient(to right, ${blue}, ${gradientBlue})`};
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 20px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '' : '0px 4px 39px #41bbff')};
  }
  @media (min-width: 768px) {
    width: 215px;
    /* padding: 11.5px 40px; */
    margin: auto 0 auto auto;
    order: 2;
  }
`;

export const QuestionContainer = styled.div``;

export const Question = styled.div`
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme: { grayInfo } }) => grayInfo};
`;

export const ActionContainer = styled(NavLink)`
  padding-left: 4px;
  cursor: pointer;
  font-family: Poppins;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme: { blue } }) => blue};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: ${({ theme: { blue } }) => blue};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;
export const Logo = styled.img`
  /* width: 100%;
  height: 100%; */
  width: 130px;
  height: 37px;
  &:hover {
    cursor: pointer;
  }
`;

export const LogoWrapper = styled.div`
  padding-left: 15px;
`;

export const LabelContainer = styled.label`
  cursor: pointer;
  display: flex;
  max-width: 300px;
`;

export const Info = styled.div`
  color: ${({ theme: { grayInfo } }) => grayInfo};
  font-size: 12px;
  padding: 3px 0 17px 14px;
`;

export const LinkContainer = styled.a`
  cursor: pointer;
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme: { blue } }) => blue};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: ${({ theme: { blue } }) => blue};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const HeaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  display: flex;
  min-height: 80px;
  align-items: center;
`;

export const LocaleSelect = styled.select`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border: none;
  cursor: pointer;
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  :focus-visible {
    outline: none;
  }
`;

export const Red = styled.span`
  color: red;
`;
