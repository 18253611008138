import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { useUserLoggedIn } from '../../redux/auth/selectors';
import Layout from '../Layout/Layout';

export interface ProtectedProps {
  path?: string;
  component?: any;
  exact?: boolean;
}

export const ProtectedRoute = ({
  component: Component,
  ...rest
}: ProtectedProps) => {
  const isUserLoggedIn = useSelector(useUserLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
