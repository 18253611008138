import { useSelector } from 'react-redux';
import MobileRegisterForm from '../Authentication/RegisterForm/MobileRegisterForm';
import LoginRegisterPart from '../Authentication/LoginRegisterPart';
import { useUserLoggedIn } from '../../redux/auth/selectors';
import RegisterForm from '../Authentication/RegisterForm/RegisterForm';
import { Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import * as S from './RegisterPage.styles';

const RegisterPage = () => {
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isUserLoggedIn = useSelector(useUserLoggedIn);

  if (isUserLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <S.Wrapper>
      {isTablet ? (
        <>
          <LoginRegisterPart />
          <RegisterForm />
        </>
      ) : (
        <MobileRegisterForm />
      )}
    </S.Wrapper>
  );
};

export default RegisterPage;
