export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const SET_SEARCH_USERS_PARAMS = 'SET_SEARCH_USERS_PARAMS';
export const SET_SEARCH_SEO_PARAMS = 'SET_SEARCH_SEO_PARAMS';

export const SORT_USERS_TABLE = 'SORT_USERS_TABLE';
export const SORT_SEO_TAGS_TABLE = 'SORT_SEO_TAGS_TABLE';
export const SORT_SEO_IMAGES_TABLE = 'SORT_SEO_IMAGES_TABLE';

export const FETCH_IMAGE_ALTS = 'FETCH_IMAGE_ALTS';
export const FETCH_IMAGE_ALTS_FAIL = 'FETCH_IMAGE_ALTS_FAIL';
export const FETCH_IMAGE_ALTS_SUCCESS = 'FETCH_IMAGE_ALTS_SUCCESS';

export const FETCH_OG_TAGS = 'FETCH_OG_TAGS';
export const FETCH_OG_TAGS_FAIL = 'FETCH_OG_TAGS_FAIL';
export const FETCH_OG_TAGS_SUCCESS = 'FETCH_OG_TAGS_SUCCESS';

export const RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS';

export const UPDATE_OG_IMAGE = 'UPDATE_OG_IMAGE';
export const UPDATE_OG_IMAGE_FAILED = 'UPDATE_OG_IMAGE_FAILED';
export const UPDATE_OG_IMAGE_SUCCESS = 'UPDATE_OG_IMAGE_SUCCESS';

export const UPDATE_IMAGE_ALTS = 'UPDATE_IMAGE_ALTS';
export const UPDATE_IMAGE_ALTS_FAILED = 'UPDATE_IMAGE_ALTS_FAILED';
export const UPDATE_IMAGE_ALTS_SUCCESS = 'UPDATE_IMAGE_ALTS_SUCCESS';

export const ARCHIVE_USER = 'ARCHIVE_USER';
export const ARCHIVE_USER_SUCCESS = 'ARCHIVE_USER_SUCCESS';

export const UNARCHIVE_USER = 'UNARCHIVE_USER';
export const UNARCHIVE_USER_SUCCESS = 'UNARCHIVE_USER_SUCCESS';

export const SEND_TOS_CHANGED_MAIL = 'SEND_TOS_CHANGED_MAIL';
export const SEND_TOS_CHANGED_MAIL_FAILED = 'SEND_TOS_CHANGED_MAIL_FAILED';
export const SEND_TOS_CHANGED_MAIL_SUCCESS = 'SEND_TOS_CHANGED_MAIL_SUCCESS';

export const SEND_PRIVACY_CHANGED_MAIL = 'SEND_PRIVACY_CHANGED_MAIL';
export const SEND_PRIVACY_CHANGED_MAIL_FAILED =
  'SEND_PRIVACY_CHANGED_MAIL_FAILED';
export const SEND_PRIVACY_CHANGED_MAIL_SUCCESS =
  'SEND_PRIVACY_CHANGED_MAIL_SUCCESS';
