import { CLOSE_MODALS, TOGGLE_MODAL } from './actionTypes';

export const closeModals = () => ({
  type: CLOSE_MODALS,
});

export const toggleModal = (name: string) => ({
  type: TOGGLE_MODAL,
  name,
});
