import MenuItem from '@mui/material/MenuItem/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import colorTheme from '../../../colorTheme';
import { ANALYSIS_STATUS } from '../../../utils/types';
import { MOCK_MENU_ITEMS } from '../../../utils/mocks';
import { statusesLabelsThemes } from '../../../utils/submission';
import { useTranslation } from 'react-i18next';
import * as S from './SelectWithLabel.styles';

interface IMenuOption {
  label: string;
  value: any;
}

interface IMenuSelect {
  selected: ANALYSIS_STATUS;
  action: (id: any) => void;
}

export default function SelectWithIcon({ selected, action }: IMenuSelect) {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    action(value);
  };
  const options = MOCK_MENU_ITEMS.map(({ label, status }) => ({
    label: t(label),
    value: status,
  }));
  const selectedOption = options.find(({ value }) => value === selected);
  return (
    <S.CustomSelect
      value={selectedOption ? t(selectedOption.label) : undefined}
      onChange={handleChange}
      MenuProps={{
        disableScrollLock: true,
      }}
      renderValue={() => {
        const { icon, message, color } = statusesLabelsThemes[selected];
        return (
          <S.LabelWrapper>
            <S.Icon src={icon} />
            <S.Label color={color}>{t(message)}</S.Label>
          </S.LabelWrapper>
        );
      }}
    >
      {options.map(({ label, value }: IMenuOption, index: number) => {
        const { icon, color } = statusesLabelsThemes[value as ANALYSIS_STATUS];
        return (
          <MenuItem
            key={`item_${index}`}
            value={value}
            sx={{
              background: 'transparent',
              fontSize: 15,
              fontFamily: 'Poppins',
              color: colorTheme.mainBlue,
              fontWeight: value === selected ? 600 : 500,
            }}
          >
            <S.LabelWrapper>
              <S.Icon src={icon} />
              <S.Label color={color}>{label}</S.Label>
            </S.LabelWrapper>
          </MenuItem>
        );
      })}
    </S.CustomSelect>
  );
}
