import styled from 'styled-components';
import playIcon from '../../assets/common/playIcon.svg';

export const SectionWrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { gray } }) => gray};
  max-width: 100rem;
  margin: 0 auto;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 38px;
  @media (min-width: 768px) {
    max-width: 605px;
  }
`;

export const Logo = styled.img`
  width: 99px;
  height: 85px;
  padding-bottom: 24px;
`;

export const WelcomeContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;
  word-break: break-all;
`;

export const WelcomeMessage = styled.div`
  font-size: 45px;
  line-height: 54px;
  font-family: Poppins;
  font-weight: 600;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const TextContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

export const Text = styled.div`
  font-size: 15px;
  line-height: 24px;
  font-family: Poppins;
  font-weight: 400;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const SendAnalysisButton = styled.button<{ isSideMenu?: boolean }>`
  min-width: 298px;
  font-size: ${({ isSideMenu }) => (isSideMenu ? '10px' : '15px')};
  line-height: 22.5px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  color: ${({ theme: { white } }) => white};
  background: linear-gradient(to right, #41bbff, #086ee7);
  padding: 12.5px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 20px #41bbff;
  }
  @media (min-width: 1100px) {
    font-size: ${({ isSideMenu }) => (isSideMenu ? '12px' : '15px')};
  }
  @media (min-width: 1150px) {
    font-size: ${({ isSideMenu }) => (isSideMenu ? '13px' : '15px')};
  }
  @media (min-width: 1250px) {
    font-size: 15px;
  }
`;
export const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme: { white } }) => white};
  border-radius: 4px;
  padding: 25px 10px 0;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const MovieContainer = styled.div`
  display: flex;
  padding-bottom: 24px;
  @media (min-width: 768px) {
    width: 294px;
    height: 221px;
    padding-left: 65px;
  }
`;

export const MediaTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  @media (min-width: 768px) {
    max-width: 210px;
    padding: 0 65px;
  }
  @media (min-width: 1026px) {
    max-width: 300px;
  }
  @media (min-width: 1400px) {
    max-width: 400px;
  }
`;

export const MediaTitleContainer = styled.div`
  display: flex;
  padding-bottom: 16px;
`;

export const Title = styled.div`
  font-size: 15px;
  line-height: 22.5px;
  font-family: Poppins;
  font-weight: 700;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const MediaBodyContainer = styled.div``;

export const MediaBody = styled.div`
  font-size: 15px;
  line-height: 24px;
  font-family: Poppins;
  font-weight: 400;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const PlayWrapper = styled.div`
  position: relative;
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  div {
    width: 100%;
    height: 100%;
  }
  img {
    border-radius: 8px;
    object-fit: cover;
  }
  &:hover {
    box-shadow: 0px 4px 39px #41bbff;
  }
`;
export const Image = styled.img``;

export const PlayIcon = styled.img.attrs({
  alt: 'play index',
  src: `${playIcon}`,
})`
  position: absolute;
  z-index: ${({
    theme: {
      zIndexes: { firstPlane },
    },
  }) => firstPlane};
  max-width: 28%;
`;
