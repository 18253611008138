import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeUsersPassword,
  submitOwnAccountForRemoval,
} from '../../../redux/auth/actions';
import { useIsLoading, userData } from '../../../redux/auth/selectors';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import BaseLoader from '../../../common/Loaders/BaseLoader';
import * as S from './SettingsForm.styles';

type Inputs = {
  password: string;
  newPassword: string;
  repeatedPassword: string;
};

const SettingsForm = () => {
  const { t } = useTranslation();
  const { email, surname, name } = useSelector(userData);
  const isLoading = useSelector(useIsLoading);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = ({
    password,
    newPassword,
    repeatedPassword,
  }) => {
    if (password.length < 10) {
      return toast.info(t('registerForm.minimumLengthInfo'));
    }
    if (newPassword !== repeatedPassword) {
      return toast.info(t('registerForm.passwordDontMatch'));
    }
    dispatch(changeUsersPassword(password, newPassword, t));
  };

  const hadleDeleteAccount = () => dispatch(submitOwnAccountForRemoval(t));

  return (
    <S.Background>
      <S.Wrapper>
        <S.Header>{t('settings.settings')}</S.Header>
        <S.FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <S.CustomInput
            label={t('settings.nameSurnamePlaceholder')}
            type={'text'}
            value={`${name} ${surname}`}
            disabled
          />
          <S.CustomInput
            label={t('settings.mailPlaceholder')}
            type={'email'}
            value={email}
            disabled
          />
          <S.CustomInput
            label={t('settings.currentPassword')}
            type={'password'}
            defaultValue={''}
            {...register('password')}
            error={!!errors.password}
            required
          />
          <S.CustomInput
            label={t('settings.newPassword')}
            type={'password'}
            defaultValue={''}
            {...register('newPassword')}
            error={!!errors.newPassword}
            required
          />
          <S.CustomInput
            label={t('settings.newPassword2')}
            type={'password'}
            defaultValue={''}
            {...register('repeatedPassword')}
            error={!!errors.repeatedPassword}
            required
          />
          <S.ButtonContainer>
            <S.SubmitButton type={'submit'}>
              {t('settings.changePassword')}
            </S.SubmitButton>
          </S.ButtonContainer>
        </S.FormWrapper>
        <S.RemoveButton onClick={hadleDeleteAccount}>
          {isLoading ? (
            <BaseLoader height={20} width={20} />
          ) : (
            <>
              <S.TrashIcon />
              {t('settings.deleteAccount')}
            </>
          )}
        </S.RemoveButton>
      </S.Wrapper>
    </S.Background>
  );
};

export default SettingsForm;
