import { useSelector } from 'react-redux';
import LoginRegisterPart from '../Authentication/LoginRegisterPart';
import { useMediaQuery } from 'react-responsive';
import { useUserLoggedIn } from '../../redux/auth/selectors';
import { Redirect } from 'react-router-dom';
import MobileLoginForm from '../Authentication/LoginForm/MobileLoginForm';
import LoginForm from '../Authentication/LoginForm/LoginForm';
import * as S from './LoginPage.styles';

const LoginPage = () => {
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isUserLoggedIn = useSelector(useUserLoggedIn);

  if (isUserLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <S.Wrapper>
      {isTablet ? (
        <>
          <LoginRegisterPart />
          <LoginForm />
        </>
      ) : (
        <MobileLoginForm />
      )}
    </S.Wrapper>
  );
};

export default LoginPage;
