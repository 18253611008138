import Icon from '../../assets/navbar/startPanelLogo.png';
import { useInView } from 'react-intersection-observer';
import { useState } from 'react';
import ImageModal from '../../common/ImageModal/ImageModal';
import VideoThumbnail from '../../assets/common/image 149.png';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUserLoggedIn, userData } from '../../redux/auth/selectors';
import { history } from '../../App';
import { useTranslation } from 'react-i18next';
import * as S from './StartPanel.styles';

const StartPanel = () => {
  const { t } = useTranslation();
  const isUserLoggedIn = useSelector(useUserLoggedIn);
  const { name, role } = useSelector(userData);
  const [playerRef] = useInView({ threshold: 1 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const canAddNewAnalysis = role !== 'admin';

  if (!isUserLoggedIn) {
    history.push('/login');
  }

  return (
    <S.Wrapper>
      <S.BodyContainer>
        <S.Logo src={Icon} />
        <S.WelcomeContainer>
          <S.WelcomeMessage>
            {t('startPanel.welcomeMessage')} {name}
          </S.WelcomeMessage>
        </S.WelcomeContainer>
        <S.TextContainer>
          <S.Text>{t('startPanel.thanksMessage')}</S.Text>
        </S.TextContainer>
        {canAddNewAnalysis && (
          <NavLink to="/submission/new">
            <S.SendAnalysisButton>
              {t('startPanel.startNewAnalysisCTA')}
            </S.SendAnalysisButton>
          </NavLink>
        )}
      </S.BodyContainer>
      <S.MediaContainer>
        <ImageModal
          type={'movie'}
          onClick={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          src={t('startPanel.movieLink')}
          onClose={() => setIsModalOpen(false)}
          title={''}
        />
        <S.MovieContainer ref={playerRef} onClick={() => setIsModalOpen(true)}>
          <S.PlayWrapper>
            <S.Image src={VideoThumbnail} alt={'movie thumbnail'} />
            <S.PlayIcon />
          </S.PlayWrapper>
        </S.MovieContainer>
        <S.MediaTextContainer>
          <S.MediaTitleContainer>
            <S.Title>{t('startPanel.instruction')}</S.Title>
          </S.MediaTitleContainer>
          <S.MediaBodyContainer>
            <S.MediaBody>{t('startPanel.howTo1')}</S.MediaBody>
            <S.MediaBody>{t('startPanel.howTo2')}</S.MediaBody>
            <S.MediaBody>{t('startPanel.howTo3')}</S.MediaBody>
            <S.MediaBody>{t('startPanel.howTo4')}</S.MediaBody>
          </S.MediaBodyContainer>
        </S.MediaTextContainer>
      </S.MediaContainer>
    </S.Wrapper>
  );
};

export default StartPanel;
