import styled from 'styled-components';
import { AiOutlineCheck } from 'react-icons/ai';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  height: 40px;
  @media (min-width: 768px) {
    height: 92px;
  }
`;

export const Step = styled.div<{
  isBlue?: boolean;
  isActive?: boolean;
  isFailure?: boolean;
  isGrey?: boolean;
}>`
  display: flex;
  width: 48px;
  height: 48px;
  background-color: ${({ isBlue, isGrey, isFailure, theme: { blue, white } }) =>
    isFailure ? '#FF4200' : isGrey ? 'grey' : isBlue ? blue : white};
  color: ${({ isBlue, theme: { white, grayFont } }) =>
    isBlue ? white : grayFont};
  font-family: Poppins;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ isActive, isGrey }) =>
    isGrey ? 'none' : isActive ? '0px 2px 14px #41bbff;' : 'none'};
  @media (min-width: 500px) {
    width: 56px;
    height: 56px;
  }
  @media (min-width: 768px) {
    width: ${({ isActive }) => (isActive ? '80px' : '64px')};
    height: ${({ isActive }) => (isActive ? '80px' : '64px')};
    font-size: 26px;
  }
`;

export const Progress = styled.div<{
  color: string;
  isLast: boolean;
  isGrey?: boolean;
}>`
  display: flex;
  margin: ${({ isLast, isGrey }) =>
    isLast ? '0px' : isGrey ? '0 -1px 0 0' : '0 -1px'};
  background-color: ${({ color, isGrey }) => (isGrey ? 'grey' : color)};
  width: 23px;
  height: 10px;
  @media (min-width: 500px) {
    width: 43px;
    height: 11px;
  }
  @media (min-width: 768px) {
    width: 63px;
    height: 11px;
  }
`;

export const TextsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 70px;
  @media (min-width: 768px) {
    max-width: 125px;
  }
`;

export const Text = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 22px;
`;

export const CheckMark = styled(AiOutlineCheck)``;
