import { ReactComponent as Tick } from '../../assets/authentication/check-mark.svg';
import MalwinaGrobelna from '../../assets/authentication/malwina-grobelna-small.png';
import { useTranslation } from 'react-i18next';
import * as S from './MobileLoginRegisterPart.style';

const MobileLoginRegisterPart = () => {
  const { t } = useTranslation();
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.TechnologyWrapper>
          <S.TechTextWrapper>
            <S.TitleWrapper>
              <S.TechTitleText>
                {t('loginRegisterPart.forWhom')}
              </S.TechTitleText>
            </S.TitleWrapper>
            <S.SupportWrapper>
              <S.TickIconWrapper>
                <Tick />
              </S.TickIconWrapper>
              <S.TechBodyContainer>
                <S.Body>{t('loginRegisterPart.step1')}</S.Body>
              </S.TechBodyContainer>
            </S.SupportWrapper>
            <S.SupportWrapper>
              <S.TickIconWrapper>
                <Tick />
              </S.TickIconWrapper>
              <S.TechBodyContainer>
                <S.Body>{t('loginRegisterPart.step2')}</S.Body>
              </S.TechBodyContainer>
            </S.SupportWrapper>
            <S.SupportWrapper>
              <S.TickIconWrapper>
                <Tick />
              </S.TickIconWrapper>
              <S.TechBodyContainer>
                <S.Body>{t('loginRegisterPart.step3')}</S.Body>
              </S.TechBodyContainer>
            </S.SupportWrapper>
            <S.SupportWrapper>
              <S.TickIconWrapper>
                <Tick />
              </S.TickIconWrapper>
              <S.TechBodyContainer>
                <S.Body>{t('loginRegisterPart.step4')}</S.Body>
              </S.TechBodyContainer>
            </S.SupportWrapper>
            <S.SupportWrapper>
              <S.TickIconWrapper>
                <Tick />
              </S.TickIconWrapper>
              <S.TechBodyContainer>
                <S.Body>{t('loginRegisterPart.step5')}</S.Body>
              </S.TechBodyContainer>
            </S.SupportWrapper>
          </S.TechTextWrapper>
        </S.TechnologyWrapper>
        <S.AboutUsWrapper>
          <S.TitleWrapper>
            <S.TechTitleText>{t('loginRegisterPart.aboutUs')}</S.TechTitleText>
          </S.TitleWrapper>
          <S.AboutUsTextWrapper>
            <S.Body>{t('loginRegisterPart.quote')}</S.Body>
          </S.AboutUsTextWrapper>
        </S.AboutUsWrapper>
        <S.DoctorWrapper>
          <S.PictureWrapper>
            <S.DoctorPhoto src={MalwinaGrobelna}></S.DoctorPhoto>
          </S.PictureWrapper>
          <S.TextWrapper>
            <S.TitleContainer>
              <S.TitleText>{t('loginRegisterPart.doctor')}</S.TitleText>
            </S.TitleContainer>
            <S.InfoContainer>
              <S.InfoText>{t('loginRegisterPart.specialization')}</S.InfoText>
            </S.InfoContainer>
          </S.TextWrapper>
        </S.DoctorWrapper>
        <S.ContactWrapper>
          <S.TextContainer>
            <S.Bolded>{t('loginRegisterPart.gotQuestions')}&nbsp;</S.Bolded>
            <S.Contact> {t('loginRegisterPart.contactUs')}&nbsp;</S.Contact>
            <S.ContactButton href={t('loginRegisterPart.contactLink')}>
              {t('loginRegisterPart.contact')}
            </S.ContactButton>
          </S.TextContainer>
        </S.ContactWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default MobileLoginRegisterPart;
