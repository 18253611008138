export const FETCH_SUBMISSIONS = 'FETCH_SUBMISSIONS';
export const FETCH_SUBMISSIONS_SUCCESS = 'FETCH_SUBMISSIONS_SUCCESS';

export const FETCH_SUBMISSION = 'FETCH_SUBMISSION';
export const GET_FILTERED_SUBMISSIONS = 'GET_FILTERED_SUBMISSIONS';
export const CREATE_SUBMISSION = 'CREATE_SUBMISSION';
export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS';
export const CREATE_RESULTS_SUBMISSION_SUCCESS =
  'CREATE_RESULTS_SUBMISSION_SUCCESS';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const CREATE_AND_UPLOAD_PHOTOS = 'CREATE_AND_UPLOAD_PHOTOS';
export const SET_FORM = 'SET_FORM';
export const UPLOAD_DICOMS = 'UPLOAD_DICOMS';
export const GET_UPLOAD_PROGRESS = 'GET_UPLOAD_PROGRESS';
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION';
export const RESTORE_SUBMISSION = 'RESTORE_SUBMISSION';
export const DESTROY_SUBMISSION = 'DESTROY_SUBMISSION';
export const UPLOAD_SUBMISSION_PHOTOS = 'UPLOAD_SUBMISSION_PHOTOS';
export const UPLOAD_SUBMISSION_PHOTOS_SUCCESS =
  'UPLOAD_SUBMISSION_PHOTOS_SUCCESS';
export const UPLOAD_SUBMISSION_DICOMS = 'UPLOAD_SUBMISSION_DICOMS';
export const UPLOAD_SUBMISSION_DICOMS_SUCCESS =
  'UPLOAD_SUBMISSION_DICOMS_SUCCESS';

export const CREATE_PAYMENT_SESSION_SUCCESS = 'CREATE_PAYMENT_SESSION_SUCCESS';
export const SET_DRAWER_SUBMISSION_ID = 'SET_DRAWER_SUBMISSION_ID';

export const SET_SEARCH_PARAMS = 'SET_SUBS_SEARCH_PARAMS';

export const UPLOAD_PERCENTAGE_CHANGED = 'UPLOAD_PERCENTAGE_CHANGED';
export const RESET_UPLOAD_PERCENTAGE = 'RESET_UPLOAD_PERCENTAGE';

export const MARK_SUBMISSION_AS_ARCHIVED = 'MARK_SUBMISSION_AS_ARCHIVED';
export const MARK_SUBMISSION_AS_ARCHIVED_SUCCESS =
  'MARK_SUBMISSION_AS_ARCHIVED';

export const RESTORE_ARCHIVED_SUBMISSION = 'RESTORE_ARCHIVED_SUBMISSION';
export const RESTORE_ARCHIVED_SUBMISSION_SUCCESS =
  'RESTORE_ARCHIVED_SUBMISSION_SUCCESS';

export const DESTROY_ARCHIVED_SUBMISSION = 'DESTROY_ARCHIVED_SUBMISSION';
export const DESTROY_ARCHIVED_SUBMISSION_SUCCESS =
  'DESTROY_ARCHIVED_SUBMISSION_SUCCESS';

export const REMINDER_SUBMISSION_FILES = 'REMINDER_SUBMISSION_FILES';
export const REMINDER_SUBMISSION_FILES_SUCCESS =
  'REMINDER_SUBMISSION_FILES_SUCCESS';

export const REMINDER_SUBMISSION_PAYMENT = 'REMINDER_SUBMISSION_PAYMENT';
export const REMINDER_SUBMISSION_PAYMENT_SUCCESS =
  'REMINDER_SUBMISSION_PAYMENT_SUCCESS';

export const REMOVE_NEW_STATUS = 'REMOVE_NEW_STATUS';

export const ARCHIVE_SUBMISSION = 'ARCHIVE_SUBMISSION';
export const ARCHIVE_SUBMISSION_SUCCESS = 'ARCHIVE_SUBMISSION_SUCCESS';

export const SORT_SUBMISSIONS_TABLE = 'SORT_SUBMISSIONS_TABLE';
