import { AppState } from '../store';

export const adminState = (state: AppState) => state.admin;

export const membersData = (state: AppState) => adminState(state).members;

export const isMembersLoading = (state: AppState) =>
  adminState(state).isLoadingMembers;

export const filteredMembers = (state: AppState) => {
  const {
    usersSearch: { page, perPage },
  } = state.admin;
  const query = adminState(state).usersSearch.query
    ? adminState(state).usersSearch.query.toLowerCase()
    : undefined;

  let filtered = adminState(state).members;
  if (query) {
    filtered = filtered.filter(
      (member) =>
        member.email.toLowerCase().includes(query) ||
        member.name.toLowerCase().includes(query) ||
        member.surname.toLowerCase().includes(query)
    );
  }

  const countedMembers = filtered.map((member, index: number) => ({
    ...member,
    count: index + 1,
  }));
  const firstIndex = page * perPage;
  const lastIndex = firstIndex + perPage;
  const results = countedMembers.slice(firstIndex, lastIndex);
  const count = countedMembers.length;
  const maxPage = Math.ceil(count / perPage);
  return { count, perPage, maxPage, results };
};

export const usersSearchParams = (state: AppState) => state.admin.usersSearch;

export const seoSearchParams = (state: AppState) => state.admin.seoSearch;

export const filteredSEOAlts = (state: AppState) => {
  const {
    seoSearch: { perPage, page },
  } = state.admin;
  const seoImageAlts = adminState(state).seoImageAlts;
  const query = adminState(state).seoSearch.query
    ? adminState(state).seoSearch.query.toLowerCase()
    : undefined;
  let filtered = seoImageAlts;
  if (query) {
    filtered = filtered.filter(
      (tag) =>
        tag.alt.toLowerCase().includes(query) ||
        tag.image_title.toLowerCase().includes(query)
    );
  }
  const countedSeoImageAlts = filtered.map((alt, index: number) => ({
    ...alt,
    count: index + 1,
  }));
  const firstIndex = page * perPage;
  const lastIndex = firstIndex + perPage;
  const results = countedSeoImageAlts.slice(firstIndex, lastIndex);
  const count = countedSeoImageAlts.length;
  const maxPage = Math.ceil(count / perPage);
  return { maxPage, results };
};

export const filteredSEOTags = (state: AppState) => {
  const {
    seoSearch: { perPage, page },
  } = state.admin;
  const seoTags = adminState(state).seoTags;
  const query = adminState(state).seoSearch.query
    ? adminState(state).seoSearch.query.toLowerCase()
    : undefined;

  let filtered = seoTags;
  if (query) {
    filtered = filtered.filter(
      (tag) =>
        tag.image.toLowerCase().includes(query) ||
        tag.title.toLowerCase().includes(query) ||
        tag.url.toLowerCase().includes(query)
    );
  }
  const countedSEOTags = filtered.map((tag, index: number) => ({
    ...tag,
    count: index + 1,
  }));
  const firstIndex = page * perPage;
  const lastIndex = firstIndex + perPage;
  const results = countedSEOTags.slice(firstIndex, lastIndex);
  const count = countedSEOTags.length;
  const maxPage = Math.ceil(count / perPage);
  return { maxPage, results };
};

export const useIsLoadingSendingEmail = (state: AppState) =>
  adminState(state).isLoadingSendingMails;
