import { useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchSubmissions } from '../../../redux/submission/actions';
import {
  SubmissionSortParams,
  SUBMISSION_FIELDS,
} from '../../../redux/submission/submissionReducer';
import * as S from './TableHeader.styles';

interface ITableHeader {
  sortBy: SubmissionSortParams;
  setSortBy: (value: SubmissionSortParams) => void;
}

export default function TableHeader({
  sortBy: { field, direction },
  setSortBy,
}: ITableHeader) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refresh = () => dispatch(fetchSubmissions());

  const [hoverField, setHoverField] = useState<undefined | SUBMISSION_FIELDS>();
  const isTablet = useMediaQuery('(min-width: 616px)');
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  if (!isTablet && !isDesktop) {
    return null;
  }

  const handleSort = (selectedField: SUBMISSION_FIELDS) => {
    if (selectedField === field && direction === 'asc') {
      return setSortBy({ field: selectedField, direction: 'desc' });
    }
    setSortBy({ field: selectedField, direction: 'asc' });
  };

  return (
    <S.Wrapper>
      <S.CounterCell>
        <S.SortByButton
          onMouseEnter={() => setHoverField(SUBMISSION_FIELDS.ID)}
          onMouseLeave={() => setHoverField(undefined)}
          onClick={() => handleSort(SUBMISSION_FIELDS.ID)}
        >
          <S.Label>{t('analysis.table.lp')}</S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SUBMISSION_FIELDS.ID,
              isUp: field === SUBMISSION_FIELDS.ID && direction === 'desc',
              isHover: hoverField === SUBMISSION_FIELDS.ID,
            }}
          />
        </S.SortByButton>
      </S.CounterCell>
      <S.HeaderCell>
        <S.SortByButton
          onMouseEnter={() => setHoverField(SUBMISSION_FIELDS.TITLE)}
          onMouseLeave={() => setHoverField(undefined)}
          onClick={() => handleSort(SUBMISSION_FIELDS.TITLE)}
        >
          <S.Label isHover={hoverField === SUBMISSION_FIELDS.TITLE}>
            {t('analysis.table.title')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SUBMISSION_FIELDS.TITLE,
              isUp: field === SUBMISSION_FIELDS.TITLE && direction === 'desc',
              isHover: hoverField === SUBMISSION_FIELDS.TITLE,
            }}
          />
        </S.SortByButton>
      </S.HeaderCell>
      <S.EmailCell>
        <S.SortByButton
          onMouseEnter={() => setHoverField(SUBMISSION_FIELDS.EMAIL)}
          onMouseLeave={() => setHoverField(undefined)}
          onClick={() => handleSort(SUBMISSION_FIELDS.EMAIL)}
        >
          <S.Label isHover={hoverField === SUBMISSION_FIELDS.EMAIL}>
            {t('analysis.table.email')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SUBMISSION_FIELDS.EMAIL,
              isUp: field === SUBMISSION_FIELDS.EMAIL && direction === 'desc',
              isHover: hoverField === SUBMISSION_FIELDS.EMAIL,
            }}
          />
        </S.SortByButton>
      </S.EmailCell>
      <S.QuantityCell>
        <S.SortByButton
          onMouseEnter={() => setHoverField(SUBMISSION_FIELDS.QUANTITY)}
          onMouseLeave={() => setHoverField(undefined)}
          onClick={() => handleSort(SUBMISSION_FIELDS.QUANTITY)}
        >
          <S.Label isHover={hoverField === SUBMISSION_FIELDS.QUANTITY}>
            {t('analysis.table.analysisNumber')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SUBMISSION_FIELDS.QUANTITY,
              isUp:
                field === SUBMISSION_FIELDS.QUANTITY && direction === 'desc',
              isHover: hoverField === SUBMISSION_FIELDS.QUANTITY,
            }}
          />
        </S.SortByButton>
      </S.QuantityCell>
      <S.DateCell>
        <S.SortByButton
          onMouseEnter={() => setHoverField(SUBMISSION_FIELDS.DATE)}
          onMouseLeave={() => setHoverField(undefined)}
          onClick={() => handleSort(SUBMISSION_FIELDS.DATE)}
        >
          <S.Label isHover={hoverField === SUBMISSION_FIELDS.DATE}>
            {t('analysis.table.creationDate')}
          </S.Label>
          <S.ArrowIcon
            props={{
              isVisible: field === SUBMISSION_FIELDS.DATE,
              isUp: field === SUBMISSION_FIELDS.DATE && direction === 'desc',
              isHover: hoverField === SUBMISSION_FIELDS.DATE,
            }}
          />
        </S.SortByButton>
      </S.DateCell>
      {isDesktop && (
        <>
          <S.StatusCell>
            <S.SortByButton
              onMouseEnter={() => setHoverField(SUBMISSION_FIELDS.STATUS)}
              onMouseLeave={() => setHoverField(undefined)}
              onClick={() => handleSort(SUBMISSION_FIELDS.STATUS)}
            >
              <S.Label isHover={hoverField === SUBMISSION_FIELDS.STATUS}>
                {t('analysis.table.analysisStatus')}
              </S.Label>
              <S.ArrowIcon
                props={{
                  isVisible: field === SUBMISSION_FIELDS.STATUS,
                  isUp:
                    field === SUBMISSION_FIELDS.STATUS && direction === 'desc',
                  isHover: hoverField === SUBMISSION_FIELDS.STATUS,
                }}
              />
            </S.SortByButton>
          </S.StatusCell>
          <S.ActionsCell>
            <S.Label>{t('analysis.table.actions')}</S.Label>
          </S.ActionsCell>
        </>
      )}
      <S.RefreshButtonContainer>
        <S.RefreshButton onClick={refresh} />
      </S.RefreshButtonContainer>
    </S.Wrapper>
  );
}
