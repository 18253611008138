import { IExtendedRow } from './TableRow';
import { useTranslation } from 'react-i18next';
import * as S from './TableRow.styles';

export default function TabletRow({
  email,
  files,
  formattedDate,
  isSelected,
  count,
  description,
  toggleIsArchived,
  isArchived,
  isReady,
  isTooltipOn,
  setIsTooltipOn,
  label: { background, color, message, icon },
  action,
  isTouchedByOperator,
  handleDownloadFile,
  buttonLabel,
  filesUploaded,
  isAdmin
}: IExtendedRow) {
  const { t } = useTranslation();
  const selectedBackground = isSelected ? '#fff' : background;
  const selectedColor = isSelected ? color : '#fff';
  const showDeleteTooltip = isTooltipOn && !isArchived && isAdmin;
  const showRestoreTooltip = isTooltipOn && isArchived && isAdmin;
  const showIcons = isAdmin;
  const quantity = filesUploaded?.resultFiles?.length || 0;
  const isAnalysisButtonDisabled = isArchived && !isAdmin

  return (
    <S.TabletWrapper
      isRemoved={isArchived}
      isSelected={isSelected}
      color={background}
    >
      <S.Border isRemoved={isArchived} color={selectedColor} />
      <S.Counter isSelected={isSelected}>{count}</S.Counter>
      <S.TabletBody>
        <S.TabletDataRow>
          <S.Header>{description}</S.Header>
          <S.DataValue isSelected={isSelected}>{email}</S.DataValue>
          <S.TabletQuantityValue isSelected={isSelected}>
            {quantity ? quantity : '-'}
          </S.TabletQuantityValue>
          <S.TabletDateValue isSelected={isSelected}>
            {formattedDate}
          </S.TabletDateValue>
        </S.TabletDataRow>
        <S.ButtonsRow>
          <S.TabletSpan>
            <S.TabletLabelWrapper
              isGrayedOut={isArchived}
              backgroundColor={selectedBackground}
            >
              <S.LabelIcon src={icon} alt={t(message)} />
              <S.LabelText color={color}>{t(message)}</S.LabelText>
            </S.TabletLabelWrapper>
          </S.TabletSpan>
          {/* <S.AddAnalysisButton isReady={isReady && !isArchived} isRemoved={isArchived}>
              {isArchived && t('analysis.buttons.delete')}
              {!isArchived && !isReady && t('analysis.buttons.add')}
              {isReady && !isArchived && t('analysis.buttons.change')}
            </S.AddAnalysisButton> */}
          {true ? (
            <S.DownloadIcon onClick={handleDownloadFile} />
          ) : (
            <div style={{ width: '16px', paddingRight: '2px' }}></div>
          )}
          {!isAnalysisButtonDisabled && action && buttonLabel && (
            <S.AddAnalysisButton
              isReady={isReady && !isArchived}
              isRemoved={isArchived}
              onClick={action}
            >
              {buttonLabel}
            </S.AddAnalysisButton>
          )}
          <S.RemoveButton
            onMouseDown={() => setIsTooltipOn(true)}
            onMouseUp={() => setIsTooltipOn(false)}
            onClick={toggleIsArchived}
          >
            {showDeleteTooltip && (
              <S.ArchiveTooltip>
                {t('analysis.table.reportDelete')}
              </S.ArchiveTooltip>
            )}
            {showRestoreTooltip && (
              <S.RestoreTooltip>{t('analysis.table.restore')}</S.RestoreTooltip>
            )}
            {showIcons && (isArchived ? <S.RestoreIcon /> : <S.TrashIcon />)}
          </S.RemoveButton>
        </S.ButtonsRow>
      </S.TabletBody>
    </S.TabletWrapper>
  );
}
