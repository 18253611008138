import { ISEOImageAlt } from '../../components/SEO/SEOTableImages/SEOTableImagesRow/SEOTableImagesRow';
import { ISEOTags } from '../../components/SEO/SEOTableTags/SEOTableTagsRow/SEOTableTagsRow';

export interface Member {
  id: string;
  email: string;
  name: string;
  surname: string;
  created_at: string;
  role: string;
  isAccountConfirmed: boolean;
  isArchived: boolean;
  phoneNumber: string;
  registeredLocale: string;
}

export interface searchParams {
  query: string;
  perPage: number;
  page: number;
  dateMin?: string;
  dateMax?: string;
}

const emptySearchValues = {
  query: '',
  perPage: 10,
  page: 0,
};

export const adminReducerInitialState: AdminReducerState = {
  members: [],
  seoImageAlts: [],
  seoTags: [],
  isLoadingImageAlts: false,
  isLoadingMembers: false,
  isLoadingSendingMails: false,
  usersSearch: emptySearchValues,
  seoSearch: emptySearchValues,
};

export interface AdminReducerState {
  members: Member[];
  seoImageAlts: ISEOImageAlt[];
  seoTags: ISEOTags[];
  isLoadingImageAlts: boolean;
  isLoadingMembers: boolean;
  isLoadingSendingMails: boolean;
  usersSearch: searchParams;
  seoSearch: searchParams;
}
