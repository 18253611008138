import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Collapsible from 'react-collapsible';
import VideoThumbnail from '../../assets/common/image 149.png';
import AccordionCollapsedIcon from '../../assets/common/accordion-collapsed.png';
import AccordionExpandedIcon from '../../assets/common/accordion-expanded.png';
import ImageModal from '../../common/ImageModal/ImageModal';
import { useTranslation } from 'react-i18next';
import * as S from './HelpPage.styles';

const HelpPage = () => {
  const { t } = useTranslation();
  const [playerRef] = useInView({ threshold: 1 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedElements, setExpandedElements] = useState<any[]>([]);

  const triggerStyle = {
    cursor: 'pointer',
    padding: '0 32px',
  };

  const triggerProps = {
    triggerStyle,
    transitionTime: 300,
    easing: 'ease-in',
  };

  const addElementToExpandedList = (key: string) => {
    setExpandedElements([...expandedElements, key]);
  };

  const removeElementFromExpandedList = (key: string) => {
    setExpandedElements([...expandedElements.filter((e) => e !== key)]);
  };

  const getIconForElementByKey = (key: string) =>
    expandedElements.includes(key)
      ? AccordionExpandedIcon
      : AccordionCollapsedIcon;

  const Row = (text: string, key: string) => (
    <S.RowContainer>
      <S.Question>{text}</S.Question>
      <S.ExpandedStatus>
        <S.Icon alt="status" src={getIconForElementByKey(key)} />
      </S.ExpandedStatus>
    </S.RowContainer>
  );

  return (
    // <S.Background>
    <S.Wrapper>
      <S.Header>{t('help.help')}</S.Header>
      <S.CollapsibleWrapper>
        <Collapsible
          {...triggerProps}
          onOpening={() => addElementToExpandedList('1')}
          onClosing={() => removeElementFromExpandedList('1')}
          trigger={Row(t('help.question1'), '1')}
        >
          <S.CollapsibleBody>
            <S.MediaBody>{t('help.answer1')}</S.MediaBody>
            <ImageModal
              type={'movie'}
              onClick={() => setIsModalOpen(false)}
              isOpen={isModalOpen}
              src={t('startPanel.movieLink')}
              onClose={() => setIsModalOpen(false)}
              title={''}
            />
            <S.MediaBodyContainer>
              <S.MovieContainer
                ref={playerRef}
                onClick={() => setIsModalOpen(true)}
              >
                <S.PlayWrapper>
                  <S.Image src={VideoThumbnail} alt={'movie thumbnail'} />
                  <S.Veil />
                  <S.PlayIcon />
                </S.PlayWrapper>
              </S.MovieContainer>
              <S.MediaBody>{t('help.answer11')}</S.MediaBody>
            </S.MediaBodyContainer>
          </S.CollapsibleBody>
        </Collapsible>
        <S.CollapsibleBreak />
      </S.CollapsibleWrapper>
      <S.CollapsibleWrapper>
        <Collapsible
          {...triggerProps}
          trigger={Row(t('help.question2'), '2')}
          onOpening={() => addElementToExpandedList('2')}
          onClosing={() => removeElementFromExpandedList('2')}
        >
          <S.FaqAnswer>{t('help.answer2')}</S.FaqAnswer>
        </Collapsible>
        <S.CollapsibleBreak />
      </S.CollapsibleWrapper>
      <S.CollapsibleWrapper>
        <Collapsible
          {...triggerProps}
          trigger={Row(t('help.question3'), '3')}
          onOpening={() => addElementToExpandedList('3')}
          onClosing={() => removeElementFromExpandedList('3')}
        >
          <S.FaqAnswer>{t('help.answer3')}</S.FaqAnswer>
        </Collapsible>
        <S.CollapsibleBreak />
      </S.CollapsibleWrapper>
      <S.CollapsibleWrapper>
        <Collapsible
          {...triggerProps}
          trigger={Row(t('help.question4'), '4')}
          onOpening={() => addElementToExpandedList('4')}
          onClosing={() => removeElementFromExpandedList('4')}
        >
          <S.FaqAnswer>{t('help.answer4')}</S.FaqAnswer>
        </Collapsible>
        <S.CollapsibleBreak />
      </S.CollapsibleWrapper>
      <S.ContactWrapper>
        <S.QuestionMarkIcon />
        <S.Contact>
          {t('help.contact')}
          <S.ContactLink href={t('help.contactLink')}>
            {t('help.here')}
          </S.ContactLink>
        </S.Contact>
      </S.ContactWrapper>
    </S.Wrapper>
    // </S.Background>
  );
};

export default HelpPage;
