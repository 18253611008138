import { AppState } from '../store';
import { IModalState } from './types';

export const utilsState = (state: AppState) => state.utils;

export const isModalOpen = (name: string) => (state: AppState) => {
  const maybeState = utilsState(state).modalsActiveStates.find(
    (modalState: IModalState) => modalState.name === name
  );
  if (!maybeState || !maybeState.isOpen) {
    return false;
  }
  return maybeState.isOpen;
};
