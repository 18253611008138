export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGIN_USER_LOCKED_OUT = 'LOGIN_USER_LOCKED_OUT';
export const RESET_USER_SIGN_IN_ERROR = 'RESET_USER_SIGN_IN_ERROR';

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const USER_CONTACT_UPDATE_SUCCESS = 'USER_CONTACT_UPDATE_SUCCESS';
export const SET_SIGN_IN_ERROR_MESSAGE = 'SET_SIGN_IN_ERROR_MESSAGE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SET_LOADING_DATA = 'SET_LOADING_DATA';
export const SET_LOADING_DATA_SUCCESS = 'SET_LOADING_DATA_SUCCESS';
export const SET_PASSWORD_ERROR_MESSAGE = 'SET_PASSWORD_ERROR_MESSAGE';
export const SET_VERIFIED_USER = 'SET_VERIFIED_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_FAILED = 'CONFIRM_ACCOUNT_FAILED';

export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  'MARK_NOTIFICATION_AS_READ_SUCCESS';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const SUBMIT_ACCOUNT_FOR_REMOVAL = 'SUBMIT_ACCOUNT_FOR_REMOVAL';
export const SUBMIT_ACCOUNT_FOR_REMOVAL_SUCCESS =
  'SUBMIT_ACCOUNT_FOR_REMOVAL_SUCCESS';
export const SUBMIT_ACCOUNT_FOR_REMOVAL_FAILED =
  'SUBMIT_ACCOUNT_FOR_REMOVAL_FAILED';

export const SUBMIT_ACCOUNT_PASSWORD_RESET = 'SUBMIT_ACCOUNT_PASSWORD_RESET';
export const SUBMIT_ACCOUNT_PASSWORD_RESET_SUCCESS =
  'SUBMIT_ACCOUNT_PASSWORD_RESET_SUCCESS';
export const SUBMIT_ACCOUNT_PASSWORD_RESET_FAILED =
  'SUBMIT_ACCOUNT_PASSWORD_RESET_FAILED';

export const SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION =
  'SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION';
export const SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION_SUCCESS =
  'SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION_SUCCESS';
export const SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION_FAILURE =
  'SUBMIT_ACCOUNT_PASSWORD_RESET_CONFIRMATION_FAILURE';
