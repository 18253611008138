import { getSortedSeoTags } from '../../utils/seo';
import * as A from './actionTypes';
import { adminReducerInitialState, AdminReducerState } from './types';

export enum USER_FIELDS {
  EMAIL = 'email',
  NAME = 'name',
  SURNAME = 'surname',
  CREATION = 'creation',
  CONFIRMED = 'confirmed',
  ARCHIVED = 'archived',
  ACTIONS = 'actions',
  LOCALE = 'locale',
}

export interface UserSortParams {
  field: USER_FIELDS;
  direction: 'asc' | 'desc';
}

export enum SEO_TAGS_FIELD {
  TITLE = 'TITLE',
  IMAGE = 'IMAGE',
  URL = 'URL',
  LOCALE = 'LOCALE',
}

export interface SEOTagsSortParams {
  field: SEO_TAGS_FIELD;
  direction: 'asc' | 'desc';
}

export const enum SEO_IMAGES_FIELD {
  IMAGE_TITLE = 'IMAGE_TITLE',
  ALT = 'ALT',
}

export interface SEOImagesSortParams {
  field: SEO_IMAGES_FIELD;
  direction: 'asc' | 'desc';
}

const AdminReducer = (
  state = adminReducerInitialState,
  action: any
): AdminReducerState => {
  switch (action.type) {
    case A.FETCH_USERS: {
      return {
        ...state,
        isLoadingMembers: true,
      };
    }
    case A.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        isLoadingMembers: false,
        members: action.data,
      };
    }
    case A.FETCH_USERS_FAIL: {
      return {
        ...state,
        isLoadingMembers: false,
      };
    }
    case A.SET_SEARCH_USERS_PARAMS: {
      const data = action.data;
      return {
        ...state,
        usersSearch: {
          ...state.usersSearch,
          ...data,
        },
      };
    }
    case A.SET_SEARCH_SEO_PARAMS: {
      const data = action.data;
      return {
        ...state,
        seoSearch: {
          ...state.seoSearch,
          ...data,
        },
      };
    }
    case A.SORT_USERS_TABLE: {
      const { direction, field } = action.data as {
        direction: 'desc' | 'asc';
        field: USER_FIELDS;
      };
      let userList = [...state.members];

      const sortStrings = (a: string, b: string) =>
        a.toUpperCase().localeCompare(b.toUpperCase());
      const isAsc = direction === 'desc';
      if (field === USER_FIELDS.CREATION) {
        userList = isAsc
          ? userList.sort(
              (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
            )
          : userList.sort(
              (a, b) => +new Date(a.created_at) - +new Date(b.created_at)
            );
      }
      if (field === USER_FIELDS.EMAIL) {
        userList = userList.sort(
          ({ email: aEmail = '' }, { email: bEmail = '' }) =>
            isAsc ? sortStrings(aEmail, bEmail) : sortStrings(bEmail, aEmail)
        );
      }
      if (field === USER_FIELDS.NAME) {
        userList = userList.sort(({ name: aName = '' }, { name: bName = '' }) =>
          isAsc ? sortStrings(aName, bName) : sortStrings(bName, aName)
        );
      }
      if (field === USER_FIELDS.SURNAME) {
        userList = userList.sort(
          ({ surname: aSurname = '' }, { surname: bSurname = '' }) =>
            isAsc
              ? sortStrings(aSurname, bSurname)
              : sortStrings(bSurname, aSurname)
        );
      }
      if (field === USER_FIELDS.LOCALE) {
        userList = userList.sort(
          (
            { registeredLocale: aLocale = '' },
            { registeredLocale: bLocale = '' }
          ) =>
            isAsc
              ? sortStrings(aLocale, bLocale)
              : sortStrings(bLocale, aLocale)
        );
      }
      if (field === USER_FIELDS.CONFIRMED) {
        userList = isAsc
          ? userList.sort(
              (
                { isAccountConfirmed: isAccountConfirmedA },
                { isAccountConfirmed: isAccountConfirmedB }
              ) =>
                isAccountConfirmedA === isAccountConfirmedB
                  ? 0
                  : isAccountConfirmedB
                  ? -1
                  : 1
            )
          : userList.sort(
              (
                { isAccountConfirmed: isAccountConfirmedB },
                { isAccountConfirmed: isAccountConfirmedA }
              ) =>
                isAccountConfirmedA === isAccountConfirmedB
                  ? 0
                  : isAccountConfirmedB
                  ? -1
                  : 1
            );
      }
      if (field === USER_FIELDS.ARCHIVED) {
        userList = isAsc
          ? userList.sort(
              ({ isArchived: isArchivedA }, { isArchived: isArchivedB }) =>
                isArchivedA === isArchivedB ? 0 : isArchivedB ? -1 : 1
            )
          : userList.sort(
              ({ isArchived: isArchivedB }, { isArchived: isArchivedA }) =>
                isArchivedA === isArchivedB ? 0 : isArchivedB ? -1 : 1
            );
      }
      return {
        ...state,
        members: userList,
      };
    }
    case A.FETCH_IMAGE_ALTS: {
      return {
        ...state,
        isLoadingImageAlts: true,
      };
    }
    case A.FETCH_IMAGE_ALTS_SUCCESS: {
      return {
        ...state,
        isLoadingImageAlts: false,
        seoImageAlts: action.data,
      };
    }
    case A.FETCH_IMAGE_ALTS_FAIL: {
      return {
        ...state,
        isLoadingImageAlts: false,
      };
    }
    case A.FETCH_OG_TAGS: {
      return {
        ...state,
        isLoadingImageAlts: true,
      };
    }
    case A.FETCH_OG_TAGS_SUCCESS: {
      return {
        ...state,
        isLoadingImageAlts: false,
        seoTags: getSortedSeoTags(action.data),
      };
    }
    case A.FETCH_OG_TAGS_FAIL: {
      return {
        ...state,
        isLoadingImageAlts: false,
      };
    }
    case A.RESET_SEARCH_PARAMS: {
      const data = action.data;
      return {
        ...state,
        usersSearch: {
          ...state.usersSearch,
          ...data,
        },
        seoSearch: {
          ...state.usersSearch,
          ...data,
        },
      };
    }
    case A.SORT_SEO_TAGS_TABLE: {
      const { direction, field } = action.data as {
        direction: 'desc' | 'asc';
        field: SEO_TAGS_FIELD;
      };
      let seoTagsList = [...state.seoTags];

      const sortStrings = (a: string, b: string) =>
        a.toUpperCase().localeCompare(b.toUpperCase());
      const isAsc = direction === 'desc';
      if (field === SEO_TAGS_FIELD.TITLE) {
        seoTagsList = seoTagsList.sort(
          ({ title: aTitle = '' }, { title: bTitle = '' }) =>
            isAsc ? sortStrings(aTitle, bTitle) : sortStrings(bTitle, aTitle)
        );
      }
      if (field === SEO_TAGS_FIELD.IMAGE) {
        seoTagsList = seoTagsList.sort(
          ({ image: aImage = '' }, { image: bImage = '' }) =>
            isAsc ? sortStrings(aImage, bImage) : sortStrings(bImage, aImage)
        );
      }
      if (field === SEO_TAGS_FIELD.URL) {
        seoTagsList = seoTagsList.sort(
          ({ url: aUrl = '' }, { url: bUrl = '' }) =>
            isAsc ? sortStrings(aUrl, bUrl) : sortStrings(bUrl, aUrl)
        );
      }
      if (field === SEO_TAGS_FIELD.LOCALE) {
        seoTagsList = seoTagsList.sort(
          ({ locale: aLocale = '' }, { locale: bLocale = '' }) =>
            isAsc
              ? sortStrings(aLocale, bLocale)
              : sortStrings(bLocale, aLocale)
        );
      }
      return {
        ...state,
        seoTags: seoTagsList,
      };
    }
    case A.SORT_SEO_IMAGES_TABLE: {
      const { direction, field } = action.data as {
        direction: 'desc' | 'asc';
        field: SEO_IMAGES_FIELD;
      };
      let seoImagesList = [...state.seoImageAlts];

      const sortStrings = (a: string, b: string) =>
        a.toUpperCase().localeCompare(b.toUpperCase());
      const isAsc = direction === 'desc';
      if (field === SEO_IMAGES_FIELD.IMAGE_TITLE) {
        seoImagesList = seoImagesList.sort(
          ({ image_title: aTitle = '' }, { image_title: bTitle = '' }) =>
            isAsc ? sortStrings(aTitle, bTitle) : sortStrings(bTitle, aTitle)
        );
      }
      if (field === SEO_IMAGES_FIELD.ALT) {
        seoImagesList = seoImagesList.sort(
          ({ alt: aAlt = '' }, { alt: bAlt = '' }) =>
            isAsc ? sortStrings(aAlt, bAlt) : sortStrings(bAlt, aAlt)
        );
      }
      return {
        ...state,
        seoImageAlts: seoImagesList,
      };
    }
    case A.UPDATE_OG_IMAGE_SUCCESS: {
      const { id } = action.data;
      const newSeoTags = state.seoTags.map((s) => {
        if (s.id !== id) return s;
        return {
          ...s,
          ...action.data,
        };
      });
      return {
        ...state,
        seoTags: newSeoTags,
      };
    }
    case A.UPDATE_IMAGE_ALTS_SUCCESS: {
      const { id } = action.data;
      const newSeoImageAlts = state.seoImageAlts.map((s) => {
        if (s.id !== id) return s;
        return {
          ...s,
          ...action.data,
        };
      });
      return {
        ...state,
        seoImageAlts: newSeoImageAlts,
      };
    }

    case A.ARCHIVE_USER_SUCCESS: {
      const { id } = action.data;
      const elementIndex = state.members.findIndex((l) => l.id === id);

      if (elementIndex === -1) {
        return state;
      }

      const newMembersList = [
        ...state.members.slice(0, elementIndex),
        {
          ...state.members[elementIndex],
          isArchived: true,
        },
        ...state.members.slice(elementIndex + 1),
      ];

      return {
        ...state,
        members: newMembersList,
      };
    }

    case A.UNARCHIVE_USER_SUCCESS: {
      const { id } = action.data;
      const elementIndex = state.members.findIndex((l) => l.id === id);

      if (elementIndex === -1) {
        return state;
      }

      const newMembersList = [
        ...state.members.slice(0, elementIndex),
        {
          ...state.members[elementIndex],
          isArchived: false,
        },
        ...state.members.slice(elementIndex + 1),
      ];

      return {
        ...state,
        members: newMembersList,
      };
    }
    case A.SEND_TOS_CHANGED_MAIL: {
      return {
        ...state,
        isLoadingSendingMails: true,
      };
    }
    case A.SEND_TOS_CHANGED_MAIL_SUCCESS: {
      return {
        ...state,
        isLoadingSendingMails: false,
      };
    }
    case A.SEND_TOS_CHANGED_MAIL_FAILED: {
      return {
        ...state,
        isLoadingSendingMails: false,
      };
    }
    case A.SEND_PRIVACY_CHANGED_MAIL: {
      return {
        ...state,
        isLoadingSendingMails: true,
      };
    }
    case A.SEND_PRIVACY_CHANGED_MAIL_SUCCESS: {
      return {
        ...state,
        isLoadingSendingMails: false,
      };
    }
    case A.SEND_PRIVACY_CHANGED_MAIL_FAILED: {
      return {
        ...state,
        isLoadingSendingMails: false,
      };
    }
    default:
      return state;
  }
};

export default AdminReducer;
