import styled from 'styled-components';
import { AiOutlineArrowLeft } from 'react-icons/ai';

export const Wrapper = styled.section`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-size: 15px;
  line-height: 20.8px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  padding: 0 32px 37px;
`;

export const NameContainer = styled.div`
  padding-top: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  padding-bottom: 8px;
`;

export const ResultsInfo = styled.div`
  text-align: center;
  font-size: 24px;
  padding: 50px 0;
  font-weight: 500;
`;

export const ResultsName = styled.span`
  font-weight: 700;
`;

export const NameInput = styled.input``;

export const DescriptionContainer = styled.div``;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
`;

export const InfoWrapper = styled.div`
  padding-bottom: 43px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Info = styled.div`
  color: ${({ theme: { grayInfo } }) => grayInfo};
  font-size: 13px;
  /* padding: 0 0 17px 14px; */
`;

export const NextStepButton = styled.button<{ disabled?: boolean }>`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  letter-spacing: 1px;
  justify-content: center;
  color: ${({ theme: { white } }) => white};
  background: ${({ disabled, theme: { grayFont, blue, gradientBlue } }) =>
    disabled
      ? grayFont
      : `linear-gradient(to right, ${blue}, ${gradientBlue})`};
  padding: 12.5px 45px;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 41px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  max-width: 325px;
  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '' : '0px 4px 39px #41bbff')};
  }
  @media (min-width: 768px) {
    min-width: 215px;
    padding: 11.5px 8px;
    margin: auto 0 auto auto;
    order: 2;
  }
`;

export const BackButton = styled.button<{ isDisabled?: boolean }>`
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  /* color: ${({ theme: { mainBlue } }) => mainBlue}; */
  color: ${({ isDisabled, theme: { mainBlue } }) =>
    !isDisabled ? mainBlue : 'gray'};
  border: none;
  text-transform: uppercase;
  background-color: inherit;
  cursor: ${({ isDisabled }) => (!isDisabled ? 'pointer' : 'auto')};
  @media (min-width: 768px) {
    order: 1;
  }
`;

export const BackArrow = styled(AiOutlineArrowLeft)`
  color: ${({ theme: { mainBlue } }) => mainBlue}
  padding-right: 8px;
  width: 25px;
  height: 25px;
`;
export const BackArrowDisabled = styled(AiOutlineArrowLeft)`
  color: 'gray';
  padding-right: 8px;
  width: 25px;
  height: 25px;
`;

export const StyledInput = styled.input`
  padding: 12px 21px;
  border-radius: 4px;
  border: 1px solid ${({ theme: { grayFont } }) => grayFont};
  font-family: Poppins;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 32px;
  :focus {
    outline: none;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LabelContainer = styled.label`
  cursor: pointer;
  display: flex;
`;

export const CustomContainer = styled.div`
  display: flex;
  min-width: 25px;
  padding-right: 8px;
`;

export const StyledCheckbox = styled.input`
  cursor: pointer;
  width: 21px;
  height: 21px;
  margin: 0 0 0 1px;
  /* border: 1px;
border-style: solid;
border-color: #C9C9C9;
background: #F4F5F9;
appearance: none; */
`;
export const InfoModal = styled.div`
  font-family: Poppins;
  font-size: 16px;
  color: rgb(228, 5, 55);
  text-align: center;
  width: 100%;
  margin-bottom: 9px;
`;
