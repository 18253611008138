import styled from 'styled-components';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export const ExternalWrapper = styled.div`
  position: relative;
  background-color: #07005c;
  height: 100%;
  z-index: ${({
    theme: {
      zIndexes: { background },
    },
  }) => background};
`;

export const Wrapper = styled.div`
  justify-content: center;
  padding: 15px 25px;
  @media (min-width: 1200px) {
    padding: 90px 50px;
  }
  @media (min-width: 1600px) {
    padding: 120px 160px;
  }
`;

export const TechnologyWrapper = styled.div``;

export const TickIconWrapper = styled.div`
  min-width: 45px;
  margin-right: 4px;
`;

export const Tick = styled.img`
  height: 24px;
  width: 24px;
`;

export const TechTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  padding: 20px 0 20px 0;
`;

export const TechTitleText = styled.div`
  font-family: Poppins;
  font-size: 22px;
  line-height: 31.2px;
  color: #ffffff;
`;

export const SupportWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 0;
`;

export const TechBodyContainer = styled.div``;

export const Body = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 300;
`;

export const AboutUsWrapper = styled.div``;

export const AboutUsTextWrapper = styled.div``;

export const DoctorWrapper = styled.div`
  display: flex;
  padding-top: 25px;
  @media (min-width: 1600px) {
    padding-top: 30px;
  }
`;

export const PictureWrapper = styled.div`
  width: 60px;
  margin-right: 10px;
  margin-top: auto;
`;

export const DoctorPhoto = styled.img`
  border-radius: 50%;
`;

export const TextWrapper = styled.div``;

export const TitleContainer = styled.div``;

export const TitleText = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 21px;
  line-height: 25.5px;
  color: #fff;
`;

export const InfoContainer = styled.div``;

export const InfoText = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 1px;
  background: -webkit-linear-gradient(left, #41bbff, #086ee7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ContactWrapper = styled.div`
  display: flex;
  padding-top: 30px;
`;

export const TextContainer = styled.div`
  display: flex;
  font-family: Poppins;
  font-size: 14px;
  color: white;
  align-items: center;
`;

export const Bolded = styled.span`
  font-weight: 600;
`;

export const Contact = styled.span`
  padding-right: 4px;
`;

export const ContactButton = styled.a`
  display: flex;
  border-radius: 4px;
  border: 1px solid white;
  color: white;
  background: transparent;
  text-transform: uppercase;
  padding: 4px 6px;
  text-decoration: none;
  font-weight: 600;
  font-size: 10px;
  &:hover {
    box-shadow: 0 0 8px white;
  }
`;
