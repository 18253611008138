import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SEOImagesSortParams, SEO_IMAGES_FIELD } from '../../../../redux/admin/adminReducer';
import * as S from './SEOTableImagesHeader.styles';

interface ISEOImagesTableHeader {
  sortBy: SEOImagesSortParams;
  setSortBy: (value: SEOImagesSortParams) => void;
}

const SEOTableImagesHeader = ({
  sortBy: { field, direction },
  setSortBy,
}: ISEOImagesTableHeader) => {
  const { t } = useTranslation();
  const [hoverField, setHoverField] = useState<undefined | SEO_IMAGES_FIELD>();
  const handleSort = (selectedField: SEO_IMAGES_FIELD) => {
    if (selectedField === field && direction === 'asc') {
      return setSortBy({ field: selectedField, direction: 'desc' });
    }
    setSortBy({ field: selectedField, direction: 'asc' });
  };
  return (
    <S.TableHeaderWrapper>
      <S.TitleContainer><S.SortByButton
        onClick={() => handleSort(SEO_IMAGES_FIELD.IMAGE_TITLE)}
        onMouseEnter={() => setHoverField(SEO_IMAGES_FIELD.IMAGE_TITLE)}
        onMouseLeave={() => setHoverField(undefined)}>
        <S.Label isHover={hoverField === SEO_IMAGES_FIELD.IMAGE_TITLE}>{t('seo.tableImages.title')}</S.Label>
        <S.ArrowIcon
          props={{
            isVisible: field === SEO_IMAGES_FIELD.IMAGE_TITLE,
            isUp: field === SEO_IMAGES_FIELD.IMAGE_TITLE && direction === 'desc',
            isHover: hoverField === SEO_IMAGES_FIELD.IMAGE_TITLE,
          }}
        />
      </S.SortByButton></S.TitleContainer>
      <S.AltContainer><S.SortByButton
        onClick={() => handleSort(SEO_IMAGES_FIELD.ALT)}
        onMouseEnter={() => setHoverField(SEO_IMAGES_FIELD.ALT)}
        onMouseLeave={() => setHoverField(undefined)}>
        <S.Label isHover={hoverField === SEO_IMAGES_FIELD.ALT}>{t('seo.tableImages.alt')}</S.Label>
        <S.ArrowIcon
          props={{
            isVisible: field === SEO_IMAGES_FIELD.ALT,
            isUp: field === SEO_IMAGES_FIELD.ALT && direction === 'desc',
            isHover: hoverField === SEO_IMAGES_FIELD.ALT,
          }}
        />
      </S.SortByButton></S.AltContainer>
    </S.TableHeaderWrapper>
  );
};

export default SEOTableImagesHeader;
