import { IExtendedRow } from './TableRow';
import { useTranslation } from 'react-i18next';
import * as S from './TableRow.styles';

export default function MobileRow({
  email,
  // quantity,
  isSelected,
  count,
  description,
  toggleIsArchived,
  isArchived,
  isReady,
  formattedDate,
  buttonLabel,
  isTooltipOn,
  setIsTooltipOn,
  label: { background, color, message, icon },
  action,
  filesUploaded,
  isTouchedByOperator,
  handleDownloadFile,
  isAdmin
}: IExtendedRow) {
  const { t } = useTranslation();
  const selectedBackground = isSelected ? '#fff' : background;
  const selectedColor = isSelected ? color : '#fff';
  const showDeleteTooltip = isTooltipOn && !isArchived && isAdmin;
  const showRestoreTooltip = isTooltipOn && isArchived && isAdmin;
  const showIcons = isAdmin;
  const quantity = filesUploaded?.resultFiles?.length || 0;
  const isAnalysisButtonDisabled = isArchived && !isAdmin

  return (
    <S.MobileWrapper
      isRemoved={isArchived}
      isSelected={isSelected}
      color={background}
    >
      <S.Border isRemoved={isArchived} color={selectedColor} />
      <S.HeaderRow>
        <S.Counter isSelected={isSelected}>{count}</S.Counter>
        <S.Header>{description}</S.Header>
      </S.HeaderRow>
      <S.DataRow>
        <S.DataLabel>{t('analysis.table.email')}</S.DataLabel>
        <S.DataValue isSelected={isSelected}>{email}</S.DataValue>
      </S.DataRow>
      <S.DataRow>
        <S.DataLabel>{t('analysis.table.analysisNumber')}</S.DataLabel>
        <S.DataValue isSelected={isSelected}>
          {quantity ? quantity : '-'}
        </S.DataValue>
      </S.DataRow>
      <S.DataRow>
        <S.DataLabel>{t('analysis.table.creationDate')}</S.DataLabel>
        <S.DataValue isSelected={isSelected}>{formattedDate}</S.DataValue>
      </S.DataRow>
      <S.DataRow>
        <S.DataLabel>{t('analysis.table.analysisStatus')}</S.DataLabel>
        <S.LabelWrapper
          isGrayedOut={isArchived}
          backgroundColor={selectedBackground}
        >
          <S.LabelIcon src={icon} alt={t(message)} />
          <S.LabelText color={color}>{t(message)}</S.LabelText>
        </S.LabelWrapper>
      </S.DataRow>
      <S.ButtonsRow>
        {/* <S.AddAnalysisButton isReady={isReady && !isArchived} isRemoved={isArchived}>
          {buttonLabel}
        </S.AddAnalysisButton> */}
        {true && <S.DownloadIcon onClick={handleDownloadFile} />}
        {!isAnalysisButtonDisabled && action && buttonLabel && (
          <S.AddAnalysisButton
            isReady={isReady && !isArchived}
            isRemoved={isArchived}
            onClick={action}
          >
            {buttonLabel}
          </S.AddAnalysisButton>
        )}
        <S.RemoveButton
          onMouseDown={() => setIsTooltipOn(true)}
          onMouseUp={() => setIsTooltipOn(false)}
          onClick={toggleIsArchived}
        >
          {showDeleteTooltip && (
            <S.ArchiveTooltip>
              {t('analysis.table.reportDelete')}
            </S.ArchiveTooltip>
          )}
          {showRestoreTooltip && (
            <S.RestoreTooltip>{t('analysis.table.restore')}</S.RestoreTooltip>
          )}
          {showIcons && (isArchived ? <S.RestoreIcon /> : <S.TrashIcon />)}
        </S.RemoveButton>
      </S.ButtonsRow>
    </S.MobileWrapper>
  );
}
