import { useStripe } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { history } from '../../../App';

const PaymentStep = () => {
  const stripe = useStripe();
  const { submissionId, paymentId } = useParams() as {
    submissionId: string;
    paymentId: string;
  };

  useEffect(() => {
    stripe
      ?.redirectToCheckout({ sessionId: paymentId })
      .then(() => {
        history.push(`/submission/${submissionId}/success`);
      })
      .catch(() => {
        history.push(`/submission/${submissionId}/failure`);
      });
  }, [submissionId, paymentId, stripe]);

  return null;
};

export default PaymentStep;
