export const isSearcheableRoute = (pathname: string) =>
  pathname.includes('analysis-list') ||
  pathname.includes('users') ||
  pathname.includes('seo');

export const isSeoRouteActive = (pathname: string) =>
  pathname.includes('seo-tags') || pathname.includes('seo-images');

export const isAdminRouteActive = (pathname: string) =>
  pathname.includes('analysis-list') || pathname.includes('users') || pathname.includes('analysis-details');
